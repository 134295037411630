exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.admin-users {\n  margin-left: 0px;\n}\n.admin-users .actions {\n  background-color: white;\n  border-bottom: 1px solid #e0e0e0;\n}\n.admin-users .name-filter {\n  width: 200px;\n}\n.admin-users .tab-error-badge .v-badge__badge {\n  border-radius: 4px !important;\n  font-size: 10px;\n  height: 16px;\n  min-width: 16px;\n  padding: 4px 2px !important;\n}\n.admin-users thead th span {\n  font-size: 12px !important;\n}\n.admin-users .user-actions {\n  right: 0px !important;\n}\n.admin-users .user-footer {\n  border-top: thin solid rgba(0, 0, 0, 0.12);\n}\n.admin-users .v-list-item__content {\n  padding: 0px;\n}\n.admin-users .v-tabs {\n  border-bottom: 1px solid #e0e0e0;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};