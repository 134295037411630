<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $tc('labels.flag', 2), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="flags"
    >
      <v-row
        class="actions"
        no-gutters
      >
        <v-col>
          <v-text-field
            v-model.trim="filters.name"
            :append-icon="filters.name ? '' : 'fal fa-search'"
            class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
            :clearable="!!filters.name"
            dense
            hide-details
            :placeholder="$t('labels.searchByName')"
            solo
          />
          <v-btn
            class="float-right mt-2 mr-4"
            color="secondary"
            @click="openFlagDialog()"
          >
            {{ $t('labels.addFlag') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-resize.quiet="onWindowResized"
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedFlags"
        :items-per-page="pagination.itemsPerPage"
        must-sort
        single-select
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
      >
        <template #item="{ item, headers: tableHeaders }">
          <tr
            :class="item.obsolete ? 'obsolete' : ''"
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <span
                v-if="header.value === 'name'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.name"
              >
                {{ item.name }}
              </span>
              <span
                v-else-if="header.value === 'shortCode'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.shortCode"
              >
                {{ item.shortCode }}
              </span>
              <template v-else>
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': header.width === '*' ? '150px' : `${header.width}px` }"
                >
                  <v-icon
                    v-if="item[header.value]"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 flag-dial"
              :value="hoveredItem && hoveredItem.id === item.id"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="edit"
                fab
                height="30"
                width="30"
                :title="$t('labels.editFlag')"
                @click="openFlagDialog(item)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="delete"
                fab
                height="30"
                width="30"
                :title="$t('labels.deleteFlag')"
                @click="openDeleteConfirmationDialog(item)"
              >
                <v-icon
                  color="error"
                  small
                >
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="py-4 footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showFlagDialog"
      persistent
      width="500px"
      :value="showFlagDialog"
    >
      <v-card
        id="flag"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ selectedFlag.id ? $t('labels.editFlag') : $t('labels.addFlag') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeFlagDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="flag">
              <v-container class="pt-0 px-6">
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedFlag.name"
                      dense
                      :label="$t('labels.name')"
                      name="name"
                      outlined
                      :rules="{ required: true, max: 32, excluded: namesInUse }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedFlag.shortCode"
                      dense
                      :label="$t('labels.shortcode')"
                      name="shortcode"
                      outlined
                      :rules="{ required: true, max: 4, excluded: shortCodesInUse }"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <VeeTextField
                      v-model.trim="selectedFlag.description"
                      dense
                      :label="$t('labels.description')"
                      name="description"
                      outlined
                      rules="max:256"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    {{ $t('labels.countAs') }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-checkbox
                      v-model="selectedFlag.commitment"
                      class="commitment mt-0"
                      color="secondary"
                      dense
                      hide-details
                      :label="$t('labels.commitment')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-checkbox
                      v-model="selectedFlag.float"
                      class="float mt-0"
                      color="secondary"
                      dense
                      hide-details
                      :label="$t('labels.float')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-checkbox
                      v-model="selectedFlag.productivity"
                      class="productivity mt-0"
                      color="secondary"
                      dense
                      hide-details
                      :label="$t('labels.productivity')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-checkbox
                      v-model="selectedFlag.working"
                      class="working mt-0"
                      color="secondary"
                      dense
                      hide-details
                      :label="$t('labels.working')"
                    />
                  </v-col>
                </v-row>
                <v-row
                  class="mt-4"
                  dense
                >
                  <v-col
                    cols="12"
                  >
                    {{ $t('labels.accessibility') }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedFlag.allowRequest"
                      class="pt-0 mt-1 d-inline-block allow-request"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.flagAllowRequest')"
                        >
                          {{ $t('labels.flagAllowRequest') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedFlag.visibleToOtherStaff"
                      class="pt-0 mt-1 d-inline-block visible-to-others"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.flagVisibleToOthers')"
                        >
                          {{ $t('labels.flagVisibleToOthers') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeFlagDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="selectedFlag.id"
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.save') }}
                    </span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(add)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.add') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      v-if="deleteDialog.show"
      action="org/checkFlagDependencies"
      :delete-body="$t('descriptions.continueDeleteFlag', { name: deleteDialog.flag.name })"
      :dependencies-body="$t('descriptions.hasDependenciesFlag', { name: deleteDialog.flag.name })"
      :item="deleteDialog.flag"
      :title="$t('labels.deleteFlag?')"
      @close="closeDeleteConfirmationDialog"
      @del="deleteFlag"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import VeeTextField from '@/components/form_controls/VeeTextField';
import DeleteConfirmation from '@/components/admin/DeleteConfirmation';
import { showStatus } from '@/plugins/vue-notification';
import { ERROR_CODES, GROUPS } from '@/services/constants';

export default {
  components: {
    DeleteConfirmation,
    VeeTextField
  },
  data () {
    return {
      deleteDialog: {
        show: false,
        flag: null
      },
      filters: {
        name: ''
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedFlag: null,
      showFlagDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      }
    };
  },
  computed: {
    filteredFlags () {
      const filters = [];

      if (this.filters.name) {
        filters.push((flag) => flag.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }

      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let flags = this.flags;
      if (filters.length > 0) {
        flags = _.filter(this.flags, (flag) => filters.reduce((matches, filter) => {
          matches &= filter(flag);
          return matches;
        }, true));
      }

      return _.orderBy(flags, this.sort.by, orders);
    },
    groupsAvailable () {
      const groups = [];
      for (let id in GROUPS) {
        groups.push({
          label: GROUPS[id],
          value: id
        });
      }
      return _.sortBy(groups, ['label']);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 200
        },
        {
          sortable: true,
          text: this.$t('labels.shortcode'),
          value: 'shortCode',
          width: 120
        },
        {
          sortable: true,
          text: this.$t('labels.commitment'),
          value: 'commitment',
          width: 90
        },
        {
          sortable: true,
          text: this.$t('labels.float'),
          value: 'float',
          width: 90
        },
        {
          sortable: true,
          text: this.$t('labels.productivity'),
          value: 'productivity',
          width: 90
        },
        {
          sortable: true,
          text: this.$t('labels.working'),
          value: 'working',
          width: 80
        },
        {
          sortable: true,
          text: this.$t('labels.flagAllowRequest'),
          value: 'allowRequest',
          width: 200
        },
        {
          sortable: true,
          text: this.$t('labels.flagVisibleToOthers'),
          value: 'visibleToOtherStaff',
          width: '*'
        }
      ];
    },
    namesInUse () {
      let flags = this.flags;
      if (this.selectedFlag) {
        flags = _.filter(this.flags, (st) => st.id !== this.selectedFlag.id);
      }
      return flags.map((st) => st.name);
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredFlags.length / this.pagination.itemsPerPage);
    },
    paginatedFlags () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredFlags.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredFlags;
    },
    flags () {
      return _.cloneDeep(this.$store.state.org.flags);
    },
    shiftTypesAvailable () {
      const shiftTypes = _.sortBy(this.$store.state.org.shiftTypes, ['name']);
      return shiftTypes.map((st) => {
        return {
          label: `${st.name} (${this.formatTime(st.startTime)} - ${this.formatTime(st.endTime)})`,
          value: st.id
        };
      });
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    shortCodesInUse () {
      let flags = this.flags;
      if (this.selectedFlag) {
        flags = _.filter(this.flags, (st) => st.id !== this.selectedFlag.id);
      }
      return flags.map((st) => st.shortCode);
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    add () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/createFlag', this.getPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.flagSaveSuccess'),
            type: 'success'
          });
          this.closeFlagDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.flagSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeDeleteConfirmationDialog () {
      this.deleteDialog.show = false;
      this.deleteDialog.flag = null;
    },
    closeFlagDialog () {
      this.showFlagDialog = false;
      this.selectedFlag = null;
    },
    deleteFlag (flag) {
      this.dispatch('org/deleteFlag', flag.id).then(() => {
        showStatus({
          text: this.$t('descriptions.flagDeletionSuccess', { name: flag.name }),
          type: 'success'
        });
        this.$emit('close');
      }).catch((error) => {
        const status = _.get(error, 'response.status', '');
        const responseData = {
          error: _.get(error, 'response.data')
        };

        let text = '';
        if (status === ERROR_CODES.http412PreconditionFailed) {
          text = this.$t('descriptions.flagCannotDelete', { name: flag.name });
        } else {
          text = this.$t('descriptions.flagDeletionFail', { name: flag.name });
        }

        showStatus({
          text,
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.closeDeleteConfirmationDialog();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatTime (time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    },
    getPayload () {
      return _.cloneDeep(this.selectedFlag);
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openDeleteConfirmationDialog (flag) {
      this.deleteDialog.flag = flag;
      this.deleteDialog.show = true;
    },
    openFlagDialog (flag) {
      let selectedFlag = {
        allowRequest: false,
        commitment: false,
        description: '',
        float: false,
        name: '',
        obsolete: false,
        productivity: false,
        shortCode: '',
        visibleToOtherStaff: false,
        working: false
      };
      if (flag) {
        selectedFlag = _.cloneDeep(flag);
      }
      this.selectedFlag = selectedFlag;
      this.showFlagDialog = true;
    },
    setFilter (field, value) {
      this.filters[field] = value;
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateFlag', this.getPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.flagSaveSuccess'),
            type: 'success'
          });
          this.closeFlagDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.flagSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
#flags {
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .flag-dial {
    right: 0px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
  thead th span {
    font-size: 12px !important;
  }
}

#flag {
  .v-input--checkbox {
    label {
      font-size: 14px;
    }
  }
}
</style>
