<template>
  <v-container
    class="px-0"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.user', 2), disabled: false }, { text: $t('labels.operation'), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <Users
      :classification="classification"
      :headers="headers"
      :users="users"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import Users from '@/views/admin/users/Users';
import { CLASSIFICATIONS } from '@/services/constants';

export default {
  components: {
    Users
  },
  data () {
    return {
      classification: CLASSIFICATIONS.opsManagement,
      headers: {
        'state': {},
        'fullName': {},
        'jobTypeId': {},
        'jobStatusId': {},
        'shiftTypeId': {},
        'phone': {},
        'employeeID': {}
      }
    };
  },
  computed: {
    users () {
      return _.cloneDeep(this.$store.getters['org/getEmployeesByClassification'](CLASSIFICATIONS.opsManagement));
    }
  }
};
</script>
