exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#flags .actions {\n  background-color: white;\n  border-bottom: 1px solid #e0e0e0;\n}\n#flags .footer {\n  border-top: thin solid rgba(0, 0, 0, 0.12);\n}\n#flags .flag-dial {\n  right: 0px !important;\n}\n#flags tr.obsolete td span {\n  color: #9e9e9e !important;\n}\n#flags thead th span {\n  font-size: 12px !important;\n}\n#flag .v-input--checkbox label {\n  font-size: 14px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};