<template>
  <v-container
    id="scheduleStates"
    class="px-0"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $t('labels.schedule'), disabled: false }, { text: $tc('labels.state', 2), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      class="mx-10 my-3 pa-0"
    >
      <v-tabs
        class="dense mt-4"
        color="grey darken-3"
        show-arrows
        slider-color="accent"
        slider-size="3"
      >
        <v-tab
          href="#states"
        >
          <span>
            {{ $tc('labels.state', 2) }}
          </span>
        </v-tab>
        <v-tab
          href="#timeFrame"
        >
          <span>
            {{ $t('labels.timeFrame') }}
          </span>
        </v-tab>
        <v-tab-item value="states">
          <ValidationObserver v-slot="{ invalid, passes }">
            <v-container
              class="pa-0 mx-0 states"
              fluid
            >
              <v-row
                class="pa-4"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.selfSchedule') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.selfSchedule') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4"
                dense
              >
                <v-col>
                  <v-switch
                    v-model="selfScheduleAlwaysOpened"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-always-opened"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.selfScheduleAlwaysOpened')"
                      >
                        {{ $t('labels.selfScheduleAlwaysOpened') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer class="my-2" />
                  <v-switch
                    v-model="selfScheduleBidForOpening"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-bid"
                    color="success"
                    dense
                    :disabled="selfScheduleAlwaysOpened"
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-2 body-2 grey--text text--darken-3"
                        :title="$t('labels.selfScheduleBid')"
                      >
                        {{ $t('labels.selfScheduleBid') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-tooltip
                    max-width="400px"
                    right
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="mt-n1"
                        color="info"
                        dense
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        fal fa-question-circle
                      </v-icon>
                    </template>
                    <span class="body-2">
                      {{ $t('descriptions.selfScheduleBid') }}
                    </span>
                  </v-tooltip>
                  <v-spacer class="my-2" />
                  <v-switch
                    v-model="primaryShiftOnly"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-primary"
                    color="success"
                    dense
                    :disabled="selfScheduleBidForOpening"
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.selfSchedulePrimaryShiftOnly')"
                      >
                        {{ $t('labels.selfSchedulePrimaryShiftOnly') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer class="my-2" />
                  <v-switch
                    v-model="allowEditing.selfSchedule"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-editing"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.selfScheduleAllowEditing')"
                      >
                        {{ $t('labels.selfScheduleAllowEditing') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer class="my-2" />
                  <v-switch
                    v-model="allowOvertime"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-overtime"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.allowOvertime')"
                      >
                        {{ $t('labels.allowOvertime') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-divider class="my-4" />
                  <v-switch
                    v-model="snapshots.selfSchedule"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-snapshot"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.takeSnapshotSelfSchedule')"
                      >
                        {{ $t('labels.takeSnapshotSelfSchedule') }}
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row
                class="pa-4"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.draft') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.draftState') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4"
                dense
              >
                <v-col>
                  <v-switch
                    v-model="hideDraftScheduleFromStaff"
                    class="pt-0 mt-0 ml-1 d-inline-block hide-draft"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.hideDraftScheduleFromStaff')"
                      >
                        {{ $t('labels.hideDraftScheduleFromStaff') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer class="my-3" />
                  <v-switch
                    v-model="approvals.post"
                    class="pt-0 mt-0 ml-1 d-inline-block draft-approval"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.scheduleApprovalRequired')"
                      >
                        {{ $t('labels.scheduleApprovalRequired') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer class="my-3" />
                  <VeeSelect
                    v-model="reviewers.post"
                    class="post-reviewer"
                    dense
                    :disabled="!approvals.post"
                    hide-details
                    item-text="name"
                    item-value="id"
                    :items="groups"
                    :label="$t('labels.reviewBy')"
                    name="draftReviewer"
                    outlined
                    :rules="approvals.post ? 'required' : ''"
                  />
                  <v-divider class="my-4" />
                  <v-switch
                    v-model="snapshots.draft"
                    class="pt-0 mt-0 ml-1 d-inline-block draft-snapshot"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.takeSnapshotDraft')"
                      >
                        {{ $t('labels.takeSnapshotDraft') }}
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row
                class="pa-4"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.underStaffReview') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.underStaffReview') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4"
                dense
              >
                <v-col>
                  <v-switch
                    v-model="approvals.publish"
                    class="pt-0 mt-0 ml-1 d-inline-block published-approval"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.scheduleApprovalRequired')"
                      >
                        {{ $t('labels.scheduleApprovalRequired') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer class="my-3" />
                  <VeeSelect
                    v-model="reviewers.publish"
                    dense
                    :disabled="!approvals.publish"
                    hide-details
                    item-text="name"
                    item-value="id"
                    :items="groups"
                    :label="$t('labels.reviewBy')"
                    name="underStaffReviewReviewer"
                    outlined
                    :rules="approvals.publish ? 'required' : ''"
                  />
                  <v-divider class="my-4" />
                  <v-switch
                    v-model="snapshots.published"
                    class="pt-0 mt-0 ml-1 d-inline-block published-snapshot"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.takeSnapshotUnderStaffReview')"
                      >
                        {{ $t('labels.takeSnapshotUnderStaffReview') }}
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
            </v-container>
            <v-row
              class="pa-4"
              dense
            >
              <v-spacer />
              <v-col
                class="text-right"
                cols="6"
              >
                <v-btn
                  class="ml-3 save-states"
                  color="secondary"
                  :disabled="invalid || saving"
                  @click="passes(saveStates)"
                >
                  <v-progress-circular
                    v-if="saving"
                    color="secondary"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.save') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-tab-item>
        <v-tab-item value="timeFrame">
          <ValidationObserver
            v-slot="{ invalid, passes }"
            ref="timeFrame"
          >
            <v-container
              class="pa-0 mx-0 time-frame"
              fluid
            >
              <v-row
                class="py-4 pl-4 pr-8"
                dense
              >
                <v-col cols="6">
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.scheduleStartDate') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.scheduleStartDate') }}
                  </div>
                </v-col>
                <v-col
                  class="text-right"
                  cols="6"
                >
                  <v-menu
                    ref="hiredOnPicker"
                    v-model="showScheduleStartDatePicker"
                    :close-on-content-click="false"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    :nudge-bottom="0"
                    :nudge-left="45"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VeeTextField
                        v-model="initialStartDateDisplay"
                        v-mask="'##/##/####'"
                        append-icon="fal fa-calendar-alt"
                        :autocomplete="false"
                        class="start-date"
                        dense
                        disabled
                        hide-details
                        name="scheduleStartDate"
                        outlined
                        :placeholder="$t('labels.scheduleStartDate')"
                        :rules="{ required: true, dateRegex_MMDDYYYY: dateRegex }"
                        v-bind="attrs"
                        @blur="initialStartDate = parseDate(initialStartDateDisplay)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="initialStartDate"
                      :max="maxScheduleStartDate"
                      no-title
                      @input="showScheduleStartDatePicker = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
              <v-row
                class="py-4 pl-4 pr-8"
                dense
              >
                <v-col cols="6">
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.scheduleDuration') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.scheduleDuration') }}
                  </div>
                </v-col>
                <v-col
                  class="text-right"
                  cols="6"
                >
                  <VeeTextField
                    v-model.number="period"
                    class="number-of-days"
                    dense
                    hide-details
                    name="scheduleDuration"
                    outlined
                    :placeholder="$t('labels.numberOfDays')"
                    :rules="{ required: true, numeric: true, min_value: selfSchedulePeriod + postLeadTime }"
                  />
                </v-col>
              </v-row>
              <v-row
                class="py-4 pl-4 pr-8"
                dense
              >
                <v-col cols="6">
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.selfSchedulePeriod') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.selfSchedulePeriod') }}
                  </div>
                </v-col>
                <v-col
                  class="text-right"
                  cols="6"
                >
                  <VeeTextField
                    v-model.number="selfSchedulePeriod"
                    class="number-of-days"
                    dense
                    hide-details
                    name="selfSchedulePeriod"
                    outlined
                    :placeholder="$t('labels.numberOfDays')"
                    :rules="{ required: true, numeric: true, min_value: 1, max_value: period - postLeadTime }"
                  />
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4"
                dense
              >
                <v-col>
                  <v-switch
                    v-model="useDefaultSelfScheduleWindow"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-segments"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.useDefaultSelfScheduleWindow')"
                      >
                        {{ $t('labels.useDefaultSelfScheduleWindow') }}
                      </span>
                    </template>
                  </v-switch>
                  <template v-if="!useDefaultSelfScheduleWindow">
                    <v-spacer class="my-2" />
                    <v-row dense>
                      <v-col>
                        <v-row
                          v-for="(segment, idx) in selfScheduleSegments"
                          :key="idx"
                          class="my-1 segment"
                          dense
                        >
                          <v-col>
                            <VeeSelect
                              v-model="segment.allowedJobStatus"
                              class="d-inline-block job-status"
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              :items="getAvailableJobStatus(idx)"
                              :label="$t('labels.jobStatus')"
                              :menu-props="{ top: false, offsetY: true }"
                              multiple
                              outlined
                              rules="required"
                              :vid="`jobStatus${idx}`"
                            >
                              <template #selection="{ item, index: jobStatusIndex }">
                                <span
                                  v-if="jobStatusIndex === segment.allowedJobStatus.length - 1"
                                  class="grey--text text--darken-3 body-2"
                                >
                                  {{ item.shortCode }}
                                </span>
                                <span
                                  v-else
                                  class="grey--text text--darken-3 body-2 pr-1"
                                >
                                  {{ `${item.shortCode},` }}
                                </span>
                              </template>
                            </VeeSelect>
                            <VeeSelect
                              v-model="segment.type"
                              class="d-inline-block rule-type"
                              dense
                              hide-details
                              item-text="name"
                              item-value="id"
                              :items="[{ name: $t('labels.canSelfSchedule'), id: 'allow' }, { name: $t('labels.cannotSelfSchedule'), id: 'forbid' }]"
                              :label="$t('labels.ruleType')"
                              :menu-props="{ top: false, offsetY: true }"
                              outlined
                              rules="required"
                              :vid="`ruleType${idx}`"
                            />
                            <template v-if="segment.type === 'allow'">
                              <span class="grey--text text--darken-3 body-2 mx-2 text-lowercase">
                                {{ $t('labels.from') }}
                              </span>
                              <VeeTextField
                                v-model.number="segment.latent"
                                class="latent d-inline-block number-of-days"
                                dense
                                hide-details
                                name="latent"
                                outlined
                                :label="$t('labels.day')"
                                :rules="{ required: true, numeric: true, min_value: 1, max_value: selfSchedulePeriod }"
                                :vid="`latent${idx}`"
                              />
                            </template>
                            <v-btn
                              class="ml-2 remove-segment"
                              icon
                              @click="removeSegment(idx)"
                            >
                              <v-icon
                                color="error"
                                small
                              >
                                fal fa-trash-alt
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-spacer />
                        <a
                          class="caption add-segment secondary--text"
                          @click="addSegment"
                        >
                          + {{ $t('labels.addSelfScheduleWindow') }}
                        </a>
                      </v-col>
                    </v-row>
                    <div
                      class="grey--text caption mt-2"
                    >
                      {{ `*${$t('descriptions.selfScheduleWindows')}` }}
                    </div>
                  </template>
                </v-col>
              </v-row>
              <v-row
                class="py-4 pl-4 pr-8"
                dense
              >
                <v-col cols="6">
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.postLeadTime') }}
                  </div>
                  <div class="grey--text caption">
                    {{ $t('descriptions.postLeadTime') }}
                  </div>
                </v-col>
                <v-col
                  class="text-right"
                  cols="6"
                >
                  <VeeTextField
                    v-model.number="postLeadTime"
                    class="number-of-days"
                    dense
                    hide-details
                    name="postLeadTime"
                    outlined
                    :placeholder="$t('labels.numberOfDays')"
                    :rules="{ required: true, numeric: true, min_value: nurseReviewPeriod, max_value: period - selfSchedulePeriod }"
                  />
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4"
                dense
              >
                <v-col>
                  <span class="grey--text text--darken-3 body-2 mr-1">
                    {{ $t('labels.nurseReviewPeriod1') }}
                  </span>
                  <VeeTextField
                    v-model.number="nurseReviewPeriod"
                    class="number-of-days"
                    dense
                    hide-details
                    name="nurseReviewPeriod"
                    outlined
                    :placeholder="$t('labels.numberOfDays')"
                    :rules="{ required: true, numeric: true, min_value: 0, max_value: postLeadTime }"
                  />
                  <span class="grey--text text--darken-3 body-2 ml-1">
                    {{ $t('labels.nurseReviewPeriod2') }}
                  </span>
                  <div
                    v-if="nurseReviewPeriod"
                    class="grey--text caption mt-3"
                  >
                    {{ `*${$t('descriptions.nurseReviewPeriod', { n: nurseReviewPeriod })}` }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="py-4 pl-4 pr-8"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.otherSettings') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4"
                dense
              >
                <v-col cols="12">
                  <v-switch
                    v-model="allowFlexOn"
                    class="pt-0 mt-0 ml-1 d-inline-block self-schedule-availability"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.selfScheduleAvailability')"
                      >
                        {{ $t('labels.selfScheduleAvailability') }}
                      </span>
                    </template>
                  </v-switch>
                  <v-spacer />
                  <div
                    v-if="allowFlexOn"
                    class="ml-15"
                  >
                    <v-switch
                      v-model="allowPartialFlexOn"
                      class="mt-1 d-inline-block self-schedule-custom-availability"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.allowFlexOnPartial')"
                        >
                          {{ $t('labels.allowFlexOnPartial') }}
                        </span>
                      </template>
                    </v-switch>
                    <v-tooltip
                      max-width="400px"
                      right
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="mt-n1"
                          color="info"
                          dense
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          fal fa-question-circle
                        </v-icon>
                      </template>
                      <span class="body-2">
                        {{ $t('descriptions.allowFlexOnPartial') }}
                      </span>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="allowFlexOn"
                    class="ml-15"
                  >
                    <span class="grey--text text--darken-3 body-2 mr-1">
                      {{ $t('labels.allowFlexOn1') }}
                    </span>
                    <VeeTextField
                      v-model.number="flexOnLeadTime"
                      class="number-of-days"
                      dense
                      hide-details
                      name="nurseAvailability"
                      outlined
                      :placeholder="$t('labels.numberOfHours')"
                      :rules="{ required: allowFlexOn, numeric: true, min_value: 0 }"
                    />
                    <span class="grey--text text--darken-3 body-2 ml-1">
                      {{ $t('labels.allowFlexOn2') }}
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="py-4 pl-4 pr-8"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-3 body-2">
                    {{ $t('labels.preview') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                class="grey lighten-5 pa-4 preview"
                dense
              >
                <v-col cols="10">
                  <v-row
                    class="body-2 font-weight-medium grey--text text--darken-3"
                    dense
                    no-gutters
                  >
                    <v-col
                      cols="2"
                    >
                      {{ $t('labels.selfSchedule') }}
                    </v-col>
                    <v-spacer />
                    <v-col
                      cols="2"
                    >
                      {{ $t('labels.draft') }}
                    </v-col>
                    <v-spacer />
                    <v-col
                      cols="3"
                    >
                      {{ $t('labels.staffReview') }}
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      {{ $t('labels.published') }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10">
                  <v-row
                    class="body-2 font-weight-medium grey--text text--darken-3"
                    dense
                    no-gutters
                  >
                    <v-col
                      cols="2"
                    >
                      <div class="self-schedule-preview caption">
                        {{ selfScheduleRangePreview }}
                      </div>
                    </v-col>
                    <v-col
                      cols="1"
                    >
                      <v-icon
                        class="text--darken-3"
                        color="grey"
                        small
                      >
                        fas fa-caret-right
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <div class="draft-preview caption">
                        {{ draftRangePreview }}
                      </div>
                    </v-col>
                    <v-col
                      cols="1"
                    >
                      <v-icon
                        class="text--darken-3"
                        color="grey"
                        small
                      >
                        fas fa-caret-right
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      <div class="staff-review-preview caption">
                        {{ staffReviewRangePreview }}
                      </div>
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      <div class="published-preview caption">
                        {{ publishedRangePreview }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10">
                  <v-row
                    class="body-2 font-weight-medium grey--text text--darken-3"
                    dense
                    no-gutters
                  >
                    <v-col
                      cols="2"
                    >
                      {{ $t('labels.xDays', { x: selfSchedulePeriod }) }}
                    </v-col>
                    <v-spacer />
                    <v-col
                      cols="2"
                    >
                      {{ $t('labels.xDays', { x: period - selfSchedulePeriod - postLeadTime }) }}
                    </v-col>
                    <v-spacer />
                    <v-col
                      cols="3"
                    >
                      {{ $t('labels.xDays', { x: nurseReviewPeriod }) }}
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      {{ $t('labels.xDays', { x: postLeadTime - nurseReviewPeriod }) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-row
              class="pa-4"
              dense
            >
              <v-spacer />
              <v-col
                class="text-right"
                cols="6"
              >
                <v-btn
                  class="ml-3 save-time-frame"
                  color="secondary"
                  :disabled="invalid || saving"
                  @click="passes(saveTimeFrame)"
                >
                  <v-progress-circular
                    v-if="saving"
                    color="secondary"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.save') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import { DATE_REGEX } from '@/components/form_controls/constants';
import { showStatus } from '@/plugins/vue-notification';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { DATE_FORMAT } from '@/utils/ui';
import moment from 'moment';

export default {
  components: {
    VeeSelect,
    VeeTextField
  },
  data () {
    const states = _.cloneDeep(this.$store.state.org.settings.scheduling.states);
    let allowSelfScheduleEditing = false;
    let takeDraftSnapshot = false;
    let takeSelfScheduleSnapshot = false;
    let takePublishedSnapshot = false;
    let postApproval = false;
    let publishApproval = false;
    let postReviewer = '';
    let publishReviewer = '';
    let hideDraftScheduleFromStaff = false;
    for (let i = 0, count = states.length; i < count; i++) {
      switch (states[i].state) {
        case SCHEDULE_STATES.DRAFT:
          takeDraftSnapshot = states[i].takeSnapshot.onLeave;
          hideDraftScheduleFromStaff = _.get(states, [i, 'hideScheduleFromStaff'], false);
          break;
        case SCHEDULE_STATES.SELF_SCHEDULE:
          takeSelfScheduleSnapshot = states[i].takeSnapshot.onLeave;
          allowSelfScheduleEditing = states[i].allowEditing;
          break;
        case SCHEDULE_STATES.PUBLISHED:
          takePublishedSnapshot = states[i].takeSnapshot.onEnter;
          break;
        case SCHEDULE_STATES.PENDING_POST_APPROVAL:
          postApproval = true;
          postReviewer = states[i].owner;
          break;
        case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
          publishApproval = true;
          publishReviewer = states[i].owner;
          break;
      }
    }
    const selfScheduleSegments = _.cloneDeep(this.$store.state.org.settings.scheduling.nurseSelfSchedule.segments);
    for (let i = 0, count = selfScheduleSegments.length; i < count; i++) {
      selfScheduleSegments[i].type = selfScheduleSegments[i].latent === null ? 'forbid' : 'allow';
    }

    return {
      allowEditing: {
        selfSchedule: allowSelfScheduleEditing
      },
      allowFlexOn: this.$store.state.org.settings.scheduling.flexOn.allowSelfSchedule,
      allowOvertime: _.get(this.$store.state.org.settings, 'scheduling.nurseSelfSchedule.allowOvertime', true),
      allowPartialFlexOn: _.get(this.$store.state.org.settings, 'scheduling.flexOn.allowPartialShift', false),
      approvals: {
        post: postApproval,
        publish: publishApproval
      },
      dateRegex: DATE_REGEX,
      flexOnLeadTime: this.$store.state.org.settings.scheduling.flexOn.leadTime,
      hideDraftScheduleFromStaff,
      nurseReviewPeriod: this.$store.state.org.settings.scheduling.nurseReviewPeriod,
      period: this.$store.state.org.settings.scheduling.period,
      postLeadTime: this.$store.state.org.settings.scheduling.postLeadTime,
      primaryShiftOnly: this.$store.state.org.settings.scheduling.nurseSelfSchedule.primaryShiftOnly,
      reviewers: {
        post: postReviewer,
        publish: publishReviewer
      },
      saving: false,
      selfScheduleAlwaysOpened: this.$store.state.org.settings.scheduling.nurseSelfSchedule.alwaysOpen,
      selfScheduleBidForOpening: this.$store.state.org.settings.scheduling.nurseSelfSchedule.bidForOpening,
      selfSchedulePeriod: this.$store.state.org.settings.scheduling.nurseSelfSchedule.period,
      selfScheduleSegments,
      showScheduleStartDatePicker: false,
      snapshots: {
        draft: takeDraftSnapshot,
        selfSchedule: takeSelfScheduleSnapshot,
        published: takePublishedSnapshot
      },
      initialStartDate: this.$store.state.org.settings.scheduling.initialStartDate,
      initialStartDateDisplay: this.formatDate(this.$store.state.org.settings.scheduling.initialStartDate),
      states,
      useDefaultSelfScheduleWindow: this.$store.state.org.settings.scheduling.nurseSelfSchedule.segments.length === 0
    };
  },
  computed: {
    draftRangePreview () {
      if (this.period - this.selfSchedulePeriod - this.postLeadTime < 1) {
        return 'N/A';
      }
      const startOfDraft = moment(this.scheduleStartDate).add(this.selfSchedulePeriod, 'd');
      return [
        startOfDraft.format('MMM D'),
        startOfDraft.add(this.period - this.selfSchedulePeriod - this.postLeadTime - 1, 'd').format('MMM D')
      ].join(' - ');
    },
    groups () {
      const groups = [
        {
          id: 'director',
          name: 'Director'
        },
        {
          id: 'operator',
          name: 'Operator'
        }
      ];
      return groups;
    },
    jobStatus () {
      return _.sortBy(this.$store.state.org.jobStatus, ['name']);
    },
    maxScheduleStartDate () {
      return moment().format(DATE_FORMAT);
    },
    publishedRangePreview () {
      if (this.postLeadTime - this.nurseReviewPeriod < 1) {
        return 'N/A';
      }
      const endOfNextSchedule = moment(this.scheduleStartDate).add(this.period - 1, 'd');
      return [
        endOfNextSchedule.clone().subtract(this.postLeadTime - this.nurseReviewPeriod - 1, 'd').format('MMM D'),
        endOfNextSchedule.format('MMM D')
      ].join(' - ');
    },
    scheduleStartDate () {
      const weekDay = moment(this.initialStartDate).weekday();
      const initialStartDate = moment(this.initialStartDate).startOf('w');
      const thisWeek = moment().startOf('w');

      const weekDiff = thisWeek.diff(initialStartDate, 'w');
      const weeksRemainder = weekDiff % (this.period / 7);
      const startOfCurrentSchedule = initialStartDate.clone().add(weekDiff - weeksRemainder, 'w');
      const startOfNextSchedule = startOfCurrentSchedule.clone().add((this.period / 7), 'w');
      return startOfNextSchedule.weekday(weekDay).format('YYYY-MM-DD');
    },
    selfScheduleRangePreview () {
      if (this.selfSchedulePeriod < 1) {
        return 'N/A';
      }
      const startOfNextSchedule = moment(this.scheduleStartDate);
      return [
        startOfNextSchedule.format('MMM D'),
        startOfNextSchedule.add(this.selfSchedulePeriod - 1, 'd').format('MMM D')
      ].join(' - ');
    },
    staffReviewRangePreview () {
      if (this.nurseReviewPeriod < 1) {
        return 'N/A';
      }
      const endOfNextSchedule = moment(this.scheduleStartDate).add(this.period - 1, 'd');
      const startOfNextPublishedDate = endOfNextSchedule.subtract(this.postLeadTime - this.nurseReviewPeriod - 1, 'd');
      return [
        startOfNextPublishedDate.clone().subtract(this.nurseReviewPeriod, 'd').format('MMM D'),
        startOfNextPublishedDate.clone().subtract(1, 'd').format('MMM D')
      ].join(' - ');
    }
  },
  watch: {
    allowFlexOn () {
      if (!this.allowFlexOn) {
        this.allowPartialFlexOn = false;
      }
    },
    'initialStartDate' () {
      this.initialStartDateDisplay = this.formatDate(this.initialStartDate);
    },
    'selfScheduleAlwaysOpened' () {
      if (this.selfScheduleAlwaysOpened) {
        this.selfScheduleBidForOpening = false;
      }
    },
    'selfScheduleBidForOpening' () {
      if (this.selfScheduleBidForOpening) {
        this.primaryShiftOnly = true;
      }
    }
  },
  methods: {
    addSegment () {
      this.selfScheduleSegments.push({
        latent: null,
        allowedJobStatus: [],
        type: 'allow'
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatDate (date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    getAvailableJobStatus (segmentIdx) {
      const jobStatusIdsInUse = _.uniq(this.selfScheduleSegments.reduce(
        (arr, currentValue, currentIndex) => {
          if (segmentIdx !== currentIndex) {
            arr.push(...currentValue['allowedJobStatus']);
          }
          return arr;
        }, // eslint-disable-line no-return-assign, no-sequences
        []
      ));
      return _.filter(this.jobStatus, (jobStatus) => {
        return !jobStatusIdsInUse.includes(jobStatus.id);
      });
    },
    parseDate (date) {
      if (!date) {
        return null;
      }

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    removeSegment (idx) {
      this.selfScheduleSegments.splice(idx, 1);
    },
    saveStates () {
      if (!this.saving) {
        this.saving = true;
        const states = _.cloneDeep(this.states);

        const selfScheduleSnapshotIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.SELF_SCHEDULE);
        if (selfScheduleSnapshotIndex >= 0) {
          states[selfScheduleSnapshotIndex].takeSnapshot.onLeave = this.snapshots.selfSchedule;
          states[selfScheduleSnapshotIndex].allowEditing = this.allowEditing.selfSchedule;
        }
        const draftSnapshotIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.DRAFT);
        if (draftSnapshotIndex >= 0) {
          states[draftSnapshotIndex].takeSnapshot.onLeave = this.snapshots.draft;
          states[draftSnapshotIndex].hideScheduleFromStaff = this.hideDraftScheduleFromStaff;
        }
        const publishedSnapshotIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.PUBLISHED);
        if (publishedSnapshotIndex >= 0) {
          states[publishedSnapshotIndex].takeSnapshot.onEnter = this.snapshots.published;
        }

        const postApprovalIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.PENDING_POST_APPROVAL);
        if (this.approvals.post) {
          if (postApprovalIndex >= 0) {
            states[postApprovalIndex].owner = this.reviewers.post;
          } else {
            const draftIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.DRAFT);
            states.splice(draftIndex + 1, 0, {
              owner: this.reviewers.post,
              state: SCHEDULE_STATES.PENDING_POST_APPROVAL,
              takeSnapshot: {
                onEnter: false,
                onLeave: false
              },
              allowEditing: true
            });
          }
        } else {
          if (postApprovalIndex >= 0) {
            states.splice(postApprovalIndex, 1);
          }
        }

        const publishApprovalIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL);
        if (this.approvals.publish) {
          if (publishApprovalIndex >= 0) {
            states[publishApprovalIndex].owner = this.reviewers.publish;
          } else {
            const underNurseReviewIndex = _.findIndex(states, (item) => item.state === SCHEDULE_STATES.UNDER_NURSE_REVIEW);
            states.splice(underNurseReviewIndex + 1, 0, {
              owner: this.reviewers.publish,
              state: SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL,
              takeSnapshot: {
                onEnter: false,
                onLeave: false
              }
            });
          }
        } else {
          if (publishApprovalIndex >= 0) {
            states.splice(publishApprovalIndex, 1);
          }
        }

        const payload = [
          {
            path: ['scheduling', 'states'],
            value: states
          },
          {
            path: ['scheduling', 'nurseSelfSchedule', 'primaryShiftOnly'],
            value: this.primaryShiftOnly
          },
          {
            path: ['scheduling', 'nurseSelfSchedule', 'alwaysOpen'],
            value: this.selfScheduleAlwaysOpened
          },
          {
            path: ['scheduling', 'nurseSelfSchedule', 'bidForOpening'],
            value: this.selfScheduleBidForOpening
          },
          {
            path: ['scheduling', 'nurseSelfSchedule', 'allowOvertime'],
            value: this.allowOvertime
          }
        ];
        this.dispatch('org/updateHospitalSettings', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.scheduleSettingsUpdateSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.scheduleSettingsUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    saveTimeFrame () {
      if (!this.saving) {
        this.saving = true;
        const payload = [
          {
            path: ['scheduling', 'initialStartDate'],
            value: this.initialStartDate
          },
          {
            path: ['scheduling', 'period'],
            value: this.period
          },
          {
            path: ['scheduling', 'nurseSelfSchedule', 'period'],
            value: this.selfSchedulePeriod
          },
          {
            path: ['scheduling', 'postLeadTime'],
            value: this.postLeadTime
          },
          {
            path: ['scheduling', 'nurseReviewPeriod'],
            value: this.nurseReviewPeriod
          },
          {
            path: ['scheduling', 'flex_on'],
            value: {
              allowPartialShift: this.allowPartialFlexOn,
              allowSelfSchedule: this.allowFlexOn,
              leadTime: this.flexOnLeadTime
            }
          }
        ];

        if (this.useDefaultSelfScheduleWindow) {
          payload.push({
            path: ['scheduling', 'nurseSelfSchedule', 'segments'],
            value: []
          });
        } else {
          const selfScheduleSegments = [];
          for (let i = 0, count = this.selfScheduleSegments.length; i < count; i++) {
            selfScheduleSegments.push({
              allowedJobStatus: this.selfScheduleSegments[i].allowedJobStatus,
              latent: this.selfScheduleSegments[i].type === 'allow' ? this.selfScheduleSegments[i].latent : null
            });
          }
          payload.push({
            path: ['scheduling', 'nurseSelfSchedule', 'segments'],
            value: selfScheduleSegments
          });
        }

        this.dispatch('org/updateHospitalSettings', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.scheduleSettingsUpdateSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.scheduleSettingsUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
#scheduleStates {
  .v-tabs {
    min-width: 820px;
    .v-tabs-bar__content {
      border-bottom: 1px solid map-get($grey, 'lighten-2');
    }
  }

  .draft-preview {
    background-color: #E98600 !important;
    border-color: #E98600 !important;
    border-radius: 4px;
    color: #FFF !important;
    height: 100%;
    margin: auto;
    width: 90%;
  }
  .post-lead-time-preview {
    max-width: 400px !important;
    min-width: 400px !important;
  }
  .preview {
    text-align: center;
  }
  .published-preview {
    background-color: #0F9FFF !important;
    border-color: #0F9FFF !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-radius: 4px;
    color: #FFF !important;
    height: 100%;
    margin: auto;
    width: 90%;
  }
  .self-schedule-preview {
    background-color: #8A5084 !important;
    border-color: #8A5084 !important;
    border-radius: 4px;
    color: #FFF !important;
    height: 100%;
    margin: auto;
    width: 90%;
  }
  .staff-review-preview {
    background-color: #0F9FFF !important;
    border-color: #0F9FFF !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-radius: 4px;
    color: #FFF !important;
    height: 100%;
    margin: auto;
    width: 90%;
  }
  .states, .time-frame {
    > .row {
      border-bottom: 1px solid map-get($grey, 'lighten-2');
    }
    .job-status {
      margin-right: 16px;
      width: 300px;
    }
    .latent {
      width: 80px !important;
    }
    .number-of-days {
      display: inline-block;
      width: 140px;
    }
    .rule-type {
      width: 200px;
    }
    .start-date {
      display: inline-block;
      width: 200px;
    }
    .v-select {
      width: 360px;
    }
  }
}
</style>
