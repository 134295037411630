<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $tc('labels.jobType', 2), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="jobTypes"
    >
      <v-row
        class="actions"
        no-gutters
      >
        <v-col>
          <v-text-field
            v-model.trim="filters.name"
            :append-icon="filters.name ? '' : 'fal fa-search'"
            class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
            :clearable="!!filters.name"
            dense
            hide-details
            :placeholder="$t('labels.searchByName')"
            solo
          />
          <v-btn
            class="float-right mt-2 mr-4"
            color="secondary"
            @click="openJobTypeDialog()"
          >
            {{ $t('labels.addJobType') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-resize.quiet="onWindowResized"
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedJobTypes"
        :items-per-page="pagination.itemsPerPage"
        must-sort
        single-select
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
      >
        <template #header.description="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #header.associatedGroup="{ header }">
          <MultiSelectionList
            :selection="groupsAvailable"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #header.associatedShiftTypes="{ header }">
          <MultiSelectionList
            :selection="shiftTypesAvailable"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #header.swappableJobTypes="{ header }">
          <MultiSelectionList
            :selection="jobTypesAvailable"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #item="{ item, headers: tableHeaders }">
          <tr
            :class="item.obsolete ? 'obsolete' : ''"
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <span
                v-if="header.value === 'name'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.name"
              >
                {{ item.name }}
              </span>
              <span
                v-if="header.value === 'description'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.description"
              >
                {{ item.description }}
              </span>
              <span
                v-if="header.value === 'associatedGroup'"
                class="grey--text text--darken-3 text-truncate d-block text-capitalize"
                :style="{ width: `${header.width}px` }"
                :title="getAssociatedGroupDisplay(item)"
              >
                {{ getAssociatedGroupDisplay(item) }}
              </span>
              <span
                v-if="header.value === 'associatedShiftTypes'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="getAssociatedShiftTypesDisplay(item)"
              >
                {{ getAssociatedShiftTypesDisplay(item) }}
              </span>
              <span
                v-if="header.value === 'swappableJobTypes'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="getSwappableJobTypesDisplay(item)"
              >
                {{ getSwappableJobTypesDisplay(item) }}
              </span>
              <template v-if="header.value === 'partakeInScheduling'">
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': '140px' }"
                >
                  <v-icon
                    v-if="item.partakeInScheduling"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 job-type-dial"
              :value="hoveredItem && hoveredItem.id === item.id"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="edit"
                fab
                height="30"
                width="30"
                :title="$t('labels.editJobType')"
                @click="openJobTypeDialog(item)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="delete"
                fab
                height="30"
                width="30"
                :title="$t('labels.deleteJobType')"
                @click="openDeleteConfirmationDialog(item)"
              >
                <v-icon
                  color="error"
                  small
                >
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="py-4 footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showJobTypeDialog"
      persistent
      width="500px"
      :value="showJobTypeDialog"
    >
      <v-card
        id="jobType"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ selectedJobType.id ? $t('labels.editJobType') : $t('labels.addJobType') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeJobTypeDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="job-type">
              <v-container class="pt-0 px-6">
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedJobType.name"
                      dense
                      :label="$t('labels.name')"
                      name="name"
                      outlined
                      :rules="{ required: true, max: 32, excluded: namesInUse }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedJobType.description"
                      dense
                      :label="$t('labels.description')"
                      name="description"
                      outlined
                      rules="max:256"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <VeeSelect
                      v-model="selectedJobType.settings.associatedGroups"
                      dense
                      item
                      item-text="label"
                      item-value="value"
                      :items="groupsAvailable"
                      :label="$t('labels.associatedGroup')"
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <VeeSelect
                      v-model="selectedJobType.settings.associatedShiftTypes"
                      dense
                      item
                      item-text="label"
                      item-value="value"
                      :items="shiftTypesAvailable"
                      :label="$t('labels.associatedShiftTypes')"
                      multiple
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <VeeSelect
                      ref="swappableJobTypes"
                      v-model="selectedJobType.swappableJobTypes"
                      dense
                      item
                      item-text="label"
                      item-value="value"
                      :items="jobTypesAvailable"
                      :label="$t('labels.canSwapWith')"
                      :menu-props="{ top: false, offsetY: true }"
                      multiple
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-switch
                      v-model="selectedJobType.partakeInScheduling"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.involveInScheduling')"
                        >
                          {{ $t('labels.involveInScheduling') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedJobType.settings.isChargeNurse"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      :disabled="!selectedJobType.partakeInScheduling"
                      hide-details
                      inset
                      @change="onIsChargeNurseChange"
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.isChargeNurse')"
                        >
                          {{ $t('labels.isChargeNurse') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedJobType.settings.canBeChargeNurse"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      :disabled="!selectedJobType.partakeInScheduling || selectedJobType.settings.isChargeNurse"
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.canBeChargeNurse')"
                        >
                          {{ $t('labels.canBeChargeNurse') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-row
                v-if="selectedJobType.id"
                class="grey lighten-4 mb-0 py-4 px-6"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-2 body-2 mb-3">
                    {{ $t('descriptions.obsolete', { type: $tc('labels.jobType', 1).toLowerCase() }) }}
                  </div>
                  <v-switch
                    v-model="selectedJobType.obsolete"
                    class="pt-0 mt-1 d-inline-block"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.obsolete')"
                      >
                        {{ $t('labels.obsolete') }}
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row> -->
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeJobTypeDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="selectedJobType.id"
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.save') }}
                    </span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(add)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.add') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      v-if="deleteDialog.show"
      action="org/checkJobTypeDependencies"
      :delete-body="$t('descriptions.continueDeleteJobType', { name: deleteDialog.jobType.name })"
      :dependencies-body="$t('descriptions.hasDependenciesJobType', { name: deleteDialog.jobType.name })"
      :item="deleteDialog.jobType"
      :title="$t('labels.deleteJobType?')"
      @close="closeDeleteConfirmationDialog"
      @del="deleteJobType"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import MultiSelectionList from '@/components/MultiSelectionList';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import DeleteConfirmation from '@/components/admin/DeleteConfirmation';
import { showStatus } from '@/plugins/vue-notification';
import { ERROR_CODES, GROUPS } from '@/services/constants';

export default {
  components: {
    DeleteConfirmation,
    MultiSelectionList,
    VeeSelect,
    VeeTextField
  },
  data () {
    return {
      deleteDialog: {
        show: false,
        jobType: null
      },
      displayOptions: {
        mobile: [
          { text: this.$t('labels.shortcode'), value: 'text' }
        ],
        web: [
          { text: this.$t('labels.startAndEndTime'), value: 'time' },
          { text: this.$t('labels.shortcode'), value: 'text' }
        ]
      },
      filters: {
        associatedGroup: [],
        associatedShiftTypes: [],
        name: '',
        swappableJobTypes: []
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedJobType: null,
      showJobTypeDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      }
    };
  },
  computed: {
    filteredJobTypes () {
      const filters = [];

      if (this.filters.name) {
        filters.push((jobType) => jobType.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }

      if (this.filters.associatedGroup.length > 0) {
        filters.push((jobType) => _.intersection(this.filters.associatedGroup, jobType.settings.associatedGroups).length > 0);
      }

      if (this.filters.associatedShiftTypes.length > 0) {
        filters.push((jobType) => _.intersection(this.filters.associatedShiftTypes, jobType.settings.associatedShiftTypes).length > 0);
      }

      if (this.filters.swappableJobTypes.length > 0) {
        filters.push((jobType) => _.intersection(this.filters.swappableJobTypes, jobType.swappableJobTypes).length > 0);
      }

      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let jobTypes = this.jobTypes;
      if (filters.length > 0) {
        jobTypes = _.filter(this.jobTypes, (jobType) => filters.reduce((matches, filter) => {
          matches &= filter(jobType);
          return matches;
        }, true));
      }

      return _.orderBy(jobTypes, this.sort.by, orders);
    },
    groupsAvailable () {
      const groups = [];
      for (let id in GROUPS) {
        groups.push({
          label: GROUPS[id],
          value: id
        });
      }
      return _.sortBy(groups, ['label']);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 225
        },
        {
          sortable: false,
          text: this.$t('labels.description'),
          value: 'description',
          width: 225
        },
        {
          sortable: false,
          text: this.$t('labels.associatedGroup'),
          value: 'associatedGroup',
          width: 200
        },
        {
          sortable: false,
          text: this.$t('labels.associatedShiftTypes'),
          value: 'associatedShiftTypes',
          width: 200
        },
        {
          sortable: false,
          text: this.$t('labels.canSwapWith'),
          value: 'swappableJobTypes',
          width: 200
        },
        {
          sortable: true,
          text: this.$t('labels.involveInScheduling'),
          value: 'partakeInScheduling',
          width: '*'
        }
      ];
    },
    namesInUse () {
      let jobTypes = this.jobTypes;
      if (this.selectedJobType) {
        jobTypes = _.filter(this.jobTypes, (st) => st.id !== this.selectedJobType.id);
      }
      return jobTypes.map((st) => st.name);
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredJobTypes.length / this.pagination.itemsPerPage);
    },
    paginatedJobTypes () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredJobTypes.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredJobTypes;
    },
    jobTypes () {
      return _.cloneDeep(this.$store.state.org.jobTypes);
    },
    jobTypesAvailable () {
      let jobTypes = _.sortBy(this.$store.state.org.jobTypes, ['name']);
      if (this.selectedJobType) {
        jobTypes = _.filter(jobTypes, (jt) => jt.id !== this.selectedJobType.id);
      }
      return jobTypes.map((st) => {
        return {
          label: st.name,
          value: st.id
        };
      });
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    shiftTypesAvailable () {
      const shiftTypes = _.sortBy(this.$store.state.org.shiftTypes, ['name']);
      return shiftTypes.map((st) => {
        return {
          label: `${st.name} (${this.formatTime(st.startTime)} - ${this.formatTime(st.endTime)})`,
          value: st.id
        };
      });
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    add () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/createJobType', this.getPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.jobTypeSaveSuccess'),
            type: 'success'
          });
          this.closeJobTypeDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.jobTypeSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeDeleteConfirmationDialog () {
      this.deleteDialog.show = false;
      this.deleteDialog.jobType = null;
    },
    closeJobTypeDialog () {
      this.showJobTypeDialog = false;
      this.selectedJobType = null;
    },
    deleteJobType (jobType) {
      this.dispatch('org/deleteJobType', jobType.id).then(() => {
        showStatus({
          text: this.$t('descriptions.jobTypeDeletionSuccess', { name: jobType.name }),
          type: 'success'
        });
        this.$emit('close');
      }).catch((error) => {
        const status = _.get(error, 'response.status', '');
        const responseData = {
          error: _.get(error, 'response.data')
        };

        let text = '';
        if (status === ERROR_CODES.http412PreconditionFailed) {
          text = this.$t('descriptions.jobTypeCannotDelete', { name: jobType.name });
        } else {
          text = this.$t('descriptions.jobTypeDeletionFail', { name: jobType.name });
        }

        showStatus({
          text,
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.closeDeleteConfirmationDialog();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatTime (time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    },
    getPayload () {
      const payload = _.cloneDeep(this.selectedJobType);
      payload.settings.associatedGroups = [payload.settings.associatedGroups];
      return payload;
    },
    getAssociatedGroupDisplay (jobType) {
      const associatedGroups = _.get(jobType, 'settings.associatedGroups', []);
      return associatedGroups.join(', ');
    },
    getAssociatedShiftTypesDisplay (jobType) {
      const associatedShiftTypeIds = _.get(jobType, 'settings.associatedShiftTypes', []);
      const associatedShiftTypes = [];
      for (let i = 0, count = associatedShiftTypeIds.length; i < count; i++) {
        if (this.shiftTypesById[associatedShiftTypeIds[i]]) {
          associatedShiftTypes.push(this.shiftTypesById[associatedShiftTypeIds[i]].name);
        }
      }
      return associatedShiftTypes.join(', ');
    },
    getSwappableJobTypesDisplay (jobType) {
      return jobType.swappableJobTypes.map((id) => _.get(this.jobTypesById, [id, 'name'], '')).join(', ');
    },
    onIsChargeNurseChange (value) {
      if (value) {
        this.selectedJobType.settings.canBeChargeNurse = true;
      }
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openDeleteConfirmationDialog (jobType) {
      this.deleteDialog.jobType = jobType;
      this.deleteDialog.show = true;
    },
    openJobTypeDialog (jobType) {
      let selectedJobType = {
        description: '',
        name: '',
        obsolete: false,
        partakeInScheduling: true,
        settings: {
          associatedGroups: '',
          associatedShiftTypes: [],
          canBeChargeNurse: false,
          isChargeNurse: false
        },
        swappableJobTypes: []
      };
      if (jobType) {
        selectedJobType = _.cloneDeep(jobType);
        // At the moment only one group can be associated therefore use the first value in the array.
        // When saving the job type, the structure will be updated back to an array.
        selectedJobType.settings.associatedGroups = selectedJobType.settings.associatedGroups[0];
      }
      this.selectedJobType = selectedJobType;
      this.showJobTypeDialog = true;
    },
    setFilter (field, value) {
      this.filters[field] = value;
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateJobType', this.getPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.jobTypeSaveSuccess'),
            type: 'success'
          });
          this.closeJobTypeDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.jobTypeSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
#jobTypes {
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .job-type-dial {
    right: 0px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
  thead th span {
    font-size: 12px !important;
  }
}
</style>
