var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-10 my-3", attrs: { fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$tc("labels.general", 2), disabled: false },
                { text: _vm.$t("labels.daily"), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { id: "dailySchedules" } },
        [
          _c(
            "v-tabs",
            {
              staticClass: "dense mt-4",
              attrs: {
                color: "grey darken-3",
                "show-arrows": "",
                "slider-color": "accent",
                "slider-size": "3"
              }
            },
            [
              _c("v-tab", { attrs: { href: "#rounds" } }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("labels.scheduleRound")) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-tab-item",
                { attrs: { value: "rounds" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "actions", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass:
                              "name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block",
                            attrs: {
                              "append-icon": _vm.filters.name
                                ? ""
                                : "fal fa-search",
                              clearable: !!_vm.filters.name,
                              dense: "",
                              "hide-details": "",
                              placeholder: _vm.$t("labels.searchByName"),
                              solo: ""
                            },
                            model: {
                              value: _vm.filters.name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.filters,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "filters.name"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "float-right mt-2 mr-4",
                              attrs: { color: "secondary" },
                              on: {
                                click: function($event) {
                                  return _vm.openDailyScheduleDialog()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.addDailySchedule")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "resize",
                        rawName: "v-resize.quiet",
                        value: _vm.onWindowResized,
                        expression: "onWindowResized",
                        modifiers: { quiet: true }
                      }
                    ],
                    attrs: {
                      "fixed-header": "",
                      headers: _vm.headers,
                      "header-props": { sortIcon: "fa fa-arrow-up" },
                      "hide-default-footer": "",
                      items: _vm.paginatedDailySchedules,
                      "items-per-page": _vm.pagination.itemsPerPage,
                      "must-sort": "",
                      "single-select": "",
                      "sort-by": _vm.sort.by,
                      "sort-desc": _vm.sort.desc
                    },
                    on: {
                      "update:sortBy": function($event) {
                        return _vm.$set(_vm.sort, "by", $event)
                      },
                      "update:sort-by": function($event) {
                        return _vm.$set(_vm.sort, "by", $event)
                      },
                      "update:sortDesc": function($event) {
                        return _vm.$set(_vm.sort, "desc", $event)
                      },
                      "update:sort-desc": function($event) {
                        return _vm.$set(_vm.sort, "desc", $event)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header.description",
                        fn: function(ref) {
                          var header = ref.header
                          return [
                            _c(
                              "span",
                              { staticClass: "grey--text text--darken-3" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(header.text) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "header.associatedShiftTypes",
                        fn: function(ref) {
                          var header = ref.header
                          return [
                            _c("MultiSelectionList", {
                              attrs: { selection: _vm.shiftTypesAvailable },
                              on: {
                                selectionConfirmed: function($event) {
                                  return _vm.setFilter(header.value, $event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "ml-n4 subtitle-2 text-capitalize",
                                              attrs: {
                                                color: "secondary",
                                                text: ""
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(header.text) +
                                                "\n                  "
                                            ),
                                            _vm.filters[header.value].length > 0
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      color: "grey",
                                                      right: "",
                                                      "x-small": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    fas fa-filter\n                  "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      color: "grey",
                                                      right: "",
                                                      "x-small": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    fal fa-filter\n                  "
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          var tableHeaders = ref.headers
                          return [
                            _c(
                              "tr",
                              {
                                class: item.obsolete ? "obsolete" : "",
                                on: {
                                  mouseenter: function($event) {
                                    _vm.hoveredItem = item
                                  },
                                  mouseleave: function($event) {
                                    _vm.hoveredItem = null
                                  }
                                }
                              },
                              [
                                _vm._l(tableHeaders, function(header) {
                                  return _c(
                                    "td",
                                    {
                                      key: header.value,
                                      staticClass: "text-start"
                                    },
                                    [
                                      header.value === "name"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "grey--text text--darken-3 text-truncate d-block",
                                              style: {
                                                width: header.width + "px"
                                              },
                                              attrs: { title: item.name }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.name) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      header.value === "description"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "grey--text text--darken-3 text-truncate d-block",
                                              style: {
                                                width: header.width + "px"
                                              },
                                              attrs: { title: item.description }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.description) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      header.value === "associatedShiftTypes"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "grey--text text--darken-3 text-truncate d-block",
                                              style: {
                                                width: header.width + "px"
                                              },
                                              attrs: {
                                                title: _vm.getAssociatedShiftTypesDisplay(
                                                  item
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getAssociatedShiftTypesDisplay(
                                                      item
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      header.value === "censusCollectionTimes"
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "grey--text text--darken-3 text-truncate d-block",
                                              style: {
                                                width: header.width + "px"
                                              },
                                              attrs: {
                                                title: _vm.getCensusCollectionTimesDisplay(
                                                  item
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getCensusCollectionTimesDisplay(
                                                      item
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      header.value === "receiveCensus"
                                        ? [
                                            _c(
                                              "span",
                                              { staticClass: "d-inline-block" },
                                              [
                                                _vm.receivesCensus(item)
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "success",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      fas fa-check-circle\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "grey",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      far fa-times-circle\n                    "
                                                        )
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                }),
                                _c(
                                  "v-speed-dial",
                                  {
                                    staticClass: "mt-6 daily-schedule-dial",
                                    attrs: {
                                      absolute: "",
                                      value:
                                        _vm.hoveredItem &&
                                        _vm.hoveredItem.id === item.id,
                                      right: "",
                                      direction: "left",
                                      transition: "slide-x-reverse-transition"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "edit",
                                        attrs: {
                                          fab: "",
                                          height: "30",
                                          width: "30",
                                          title: _vm.$t(
                                            "labels.editDailySchedule"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDailyScheduleDialog(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(
                                            "\n                    fal fa-pencil\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "delete",
                                        attrs: {
                                          fab: "",
                                          height: "30",
                                          width: "30",
                                          title: _vm.$t(
                                            "labels.deleteDailySchedule"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDeleteConfirmationDialog(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "error", small: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    fal fa-trash-alt\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ]
                        }
                      },
                      {
                        key: "footer",
                        fn: function() {
                          return [
                            _c("v-pagination", {
                              staticClass: "py-4 footer",
                              attrs: {
                                color: "secondary",
                                length: _vm.numberOfPages,
                                "next-icon": "far fa-chevron-right",
                                "prev-icon": "far fa-chevron-left",
                                "total-visible":
                                  _vm.pagination.maxPaginationControls
                              },
                              model: {
                                value: _vm.pagination.currentPage,
                                callback: function($$v) {
                                  _vm.$set(_vm.pagination, "currentPage", $$v)
                                },
                                expression: "pagination.currentPage"
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showDailyScheduleDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: "500px",
                value: _vm.showDailyScheduleDialog
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-0", attrs: { id: "dailySchedule" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-2" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selectedDailySchedule.id
                                ? _vm.$t("labels.editDailySchedule")
                                : _vm.$t("labels.addDailySchedule")
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeDailyScheduleDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var invalid = ref.invalid
                                var passes = ref.passes
                                return [
                                  _c(
                                    "v-form",
                                    { staticClass: "daily-schedule" },
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "pt-0 px-6" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "labels.name"
                                                      ),
                                                      name: "name",
                                                      outlined: "",
                                                      rules: {
                                                        required: true,
                                                        max: 32,
                                                        excluded: _vm.namesInUse
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .selectedDailySchedule
                                                          .name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedDailySchedule,
                                                          "name",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedDailySchedule.name"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "labels.description"
                                                      ),
                                                      name: "description",
                                                      outlined: "",
                                                      rules: "max:256"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .selectedDailySchedule
                                                          .description,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedDailySchedule,
                                                          "description",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedDailySchedule.description"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("VeeSelect", {
                                                    staticClass: "shift-types",
                                                    attrs: {
                                                      dense: "",
                                                      item: "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items:
                                                        _vm.shiftTypesAvailable,
                                                      label: _vm.$t(
                                                        "labels.associatedShiftTypes"
                                                      ),
                                                      multiple: "",
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .selectedDailySchedule
                                                          .shiftTypes,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedDailySchedule,
                                                          "shiftTypes",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedDailySchedule.shiftTypes"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pt-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "pt-0 mt-0 ml-1 d-inline-block collect-census",
                                                    attrs: {
                                                      color: "success",
                                                      dense: "",
                                                      "hide-details": "",
                                                      inset: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-4 body-2 grey--text text--darken-3",
                                                                  attrs: {
                                                                    title: _vm.$t(
                                                                      "labels.collectCensus"
                                                                    )
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.collectCensus"
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedDailyScheduleCollectCensus,
                                                      callback: function($$v) {
                                                        _vm.selectedDailyScheduleCollectCensus = $$v
                                                      },
                                                      expression:
                                                        "selectedDailyScheduleCollectCensus"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.selectedDailyScheduleCollectCensus
                                            ? [
                                                _vm._l(
                                                  _vm.selectedDailySchedule
                                                    .settings.census.predefined,
                                                  function(census, idx) {
                                                    return _c(
                                                      "v-row",
                                                      { key: idx },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "5" }
                                                          },
                                                          [
                                                            _c("VeeSelect", {
                                                              staticClass:
                                                                "census-time",
                                                              attrs: {
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                "item-text":
                                                                  "name",
                                                                "item-value":
                                                                  "id",
                                                                items:
                                                                  _vm.availableTimes,
                                                                label: _vm.$t(
                                                                  "labels.collectionTime"
                                                                ),
                                                                name:
                                                                  "collectionTime",
                                                                outlined: "",
                                                                rules:
                                                                  "required",
                                                                vid:
                                                                  "censusTime" +
                                                                  idx
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.updateCensusCollectionName(
                                                                    idx
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  census.time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    census,
                                                                    "time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "census.time"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "5" }
                                                          },
                                                          [
                                                            _c("VeeSelect", {
                                                              staticClass:
                                                                "census-offset",
                                                              attrs: {
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                "item-text":
                                                                  "name",
                                                                "item-value":
                                                                  "id",
                                                                items:
                                                                  _vm.availableDays,
                                                                label: _vm.$t(
                                                                  "labels.collectionDay"
                                                                ),
                                                                name:
                                                                  "censusOffset",
                                                                outlined: "",
                                                                rules:
                                                                  "required",
                                                                vid:
                                                                  "censusOffset" +
                                                                  idx
                                                              },
                                                              model: {
                                                                value:
                                                                  census.dayOffset,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    census,
                                                                    "dayOffset",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "census.dayOffset"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: { cols: "2" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "remove-census",
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeCensus(
                                                                      idx
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        fal fa-trash-alt\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "caption add-census secondary--text",
                                                    on: { click: _vm.addCensus }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  + " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.addCensusCollectionTime"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pb-0 pt-4 px-8",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    disabled: _vm.saving,
                                                    text: ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.closeDailyScheduleDialog
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("labels.cancel")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _vm.selectedDailySchedule.id
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "submit",
                                                      attrs: {
                                                        color: "secondary",
                                                        disabled:
                                                          invalid || _vm.saving
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return passes(
                                                            _vm.update
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.saving
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary lighten-2",
                                                                indeterminate:
                                                                  "",
                                                                size: "22",
                                                                width: "2"
                                                              }
                                                            }
                                                          )
                                                        : _c("span", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.save"
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "submit",
                                                      attrs: {
                                                        color: "secondary",
                                                        disabled:
                                                          invalid || _vm.saving
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return passes(_vm.add)
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.saving
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary lighten-2",
                                                                indeterminate:
                                                                  "",
                                                                size: "22",
                                                                width: "2"
                                                              }
                                                            }
                                                          )
                                                        : _c("span", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.add"
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3492167703
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog.show
        ? _c("DeleteConfirmation", {
            attrs: {
              action: "org/checkDailyScheduleDependencies",
              "delete-body": _vm.$t(
                "descriptions.continueDeleteDailySchedule",
                { name: _vm.deleteDialog.dailySchedule.name }
              ),
              "dependencies-body": _vm.$t(
                "descriptions.hasDependenciesDailySchedule",
                { name: _vm.deleteDialog.dailySchedule.name }
              ),
              item: _vm.deleteDialog.dailySchedule,
              title: _vm.$t("labels.deleteDailySchedule?")
            },
            on: {
              close: _vm.closeDeleteConfirmationDialog,
              del: _vm.deleteDailySchedule
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }