var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "420" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { id: "verifyUserDialog" } },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  var passes = ref.passes
                  return [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "grey--text text--darken-3 subtitle-2 pt-4 pb-0 d-block"
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("labels.addUser")) +
                            "\n        "
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right",
                            attrs: { icon: "", "x-small": "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("fal fa-times")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "mt-4" },
                      [
                        _c("VeeTextField", {
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            label: _vm.$t("labels.email"),
                            name: "email",
                            outlined: "",
                            rules: "email|required|max:255"
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        }),
                        _c("span", { staticClass: "grey--text caption ml-3" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("descriptions.verifyEmail")) +
                              "\n        "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-3 px-4",
                            attrs: { text: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.cancel")) +
                                "\n        "
                            )
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "ma-3 verify",
                            attrs: {
                              color: "secondary",
                              disabled: invalid || _vm.checking
                            },
                            on: {
                              click: function($event) {
                                return passes(_vm.verify)
                              }
                            }
                          },
                          [
                            _vm.checking
                              ? _c("v-progress-circular", {
                                  attrs: {
                                    color: "secondary",
                                    indeterminate: "",
                                    size: "22",
                                    width: "2"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.next")) +
                                      "\n          "
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }