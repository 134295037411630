<template>
  <v-container
    id="eventRequest"
    class="px-0"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $t('labels.schedule'), disabled: false }, { text: $tc('labels.event', 1), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      class="mx-10 my-3 pa-0"
    >
      <v-tabs
        class="dense mt-4"
        color="grey darken-3"
        show-arrows
        slider-color="accent"
        slider-size="3"
      >
        <v-tab
          href="#types"
        >
          <span>
            {{ $t('labels.type') }}
          </span>
        </v-tab>
        <v-tab
          href="#settings"
        >
          <span>
            {{ $tc('labels.setting', 2) }}
          </span>
        </v-tab>
        <v-tab-item value="types">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              class="actions"
              no-gutters
            >
              <v-col>
                <v-text-field
                  v-model.trim="filters.name"
                  :append-icon="filters.name ? '' : 'fal fa-search'"
                  class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
                  :clearable="!!filters.name"
                  dense
                  hide-details
                  :placeholder="$t('labels.searchByName')"
                  solo
                />
              </v-col>
            </v-row>
            <v-data-table
              v-resize.quiet="onWindowResized"
              fixed-header
              :headers="headers"
              :header-props="{ sortIcon: 'fa fa-arrow-up' }"
              hide-default-footer
              :items="paginatedEventTypes"
              :items-per-page="pagination.itemsPerPage"
              must-sort
              single-select
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
            >
              <template #header.description="{ header }">
                <span class="grey--text text--darken-3">
                  {{ header.text }}
                </span>
              </template>
              <template #header.availableStatesDisplay="{ header }">
                <MultiSelectionList
                  :selection="statesAvailable"
                  @selectionConfirmed="setFilter(header.value, $event)"
                >
                  <template #activator="{ on }">
                    <v-btn
                      class="ml-n4 subtitle-2 text-capitalize"
                      color="secondary"
                      text
                      v-on="on"
                    >
                      {{ header.text }}
                      <v-icon
                        v-if="filters[header.value].length > 0"
                        class="ml-2"
                        color="grey"
                        right
                        x-small
                      >
                        fas fa-filter
                      </v-icon>
                      <v-icon
                        v-else
                        class="ml-2"
                        color="grey"
                        right
                        x-small
                      >
                        fal fa-filter
                      </v-icon>
                    </v-btn>
                  </template>
                </MultiSelectionList>
              </template>
              <template #item="{ item, headers: tableHeaders }">
                <tr
                  :class="item.obsolete ? 'obsolete' : ''"
                  @mouseenter="hoveredItem = item"
                  @mouseleave="hoveredItem = null"
                >
                  <td
                    v-for="header in tableHeaders"
                    :key="header.value"
                    class="text-start"
                  >
                    <span
                      v-if="header.value === 'name'"
                      class="grey--text text--darken-3 text-truncate d-block"
                      :style="{ width: `${header.width}px` }"
                      :title="item.name"
                    >
                      {{ item.name }}
                    </span>
                    <span
                      v-if="header.value === 'description'"
                      class="grey--text text--darken-3 text-truncate d-block"
                      :style="{ width: `${header.width}px` }"
                      :title="item.description"
                    >
                      {{ item.description }}
                    </span>
                    <span
                      v-if="header.value === 'availableStatesDisplay'"
                      class="grey--text text--darken-3 text-truncate d-block"
                      :style="{ width: `${header.width}px` }"
                      :title="item.availableStatesDisplay"
                    >
                      {{ item.availableStatesDisplay }}
                    </span>
                  </td>
                  <v-speed-dial
                    absolute
                    class="mt-6 event-type-dial"
                    :value="hoveredItem && hoveredItem.id === item.id"
                    right
                    :direction="'left'"
                    :transition="'slide-x-reverse-transition'"
                  >
                    <v-btn
                      class="edit"
                      fab
                      height="30"
                      width="30"
                      :title="$t('labels.editEventType')"
                      @click="openEventTypeDialog(item)"
                    >
                      <v-icon small>
                        fal fa-pencil
                      </v-icon>
                    </v-btn>
                  </v-speed-dial>
                </tr>
              </template>
              <template #footer>
                <v-pagination
                  v-model="pagination.currentPage"
                  class="py-4 footer"
                  color="secondary"
                  :length="numberOfPages"
                  next-icon="far fa-chevron-right"
                  prev-icon="far fa-chevron-left"
                  :total-visible="pagination.maxPaginationControls"
                />
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
        <v-tab-item value="settings">
          <ValidationObserver
            v-slot="{ invalid, passes }"
            ref="settings"
          >
            <v-container
              class="px-4 py-0 mx-0 settings"
              fluid
            >
              <v-row dense>
                <v-col cols="10">
                  <v-radio-group
                    v-model="settings.mode"
                    column
                    dense
                  >
                    <v-radio
                      class="single-reviewer"
                      value="single"
                    >
                      <template v-slot:label>
                        <div>
                          <span class="body-2">
                            {{ $t('labels.alwaysReviewedBy') }}
                          </span>
                          <VeeSelect
                            v-model="settings.single"
                            class="d-inline-block single-reviewer"
                            dense
                            :disabled="settings.mode === 'multiple'"
                            hide-details
                            item
                            item-text="label"
                            item-value="value"
                            :items="reviewersAvailable"
                            :label="$tc('labels.jobType', 1)"
                            outlined
                            :rules="{ required: settings.mode === 'single' }"
                            vid="singleReviewer"
                          />
                        </div>
                      </template>
                    </v-radio>
                    <v-radio
                      class="multiple-reviewer mt-3"
                      value="multiple"
                    >
                      <template v-slot:label>
                        <div>
                          <span class="body-2">
                            {{ $t('labels.eventRequestWithin1') }}
                          </span>
                          <VeeTextField
                            v-model.number="settings.multiple.latent"
                            class="d-inline-block"
                            dense
                            :disabled="settings.mode === 'single'"
                            hide-details
                            :placeholder="$t('labels.number').toLowerCase()"
                            name="multipleReviewerLatent"
                            outlined
                            :rules="{ required: settings.mode === 'multiple', min_value: 1, numeric: true }"
                            style="width: 80px"
                            vid="multipleReviewerLatent"
                          />
                          <span class="body-2">
                            {{ $t('labels.eventRequestWithin2') }}
                          </span>
                          <VeeSelect
                            v-model="settings.multiple.reviewer"
                            class="d-inline-block multiple-reviewer"
                            dense
                            :disabled="settings.mode === 'single'"
                            hide-details
                            item
                            item-text="label"
                            item-value="value"
                            :items="reviewersAvailable"
                            :label="$tc('labels.jobType', 1)"
                            outlined
                            :rules="{ required: settings.mode === 'multiple' }"
                            vid="multipleReviewer"
                          />
                          <div class="mt-3">
                            <span class="body-2">
                              {{ $t('labels.eventRequestWithin3') }}
                            </span>
                            <VeeSelect
                              v-model="settings.multiple.altReviewer"
                              class="d-inline-block multiple-reviewer-alt"
                              dense
                              :disabled="settings.mode === 'single'"
                              hide-details
                              item
                              item-text="label"
                              item-value="value"
                              :items="reviewersAvailable"
                              :label="$tc('labels.jobType', 1)"
                              outlined
                              :rules="{ required: settings.mode === 'multiple' }"
                              vid="multipleReviewerAlt"
                            />
                          </div>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
            <v-row
              class="pa-4"
              dense
            >
              <v-spacer />
              <v-col
                class="text-right"
                cols="6"
              >
                <v-btn
                  class="ml-3 save-settings"
                  color="secondary"
                  :disabled="invalid || saving"
                  @click="passes(saveSettings)"
                >
                  <v-progress-circular
                    v-if="saving"
                    color="secondary"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.save') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog
      v-if="showEventTypeDialog"
      persistent
      width="600px"
      :value="showEventTypeDialog"
    >
      <v-card
        id="eventTypeDialog"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ `${$t('labels.editRequestRules')} - ${selectedEventType.name}` }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeEventTypeDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form>
              <v-container class="pt-0 px-6">
                <v-row
                  class="mb-2"
                  dense
                >
                  <v-col>
                    <span class="grey--text text--darken-1 caption">
                      {{ $t('descriptions.disabledRequestStates', { type: selectedEventType.name.toLowerCase() }) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <VeeSelect
                      v-model="selectedEventType.disabledStates"
                      class="states"
                      dense
                      item
                      item-text="label"
                      item-value="value"
                      :items="statesAvailable"
                      :label="$tc('labels.state', 2)"
                      multiple
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeEventTypeDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(saveSelectedEventType)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.update') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import MultiSelectionList from '@/components/MultiSelectionList';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import { showStatus } from '@/plugins/vue-notification';
import { REQUEST_STATES, SCHEDULE_STATES_ORDER } from '@/views/scheduling/constants';

export default {
  components: {
    MultiSelectionList,
    VeeSelect,
    VeeTextField
  },
  data () {
    const statesSettings = _.cloneDeep(_.get(this.$store.state.org, 'settings.scheduling.request.event.states', []));
    let settingsMode = '';
    let singleReviewer = '';
    let multipleReviewer = {
      altReviewer: '',
      latent: null,
      reviewer: ''
    };
    if (statesSettings.length > 1) {
      settingsMode = 'multiple';
      multipleReviewer.latent = statesSettings[0].within.days;
      multipleReviewer.reviewer = statesSettings[0].initial.other;
      multipleReviewer.altReviewer = statesSettings[1].initial.other;
    } else {
      settingsMode = 'single';
      if (statesSettings[0]) {
        singleReviewer = statesSettings[0].initial.other;
      }
    }

    return {
      filters: {
        availableStatesDisplay: [],
        name: ''
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedEventType: null,
      settings: {
        mode: settingsMode,
        multiple: multipleReviewer,
        single: singleReviewer
      },
      showEventTypeDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      }
    };
  },
  computed: {
    eventTypes () {
      const eventTypes = _.cloneDeep(this.$store.state.org.eventTypes);
      const states = _.intersection(this.$store.state.org.settings.scheduling.states.map((data) => data.state), SCHEDULE_STATES_ORDER);
      for (let i = 0, count = eventTypes.length; i < count; i++) {
        const disable = _.get(eventTypes[i], 'settings.request.disable', []);
        let availableStates = states;
        for (let disableIdx = 0, disableCount = disable.length; disableIdx < disableCount; disableIdx++) {
          switch (disable[disableIdx].type) {
            case 'schedule_state':
              availableStates = _.difference(states, disable[disableIdx].value);
              break;
          }
        }
        eventTypes[i].availableStates = availableStates;
        eventTypes[i].availableStatesDisplay = availableStates.map((s) => this.$t(`labels.${_.camelCase(s)}`)).join(', ');
      }
      return eventTypes;
    },
    filteredEventTypes () {
      const filters = [];

      if (this.filters.name) {
        filters.push((eventType) => eventType.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }
      if (this.filters.availableStatesDisplay.length > 0) {
        filters.push((event) => _.intersection(this.filters.availableStatesDisplay, event.availableStates).length > 0);
      }
      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let eventTypes = this.eventTypes;
      if (filters.length > 0) {
        eventTypes = _.filter(this.eventTypes, (eventType) => filters.reduce((matches, filter) => {
          matches &= filter(eventType);
          return matches;
        }, true));
      }

      return _.orderBy(eventTypes, this.sort.by, orders);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 200
        },
        {
          sortable: false,
          text: this.$t('labels.description'),
          value: 'description',
          width: 250
        },
        {
          sortable: false,
          text: this.$t('labels.availableStates'),
          value: 'availableStatesDisplay',
          width: '*'
        }
      ];
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredEventTypes.length / this.pagination.itemsPerPage);
    },
    paginatedEventTypes () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredEventTypes.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredEventTypes;
    },
    reviewersAvailable () {
      return [
        {
          label: this.$t('labels.pendingApprovalDirectorGroup'),
          value: REQUEST_STATES.PENDING_DIRECTOR_APPROVAL
        },
        {
          label: this.$t('labels.pendingApprovalOperatorGroup'),
          value: REQUEST_STATES.PENDING_OPERATOR_APPROVAL
        }
      ];
    },
    statesAvailable () {
      return _.intersection(this.$store.state.org.settings.scheduling.states.map((data) => data.state), SCHEDULE_STATES_ORDER).map((state) => {
        return {
          label: this.$t(`labels.${_.camelCase(state)}`),
          value: state
        };
      });
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeEventTypeDialog () {
      this.showEventTypeDialog = false;
      this.selectedEventType = null;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openEventTypeDialog (item) {
      const eventType = _.cloneDeep(this.$store.getters['org/getEventTypeById'](item.id));
      let disabledStates = [];
      const disbledRules = _.get(eventType, 'settings.request.disable', []);
      if (disbledRules.length > 0) {
        const scheduleStateRuleIdx = _.findIndex(disbledRules, (r) => r.type === 'schedule_state');
        if (scheduleStateRuleIdx >= 0) {
          disabledStates = disbledRules[scheduleStateRuleIdx].value;
        }
      }
      eventType.disabledStates = disabledStates;
      this.selectedEventType = eventType;
      this.showEventTypeDialog = true;
    },
    saveSelectedEventType () {
      if (!this.saving) {
        this.saving = true;
        const states = [
          {
            op: 'in',
            type: 'schedule_state',
            value: this.selectedEventType.disabledStates
          }
        ];
        const payload = {
          id: this.selectedEventType.id,
          settings: {
            ...this.selectedEventType.settings,
            request: {
              disable: states
            }
          }
        };
        this.dispatch('org/updateEventType', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.eventTypeSaveSuccess'),
            type: 'success'
          });
          this.closeEventTypeDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.eventTypeSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    saveSettings () {
      if (!this.saving) {
        this.saving = true;
        let states = [];
        if (this.settings.mode === 'single') {
          states = [{
            for_review: {
              other: this.settings.single
            },
            initial: {
              other: this.settings.single
            },
            type: 'fallback'
          }];
        } else {
          states = [
            {
              for_review: {
                other: this.settings.multiple.reviewer
              },
              initial: {
                other: this.settings.multiple.reviewer
              },
              type: 'day_to_day',
              within: {
                days: this.settings.multiple.latent
              }
            },
            {
              for_review: {
                other: this.settings.multiple.altReviewer
              },
              initial: {
                other: this.settings.multiple.altReviewer
              },
              type: 'fallback'
            }
          ];
        }
        const settings = [
          {
            path: ['scheduling', 'request', 'event', 'states'],
            value: states
          }
        ];
        this.dispatch('org/updateHospitalSettings', settings).then(() => {
          showStatus({
            text: this.$t('descriptions.requestSettingsUpdateSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestSettingsUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    setFilter (field, value) {
      this.filters[field] = value;
    }
  }
};
</script>

<style lang="scss">
#eventRequest {
  .v-tabs {
    min-width: 820px;
    .v-tabs-bar__content {
      border-bottom: 1px solid map-get($grey, 'lighten-2');
    }
  }
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .event-type-dial {
    right: 0px !important;
  }
  .multiple-reviewer {
    .v-input--selection-controls__ripple {
      position: absolute;
      top: -33px;
    }
    .v-input--selection-controls__input > .v-icon {
      position: absolute;
      top: -22px;
    }
  }
  thead th span {
    font-size: 12px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
}
</style>
