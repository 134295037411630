<template>
  <v-container
    id="shiftRequest"
    class="px-0"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.request', 1), disabled: false }, { text: $t('labels.shift'), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      class="mx-10 my-3 pa-0"
    >
      <v-data-table
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="requestTypes"
        :items-per-page="requestTypes.length"
        single-select
      >
        <template #header.availableStates="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #item="{ item, headers: tableHeaders }">
          <tr
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <span
                class="grey--text text--darken-3 text-truncate d-block"
                :style="header.width === '*' ? {} : { width: `${header.width}px` }"
                :title="item[header.value]"
              >
                {{ item[header.value] }}
              </span>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 dial"
              :value="hoveredItem && hoveredItem.type === item.type"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="edit"
                fab
                height="30"
                width="30"
                :title="$t('labels.edit')"
                @click="openEditDialog(item.type)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showEditDialog"
      persistent
      width="600px"
      :value="showEditDialog"
    >
      <v-card
        id="editDialog"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ `${$t('labels.editRequestRules')} - ${$tc(`labels.${selectedType.type}`, 1).toLowerCase()}` }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeEditDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form>
              <v-container class="pt-0 px-6">
                <v-row
                  class="mb-2"
                  dense
                >
                  <v-col>
                    <span class="grey--text text--darken-1 caption">
                      {{ $t('descriptions.cutOffTimeSection') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <VeeTextField
                      v-model.number="selectedType.cutOffTime"
                      class="d-inline-block"
                      dense
                      :label="$t('labels.cutOffTime')"
                      name="cutOffTime"
                      outlined
                      :rules="{ required: true, numeric: true, max_value: 12, min_value: 0 }"
                      style="width: 110px"
                    />
                    <span class="grey--text text--darken-3 body-2 ml-2">
                      {{ $t('descriptions.cutOffTimeHint') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  class="mb-2"
                  dense
                >
                  <v-col>
                    <span class="grey--text text--darken-1 caption">
                      {{ $t('descriptions.disabledRequestStates', { type: $tc(`labels.${selectedType.type}`, 1).toLowerCase() }) }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <VeeSelect
                      v-model="selectedType.disabledStates"
                      class="states"
                      dense
                      item
                      item-text="label"
                      item-value="value"
                      :items="statesAvailable"
                      :label="$tc('labels.state', 2)"
                      multiple
                      outlined
                      rules="required"
                      @change="updateSpecificReviewers"
                    />
                  </v-col>
                </v-row>
                <v-row
                  class="mb-2"
                  dense
                >
                  <v-col>
                    <span class="grey--text text--darken-1 caption">
                      {{ $t('descriptions.designateReviewers') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row
                  dense
                >
                  <v-col cols="3">
                    <div class="grey--text text--darken-3 body-2 mt-3">
                      {{ $t('labels.defaultReviewer') }}
                    </div>
                  </v-col>
                  <v-col cols="9">
                    <VeeSelect
                      v-model="selectedType.reviewers.default"
                      class="default-reviewer"
                      dense
                      hide-details
                      item-text="label"
                      item-value="value"
                      :items="reviewersAvailable"
                      :label="$tc('labels.jobType', 1)"
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-for="(item, idx) in selectedType.reviewers.specific"
                  :key="idx"
                >
                  <v-col cols="3">
                    <div class="grey--text text--darken-3 body-2 mt-3">
                      {{ $t('labels.reviewBy') }}
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <VeeSelect
                      v-model="item.reviewer"
                      class="specific-reviewer-type"
                      dense
                      hide-details
                      item-text="label"
                      item-value="value"
                      :items="reviewersAvailable"
                      :label="$tc('labels.jobType', 1)"
                      outlined
                      rules="required"
                      :vid="`jobType${idx}`"
                    />
                  </v-col>
                  <v-col cols="4">
                    <VeeSelect
                      v-model="item.state"
                      class="specific-reviewer-state"
                      dense
                      hide-details
                      item-text="label"
                      item-value="value"
                      :items="getSpecificStatesAvailable(idx)"
                      :label="$tc('labels.state', 1)"
                      outlined
                      rules="required"
                      :vid="`state${idx}`"
                    />
                  </v-col>
                  <v-col
                    class="pl-0"
                    cols="1"
                  >
                    <v-btn
                      class="remove-reviewer"
                      icon
                      @click="removeReviewer(idx)"
                    >
                      <v-icon
                        color="error"
                        small
                      >
                        fal fa-trash-alt
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <a
                  class="caption add-reviewer secondary--text"
                  @click="addReviewer"
                >
                  + {{ $t('labels.addReviewer') }}
                </a>
              </v-container>
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeEditDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.update') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import VeeSelect from '@/components/form_controls/VeeSelect';
import VeeTextField from '@/components/form_controls/VeeTextField';
import { REQUEST_STATES, SCHEDULE_STATES_ORDER } from '@/views/scheduling/constants';
import { showStatus } from '@/plugins/vue-notification';

export default {
  components: {
    VeeSelect,
    VeeTextField
  },
  data () {
    return {
      headers: [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 150
        },
        {
          sortable: false,
          text: this.$t('labels.availableStates'),
          value: 'availableStates',
          width: 250
        },
        {
          sortable: true,
          text: this.$t('labels.cutOffTime'),
          value: 'cutOffTime',
          width: 150
        },
        {
          sortable: true,
          text: this.$t('labels.reviewBy'),
          value: 'reviewBy',
          width: '*'
        }
      ],
      hoveredItem: null,
      saving: false,
      selectedType: null,
      showEditDialog: false,
      types: ['giveaway', 'split', 'swap']
    };
  },
  computed: {
    requestTypes () {
      const requestTypes = [];
      const states = _.intersection(this.$store.state.org.settings.scheduling.states.map((data) => data.state), SCHEDULE_STATES_ORDER);
      for (let i = 0, count = this.types.length; i < count; i++) {
        const settings = _.cloneDeep(this.$store.state.org.settings.scheduling.request[this.types[i]]);
        let cutOffTime = '';
        let availableStates = '';
        let reviewBy = '';
        for (let disableIdx = 0, disableCount = settings.disable.length; disableIdx < disableCount; disableIdx++) {
          const disable = settings.disable[disableIdx];
          switch (disable.type) {
            case 'lead_time':
              cutOffTime = `${disable.value} hrs`;
              break;
            case 'schedule_state':
              availableStates = _.difference(states, disable.value).map((s) => this.$t(`labels.${_.camelCase(s)}`)).join(', ');
              break;
          }
        }
        for (let stateIdx = 0, statesCount = settings.states.length; stateIdx < statesCount; stateIdx++) {
          const state = settings.states[stateIdx];
          switch (state.type) {
            case 'fallback':
              reviewBy = _.uniq(_.values(state.forReview)).map(reviewer => this.$t(`labels.${_.camelCase(reviewer)}Group`)).join(', ');
              break;
          }
        }
        requestTypes.push({
          availableStates,
          cutOffTime,
          name: this.$tc(`labels.${this.types[i]}`, 1),
          reviewBy,
          type: this.types[i]
        });
      }
      return requestTypes;
    },
    reviewersAvailable () {
      return [
        {
          label: this.$t('labels.pendingApprovalDirectorGroup'),
          value: REQUEST_STATES.PENDING_DIRECTOR_APPROVAL
        },
        {
          label: this.$t('labels.pendingApprovalOperatorGroup'),
          value: REQUEST_STATES.PENDING_OPERATOR_APPROVAL
        }
      ];
    },
    specificStatesAvailable () {
      const states = _.intersection(this.$store.state.org.settings.scheduling.states.map((data) => data.state), SCHEDULE_STATES_ORDER);
      return _.difference(states, this.selectedType.disabledStates).map((s) => {
        return {
          label: this.$t(`labels.${_.camelCase(s)}`),
          value: s
        };
      });
    },
    statesAvailable () {
      return _.intersection(this.$store.state.org.settings.scheduling.states.map((data) => data.state), SCHEDULE_STATES_ORDER).map((state) => {
        return {
          label: this.$t(`labels.${_.camelCase(state)}`),
          value: state
        };
      });
    }
  },
  methods: {
    addReviewer () {
      this.selectedType.reviewers.specific.push({
        state: '',
        reviewer: ''
      });
    },
    closeEditDialog () {
      this.showEditDialog = false;
      this.selectedType = null;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getSpecificStatesAvailable (stateIdx) {
      const statesInUse = _.uniq(this.selectedType.reviewers.specific.reduce(
        (arr, currentValue, currentIndex) => {
          if (stateIdx !== currentIndex) {
            arr.push(currentValue['state']);
          }
          return arr;
        }, // eslint-disable-line no-return-assign, no-sequences
        []
      ));
      return _.filter(this.specificStatesAvailable, (state) => {
        return !statesInUse.includes(state.value);
      });
    },
    openEditDialog (type) {
      const settings = _.cloneDeep(this.$store.state.org.settings.scheduling.request[type]);
      let cutOffTime = null;
      let disabledStates = [];
      let reviewers = {
        default: '',
        specific: []
      };
      for (let disableIdx = 0, disableCount = settings.disable.length; disableIdx < disableCount; disableIdx++) {
        const disable = settings.disable[disableIdx];
        switch (disable.type) {
          case 'lead_time':
            cutOffTime = disable.value;
            break;
          case 'schedule_state':
            disabledStates = disable.value;
            break;
        }
      }
      for (let stateIdx = 0, statesCount = settings.states.length; stateIdx < statesCount; stateIdx++) {
        const state = settings.states[stateIdx];
        switch (state.type) {
          case 'fallback':
            for (let name in state.forReview) {
              switch (name) {
                case 'other':
                  reviewers.default = state.forReview.other;
                  break;
                default:
                  reviewers.specific.push({
                    state: name,
                    reviewer: state.forReview[name]
                  });
                  break;
              }
            }
            break;
        }
      }
      this.selectedType = {
        cutOffTime,
        disabledStates,
        reviewers,
        type
      };
      this.showEditDialog = true;
    },
    removeReviewer (idx) {
      this.selectedType.reviewers.specific.splice(idx, 1);
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        const forReview = {
          other: this.selectedType.reviewers.default
        };
        for (let i = 0, count = this.selectedType.reviewers.specific.length; i < count; i++) {
          const data = this.selectedType.reviewers.specific[i];
          forReview[data.state] = data.reviewer;
        }
        const settings = [
          {
            path: ['scheduling', 'request', this.selectedType.type],
            value: {
              disable: [
                {
                  op: '<',
                  type: 'lead_time',
                  unit: 'hours',
                  value: this.selectedType.cutOffTime
                },
                {
                  op: 'in',
                  type: 'schedule_state',
                  value: this.selectedType.disabledStates
                }
              ],
              states: [
                {
                  for_review: forReview,
                  initial: {
                    other: REQUEST_STATES.PENDING_CONFIRMATION
                  },
                  type: 'fallback'
                }
              ]
            }
          }
        ];
        this.dispatch('org/updateHospitalSettings', settings).then(() => {
          showStatus({
            text: this.$t('descriptions.requestSettingsUpdateSuccess'),
            type: 'success'
          });
          this.closeEditDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.requestSettingsUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateSpecificReviewers () {
      const reviewers = this.selectedType.reviewers.specific;
      const disabledStates = this.selectedType.disabledStates;
      for (let i = 0, count = reviewers.length; i < count; i++) {
        if (disabledStates.includes(reviewers[i].state)) {
          reviewers[i].state = null;
        }
      }
    }
  }
};
</script>

<style lang="scss">
#shiftRequest {
  thead th span {
    font-size: 12px !important;
  }
}
</style>
