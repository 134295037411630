<template>
  <v-container
    class="px-10 admin-users"
    fluid
    :style="containerStyle"
  >
    <v-card elevation="1">
      <v-tabs
        v-model="state"
        align-with-title
        slider-color="accent"
      >
        <v-tab
          v-for="stateItem in states"
          :key="stateItem.id"
          class="ml-0"
          :href="`#${stateItem.id}`"
        >
          <template v-if="stateItem.id === pendingState">
            <v-badge
              class="tab-error-badge"
              color="error"
              :content="pendingProfileCount"
              inline
              overlap
              tile
              :value="pendingProfileCount > 0"
            >
              {{ stateItem.label }}
            </v-badge>
          </template>
          <template v-else>
            {{ stateItem.label }}
          </template>
        </v-tab>
      </v-tabs>
      <v-container
        class="pa-0 mx-0 actions"
        fluid
      >
        <v-row
          align="center"
          dense
          :no-gutters="!$vuetify.breakpoint.smAndDown"
          :style="[$vuetify.breakpoint.smAndDown ? {'text-align': 'center'} : {}]"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <StaffSearch
              v-model.trim="filters.fullName"
              :append-icon="filters.fullName ? '' : 'fal fa-search'"
              target-class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
              :clearable="!!filters.fullName"
              dense
              hide-details
              nudge-bottom="0"
              solo
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            md="8"
          >
            <div
              :class="[$vuetify.breakpoint.smAndDown ? '' : 'float-right']"
            >
              <v-tooltip
                max-width="300px"
                bottom
                nudge-left="140"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="mr-6"
                    color="info"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    fal fa-question-circle
                  </v-icon>
                </template>
                <span class="body-2">
                  {{ $t('descriptions.addUserEmail') }}
                </span>
              </v-tooltip>
              <v-btn
                class="mr-4"
                color="secondary"
                @click="showSearchUserDialog = true"
              >
                {{ $t('labels.addUser') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        v-resize.quiet="onWindowResized"
        dense
        fixed-header
        :headers="headerDefinitions"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedUsers"
        :items-per-page="pagination.itemsPerPage"
        mobile-breakpoint=""
        must-sort
        single-select
        :sort-by="['fullName']"
        :sort-desc="[false]"
      >
        <template #header.departmentId="{ header }">
          <MultiSelectionList
            v-if="header.filterable"
            auto-apply
            :selection="header.filterOptions"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
          <span
            v-else
            class="grey--text text--darken-3"
          >
            {{ header.text }}
          </span>
        </template>
        <template #header.jobTypeId="{ header }">
          <MultiSelectionList
            v-if="header.filterable"
            auto-apply
            :selection="header.filterOptions"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
          <span
            v-else
            class="grey--text text--darken-3"
          >
            {{ header.text }}
          </span>
        </template>
        <template #header.jobStatusId="{ header }">
          <MultiSelectionList
            v-if="header.filterable"
            auto-apply
            :selection="header.filterOptions"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
          <span
            v-else
            class="grey--text text--darken-3"
          >
            {{ header.text }}
          </span>
        </template>
        <template #header.shiftTypeId="{ header }">
          <MultiSelectionList
            v-if="header.filterable"
            auto-apply
            :selection="header.filterOptions"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
          <span
            v-else
            class="grey--text text--darken-3"
          >
            {{ header.text }}
          </span>
        </template>
        <template #header.phone="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #header.employeeID="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #item="{ item, headers: tableHeaders }">
          <tr
            class=""
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <v-list-item
                v-if="header.value === 'fullName'"
                class="px-0"
              >
                <v-list-item-avatar class="mr-1">
                  <v-avatar
                    :color="$store.state.org.employees[item.userId].avatarBgColor"
                    size="30"
                  >
                    <span class="white--text subtitle-2">
                      {{ getAvatar($store.state.org.employees[item.userId]) }}
                    </span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 mb-0 name-n-avatar"
                    :title="item.fullName"
                  >
                    <span
                      class="d-inline-block text-truncate pt-1"
                      :style="{ width: `${header.width - 40}px` }"
                    >
                      <UserName
                        :internal-control="false"
                        :user="item"
                        @click="openUserDialog(item, false)"
                      />
                      <v-tooltip
                        v-if="showPendingTooltip(item)"
                        max-width="300px"
                        right
                      >
                        <template #activator="{ on, attrs }">
                          <v-icon
                            color="info"
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            fal fa-clock
                          </v-icon>
                        </template>
                        <span class="body-2">
                          {{ $t('labels.activationScheduled') }}
                        </span>
                      </v-tooltip>
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span
                      class="caption d-inline-block text-truncate"
                      :style="{ width: `${header.width - 40}px` }"
                    >
                      {{ item.email }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <span
                v-else-if="header.value === 'departmentId'"
                class="body-2 text-truncate d-inline-block"
                :style="{ width: `${header.width}px` }"
              >
                {{ getDepartmentName(item) }}
              </span>
              <span
                v-else-if="header.value === 'jobTypeId'"
                class="body-2 text-truncate d-inline-block"
                :style="{ width: `${header.width}px` }"
              >
                {{ getJobTypeName(item) }}
              </span>
              <span
                v-else-if="header.value === 'jobStatusId'"
                class="body-2 text-truncate d-inline-block"
                :style="{ width: `${header.width}px` }"
              >
                {{ getJobStatus(item) }}
              </span>
              <v-list-item
                v-else-if="header.value === 'shiftTypeId'"
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="body-2 text-truncate d-inline-block"
                    :style="{ width: `${header.width}px` }"
                  >
                    {{ getShiftTypeName(item) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="caption mt-0 text-truncate d-inline-block"
                    :style="{ width: `${header.width}px` }"
                  >
                    {{ getShiftTime(item) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <span
                v-else-if="header.value === 'phone'"
                class="body-2 text-truncate d-inline-block"
                :style="{ width: `${header.width}px` }"
              >
                <template v-if="item.phonePersonal">
                  {{ item.phonePersonal | VMask(phoneMask) }}
                </template>
              </span>
              <span
                v-else-if="header.value === 'employeeID'"
                class="body-2 text-truncate d-inline-block"
                :style="{ width: `${header.width}px` }"
              >
                {{ item.employeeId }}
              </span>
            </td>
            <v-speed-dial
              absolute
              class="mt-7 user-actions"
              :value="hoveredItem && hoveredItem.id === item.id"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="action"
                fab
                height="30"
                width="30"
                :title="$t('labels.editUser')"
                @click="openUserDialog(item, false)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="pt-4 pb-4 user-footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <SearchUserDialog
      v-if="showSearchUserDialog"
      @close="showSearchUserDialog = false"
      @done="processSearchResults"
    />
    <template v-if="selectedUser">
      <NewUserDialog
        v-if="newSelectedUser"
        :show-hint="showUserDialogHint"
        :user="selectedUser"
        @close="closeUserDialog"
        @saved="updateUser"
      />
      <UserDialog
        v-else
        :show-hint="showUserDialogHint"
        :user="selectedUser"
        @close="closeUserDialog"
        @saved="updateUser"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import MultiSelectionList from '@/components/MultiSelectionList';
import SearchUserDialog from '@/views/admin/users/SearchUserDialog';
import UserDialog from '@/views/admin/users/UserDialog';
import NewUserDialog from '@/views/admin/users/NewUserDialog';
import StaffSearch from '@/components/StaffSearch';
import UserName from '@/components/scheduling/UserName';
import { getAvatar } from '@/utils';
import { ACCOUNT_STATE } from '@/services/constants';
import { hasDepartmentAccess, hasJobTypeAccess, userMatchesText } from '@/utils/org';

export default {
  components: {
    MultiSelectionList,
    NewUserDialog,
    SearchUserDialog,
    StaffSearch,
    UserDialog,
    UserName
  },
  props: {
    classification: {
      type: String,
      required: true
    },
    headers: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      filters: {
        departmentId: [],
        employeeID: '',
        fullName: '',
        jobTypeId: [],
        jobStatusId: [],
        shiftTypeId: []
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: null,
        maxPaginationControls: 7
      },
      pendingState: ACCOUNT_STATE.pending,
      phoneMask: '+# (###) ###-####',
      selectedUser: null,
      newSelectedUser: false,
      showSearchUserDialog: false,
      showUserDialogHint: true,
      state: ACCOUNT_STATE.active
    };
  },
  computed: {
    containerStyle () {
      const containerPadding = 68;
      const cellPadding = 32; // Each cell has 16px left and right padding
      let width = 0;
      for (let i = 0, count = this.headerDefinitions.length; i < count; i++) {
        width += this.headerDefinitions[i].width + cellPadding;
      }
      return {
        width: `${width + containerPadding}px`
      };
    },
    departments () {
      return this.$store.state.org.departments.reduce((department, value) => {
        department[value.id] = value;
        return department;
      }, {});
    },
    filteredUsers () {
      let users = _.sortBy(this.users, [(u) => { return u.fullName; }]);
      const filters = [
        (u) => u.state === this.state
      ];
      for (let header in this.filters) {
        if (_.isArray(this.filters[header])) {
          if (this.filters[header].length > 0) {
            filters.push(((field, value) => {
              return (u) => value.includes(u[field]);
            })(header, this.filters[header]));
          }
        } else {
          if (this.filters[header]) {
            if (header === 'fullName') {
              filters.push(((field, value) => {
                return (u) => userMatchesText(u, value);
              })(header, this.filters[header]));
            } else {
              filters.push(((field, value) => {
                return (u) => u[field].toLowerCase().indexOf(value) >= 0;
              })(header, this.filters[header].toLowerCase()));
            }
          }
        }
      }
      if (filters.length > 0) {
        users = _.filter(users, (u) => filters.reduce((matches, filter) => {
          matches &= filter(u);
          return matches;
        }, true));
      }

      return users;
    },
    headerDefinitions () {
      const headerDefinitions = [
        {
          filterable: false,
          filterOptions: [],
          sortable: true,
          text: this.$t('labels.name'),
          value: 'fullName',
          width: 220
        },
        {
          filterable: true,
          filterOptions: _.filter(
            this.$store.state.org.departments,
            (d) => hasDepartmentAccess(this.$store.state.account.profile, d.id)).map((d) => { return { label: d.name, value: d.id }; }
          ),
          sortable: false,
          text: this.$tc('labels.primaryDepartment', 1),
          value: 'departmentId',
          width: 155
        },
        {
          filterable: true,
          filterOptions: _.filter(
            this.$store.state.org.jobTypes,
            (jt) => hasJobTypeAccess(this.$store.state.account.profile, jt)
          ).map((j) => { return { label: j.name, value: j.id }; }),
          sortable: false,
          text: this.$tc('labels.jobType', 1),
          value: 'jobTypeId',
          width: 140
        },
        {
          filterable: true,
          filterOptions: this.$store.state.org.jobStatus.map((s) => { return { label: s.shortCode, value: s.id }; }),
          sortable: false,
          text: this.$t('labels.jobStatus'),
          value: 'jobStatusId',
          width: 140
        },
        {
          filterable: true,
          filterOptions: this.$store.state.org.shiftTypes.map((s) => { return { label: `${s.name} (${this.getTimeText(s)})`, value: s.id }; }),
          sortable: false,
          text: this.$t('labels.shift'),
          value: 'shiftTypeId',
          width: 140
        },
        {
          filterable: false,
          filterOptions: [],
          sortable: false,
          text: this.$t('labels.phone'),
          value: 'phone',
          width: 140
        },
        {
          filterable: false,
          filterOptions: [],
          sortable: false,
          text: this.$t('labels.employeeID'),
          value: 'employeeID',
          width: 140
        }
      ];
      const headers = [];
      for (let i = 0, count = headerDefinitions.length; i < count; i++) {
        if (headerDefinitions[i].value in this.headers) {
          headers.push({
            ...headerDefinitions[i],
            ...this.headers[headerDefinitions[i].value]
          });
        }
      }
      return headers;
    },
    jobTypes () {
      return this.$store.state.org.jobTypes.reduce((jobType, value) => {
        jobType[value.id] = value;
        return jobType;
      }, {});
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredUsers.length / this.pagination.itemsPerPage);
    },
    paginatedUsers () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredUsers.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredUsers;
    },
    pendingProfileCount () {
      return this.$store.getters['org/getEmployeesByStateAndClassification'](ACCOUNT_STATE.pending, this.classification).length;
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce((shiftType, value) => {
        shiftType[value.id] = value;
        return shiftType;
      }, {});
    },
    states () {
      const states = [];
      for (let state in ACCOUNT_STATE) {
        states.push({
          id: ACCOUNT_STATE[state],
          label: this.$t(`labels.${state}`)
        });
      }
      return states;
    }
  },
  watch: {
    filters: {
      handler: function () {
        this.pagination.currentPage = 1;
      },
      deep: true
    },
    state () {
      this.pagination.currentPage = 1;
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeUserDialog () {
      this.selectedUser = null;
      this.newSelectedUser = false;
    },
    getAvatar,
    getDepartmentName (item) {
      return this.departments[item.departmentId].name;
    },
    getJobStatus (item) {
      const jobStatus = this.$store.getters['org/getJobStatusById'](item.jobStatusId);
      if (!jobStatus) {
        return '';
      }
      return jobStatus.shortCode;
    },
    getJobTypeName (item) {
      return this.jobTypes[item.jobTypeId].name;
    },
    getShiftTime (item) {
      const shiftType = this.$store.getters['org/getShiftTypeById'](item.shiftTypeId);
      if (!shiftType) {
        return '';
      }
      return this.getTimeText(shiftType);
    },
    getShiftTypeName (item) {
      return this.shiftTypes[item.shiftTypeId].name;
    },
    getTimeText (data) {
      return [
        _.split(data.startTime, ':', 2).join(':'),
        _.split(data.endTime, ':', 2).join(':')
      ].join(' - ');
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openUserDialog (user, showHint) {
      this.showUserDialogHint = showHint;
      this.newSelectedUser = !user.userId || !user.id || user.state === ACCOUNT_STATE.pending;
      this.selectedUser = user;
    },
    processSearchResults ({ email, user }) {
      if (user) {
        this.openUserDialog(user, true);
      } else {
        this.openUserDialog(this.$store.getters['account/getNewUser'](email), true);
      }
    },
    setFilter (header, filters) {
      this.filters[header] = filters;
    },
    showPendingTooltip (item) {
      if (this.state !== ACCOUNT_STATE.pending) {
        return false;
      }

      return !!_.get(item, 'settings.state.nextChangeEffectiveOn', null);
    },
    updateUser (user) {
      this.$store.commit('org/update_employee', user);
      this.selectedUser = this.$store.state.org.employees[user.userId];
      if (this.newSelectedUser) {
        this.closeUserDialog();
      }
    }
  }
};
</script>

<style lang="scss">
.admin-users {
  margin-left: 0px;

  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .name-filter {
    width: 200px;
  }
  .tab-error-badge {
    .v-badge__badge {
      border-radius: 4px !important;
      font-size: 10px;
      height: 16px;
      min-width: 16px;
      padding: 4px 2px !important;
    }
  }
  thead {
    th span {
      font-size: 12px !important;
    }
  }
  .user-actions {
    right: 0px !important;
  }
  .user-footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .v-list-item__content {
    padding: 0px;
  }
  .v-tabs {
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
}
</style>
