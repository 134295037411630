<template>
  <v-container
    id="scheduleValidations"
    class="px-0"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $t('labels.schedule'), disabled: false }, { text: $tc('labels.validation', 2), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      class="mx-10 my-3 validations"
      elevation="4"
    >
      <ValidationObserver v-slot="{ invalid, passes }">
        <v-container
          class="pa-4"
          fluid
        >
          <v-row dense>
            <v-col>
              <span class="body-2 font-weight-medium text--grey text--darken-3">
                {{ $t('labels.excessiveConsecutiveShifts') }}
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="py-0">
              <span class="caption grey--text text--darken-1">
                {{ $t('descriptions.excessiveConsecutiveShifts') }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="consecutiveShiftsValidationData.length > 0"
            class="my-2"
            dense
          >
            <v-col
              class="job-types pr-4"
              cols="4"
            >
              <v-row
                v-for="(validation, validationIdx) in consecutiveShiftsValidationData"
                :key="validationIdx"
              >
                <v-col>
                  <VeeSelect
                    v-model="validation.jobTypes"
                    class="job-type-selection"
                    dense
                    hide-details
                    item
                    item-text="label"
                    item-value="value"
                    :items="getAvailableJobTypes(validationIdx)"
                    :label="$tc('labels.jobType', 2)"
                    multiple
                    outlined
                    rules="required"
                    :vid="`jobTypes${validationIdx}`"
                    @change="updateShiftTypes(validationIdx)"
                  >
                    <template #selection="{ item, index }">
                      <span class="mt-n1">
                        <span class="body-2">{{ item.label }}</span>
                        {{ index !== validation.jobTypes.length - 1 ? ', ' : '' }}
                      </span>
                    </template>
                  </VeeSelect>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="shift-types grey lighten-4"
              cols="7"
            >
              <v-container
                class="pa-0"
                fluid
              >
                <v-row
                  v-for="(validation, validationIdx) in consecutiveShiftsValidationData"
                  :key="validationIdx"
                >
                  <v-col>
                    <div
                      v-for="(shiftType, shiftIdx) in getShiftTypes(validation)"
                      :key="shiftIdx"
                      class="d-inline-block shift-count-container"
                    >
                      <span class="body-2 grey--text text--darken-3 mx-4 text-truncate shift-name">
                        {{ shiftType.name }}
                      </span>
                      <VeeTextField
                        v-model.number="validation.shiftTypes[shiftType.id]"
                        class="shift-count d-inline-block"
                        dense
                        hide-details
                        :label="$t('labels.max')"
                        name="shiftCount"
                        outlined
                        rules="required|numeric|min_value:0"
                        style="width: 80px"
                        :vid="`shiftCount${shiftIdx}-${validationIdx}`"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              cols="1"
            >
              <v-row
                v-for="(validation, validationIdx) in consecutiveShiftsValidationData"
                :key="validationIdx"
                :style="{ height: '64px' }"
              >
                <v-col>
                  <v-btn
                    class="ml-2 remove-consecutive-validation"
                    icon
                    @click="removeConsecutiveShiftValidation(validationIdx)"
                  >
                    <v-icon
                      color="error"
                      small
                    >
                      fal fa-trash-alt
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <a
                class="caption add-consecutive-validation secondary--text"
                @click="addConsecutiveShiftValidation"
              >
                + {{ $t('labels.addValidationRule') }}
              </a>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row
          class="pa-4"
          dense
        >
          <v-spacer />
          <v-col
            class="text-right"
            cols="6"
          >
            <v-btn
              class="ml-3 save-validations"
              color="secondary"
              :disabled="invalid || saving"
              @click="passes(save)"
            >
              <v-progress-circular
                v-if="saving"
                color="secondary"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.save') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import { showStatus } from '@/plugins/vue-notification';
import { snakeCasePropertyNames } from '@/utils';

export default {
  components: {
    VeeSelect,
    VeeTextField
  },
  data () {
    const consecutiveShiftsValidationData = _.cloneDeep(_.get(this.$store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []));
    for (let i = 0, count = consecutiveShiftsValidationData.length; i < count; i++) {
      // Need to turn the max consecutive count to a string because when the value is 0 (numeric), vuejs treats that as falsy
      // and text fields are not pre-populated.
      for (let id in consecutiveShiftsValidationData[i].shiftTypes) {
        consecutiveShiftsValidationData[i].shiftTypes[id] = String(consecutiveShiftsValidationData[i].shiftTypes[id]);
      }
    }
    return {
      saving: false,
      consecutiveShiftsValidationData
    };
  },
  computed: {
    jobTypes () {
      const jobTypes = _.sortBy(this.$store.state.org.jobTypes, ['name']);
      return jobTypes.map((jt) => {
        return {
          label: jt.name,
          value: jt.id,
          shiftTypes: jt.settings.associatedShiftTypes
        };
      });
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    }
  },
  methods: {
    addConsecutiveShiftValidation () {
      this.consecutiveShiftsValidationData.push({
        jobTypes: [],
        shiftTypes: {}
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getAvailableJobTypes (validationIdx) {
      const jobTypeIdsInUse = _.uniq(this.consecutiveShiftsValidationData.reduce(
        (arr, currentValue, currentIndex) => {
          if (validationIdx !== currentIndex) {
            arr.push(...currentValue['jobTypes']);
          }
          return arr;
        }, // eslint-disable-line no-return-assign, no-sequences
        []
      ));
      return _.filter(this.jobTypes, (jobType) => {
        return !jobTypeIdsInUse.includes(jobType.value);
      });
    },
    getShiftTypes (validation) {
      const shiftTypeIds = _.keys(validation.shiftTypes).map(id => parseInt(id));
      const shiftTypes = [];
      for (let i = 0, count = shiftTypeIds.length; i < count; i++) {
        shiftTypes.push(this.shiftTypesById[shiftTypeIds[i]]);
      }
      return shiftTypes;
    },
    removeConsecutiveShiftValidation (idx) {
      this.consecutiveShiftsValidationData.splice(idx, 1);
    },
    save () {
      if (!this.saving) {
        this.saving = true;
        const consecutiveShiftsValidationData = _.cloneDeep(this.consecutiveShiftsValidationData);
        for (let i = 0, count = consecutiveShiftsValidationData.length; i < count; i++) {
          for (let id in consecutiveShiftsValidationData[i].shiftTypes) {
            consecutiveShiftsValidationData[i].shiftTypes[id] = parseInt(consecutiveShiftsValidationData[i].shiftTypes[id]);
          }
        }
        const value = {
          data: consecutiveShiftsValidationData
        };
        snakeCasePropertyNames(value);
        const settings = [
          {
            path: ['validations', 'consecutive_shifts'],
            value
          }
        ];
        this.dispatch('org/updateHospitalSettings', settings).then(() => {
          showStatus({
            text: this.$t('descriptions.scheduleSettingsUpdateSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.scheduleSettingsUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateShiftTypes (validationIndex) {
      const validation = this.consecutiveShiftsValidationData[validationIndex];
      const jobTypes = validation.jobTypes.map(id => this.jobTypesById[id]);
      const shiftTypes = validation.shiftTypes;
      const newShiftTypes = {};
      for (let i = 0, count = jobTypes.length; i < count; i++) {
        const associatedShiftTypes = jobTypes[i].settings.associatedShiftTypes;
        for (let shiftIdx = 0, shiftCount = associatedShiftTypes.length; shiftIdx < shiftCount; shiftIdx++) {
          newShiftTypes[associatedShiftTypes[shiftIdx]] = 0;
        }
      }
      const ids = _.intersection(_.keys(newShiftTypes), _.keys(shiftTypes));
      const exeistingShiftTypes = {};
      for (let i = 0, count = ids.length; i < count; i++) {
        exeistingShiftTypes[ids[i]] = shiftTypes[ids[i]];
      }
      this.consecutiveShiftsValidationData[validationIndex].shiftTypes = {
        ...newShiftTypes,
        ...exeistingShiftTypes
      };
    }
  }
};
</script>

<style lang="scss">
#scheduleValidations {
  .job-type-selection {
    width: 300px;
    .v-select__selections {
      display: inline-block;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      input {
        width: 0px;
      }
    }
  }
  .shift-count-container {
    margin-right: 16px;
    width: 172px;
  }
  .shift-name {
    display: inline-block;
    text-align: end;
    vertical-align: middle;
    width: 60px;
  }
  .shift-types {
    overflow-x: auto;
    .container {
      min-width: 400px;
      > .row {
        white-space: nowrap;
      }
    }
  }
  .validations {
    min-width: 600px;
    overflow-x: auto;
  }
}
</style>
