<template>
  <v-dialog
    v-model="show"
    content-class="delete-confirmation"
    persistent
    width="560px"
  >
    <v-card
      class="pa-0"
    >
      <v-card-title class="body-2 d-block mb-0 pb-0">
        <span class="body-2 font-weight-medium">
          {{ title }}
        </span>
        <v-btn
          class="float-right mt-n1"
          :disabled="deleting"
          icon
          small
          @click="$emit('close')"
        >
          <v-icon small>
            fal fa-times
          </v-icon>
        </v-btn>
      </v-card-title>
      <template v-if="state === 'checkingDependencies'">
        <v-card-text>
          <v-row class="text-center">
            <v-col class="text-center">
              <v-progress-circular
                color="info"
                indeterminate
                size="75"
                width="6"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span>{{ $t('descriptions.checkingDependencies') }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template v-else-if="state === 'cannotDelete'">
        <v-card-text class="delete-department-body">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="body-2 mt-3"
            v-html="dependenciesBody"
          />
        </v-card-text>
      </template>
      <template v-else-if="state === 'deleteConfirmation'">
        <v-card-text>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="body-2 mt-3"
            v-html="deleteBody"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="ma-3 px-5"
            :disabled="deleting"
            text
            @click="$emit('close')"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            class="ma-3 px-5"
            color="accent"
            :disabled="deleting"
            @click="del"
          >
            <v-progress-circular
              v-if="deleting"
              color="primary lighten-2"
              indeterminate
              size="22"
              width="2"
            />
            <span v-else>
              {{ $t('labels.delete') }}
            </span>
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text class="delete-department-body">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="body-2 mt-3"
            v-html="error"
          />
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    action: {
      type: String,
      required: true
    },
    deleteBody: {
      type: String,
      required: true
    },
    dependenciesBody: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    show: {
      default: true,
      type: Boolean
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      deleting: false,
      error: '',
      state: 'checkingDependencies'
    };
  },
  mounted () {
    this.checkDependencies();
  },
  methods: {
    checkDependencies () {
      this.state = 'checkingDependencies';
      this.dispatch(this.action, this.item.id).then((hasDependencies) => {
        if (hasDependencies) {
          this.state = 'cannotDelete';
        } else {
          this.state = 'deleteConfirmation';
        }
      }).catch((error) => {
        this.error = _.get(error, 'response.data');
        this.state = 'error';
      });
    },
    del () {
      if (!this.deleting) {
        this.deleting = true;
        this.$emit('del', this.item);
      }
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
};
</script>

<style lang="scss">
.delete-department-body {
  height: 150px;
}
</style>
