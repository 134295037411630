<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $tc('labels.department', 2), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="departments"
    >
      <v-row
        class="actions"
        no-gutters
      >
        <v-col>
          <v-text-field
            v-model.trim="filters.name"
            :append-icon="filters.name ? '' : 'fal fa-search'"
            class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
            :clearable="!!filters.name"
            dense
            hide-details
            :placeholder="$t('labels.searchByName')"
            solo
          />
          <v-btn
            class="float-right mt-2 mr-4"
            color="secondary"
            @click="openDetailsDialog()"
          >
            {{ $t('labels.addDepartment') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-resize.quiet="onWindowResized"
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedDepartments"
        :items-per-page="pagination.itemsPerPage"
        must-sort
        single-select
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
      >
        <template #header.fullName="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #header.jobTypes="{ header }">
          <MultiSelectionList
            :selection="jobTypesAvailable"
            @selectionConfirmed="setFilter(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ header.text }}
                <v-icon
                  v-if="filters[header.value].length > 0"
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #header.costCenter="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #item="{ item, headers: tableHeaders }">
          <tr
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <span
                v-if="header.value === 'name'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.name"
              >
                {{ item.name }}
              </span>
              <span
                v-if="header.value === 'fullName'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.fullName"
              >
                {{ item.fullName }}
              </span>
              <span
                v-if="header.value === 'jobTypes'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="getJobTypesDisplay(item)"
              >
                {{ getJobTypesDisplay(item) }}
              </span>
              <span
                v-if="header.value === 'costCenter'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.costCenter"
              >
                {{ item.costCenter }}
              </span>
              <template v-if="header.value === 'receiveDailyCensus'">
                <v-icon
                  v-if="receiveDailyCensusEnabled(item)"
                  color="success"
                  small
                >
                  fas fa-check-circle
                </v-icon>
                <v-icon
                  v-else
                  color="grey"
                  small
                >
                  far fa-times-circle
                </v-icon>
              </template>
              <template v-if="header.value === 'partakeInScheduling'">
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': '150px' }"
                >
                  <v-icon
                    v-if="item.partakeInScheduling"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
              <template v-if="header.value === 'setUpProgress'">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="setup-step"
                      color="success"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      fas fa-square
                    </v-icon>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.details') }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <template v-if="item.receiveDailyCensus">
                      <v-icon
                        v-if="Object.keys(item.settings.acuity).length > 0"
                        class="setup-step"
                        color="success"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        fas fa-square
                      </v-icon>
                      <v-icon
                        v-else
                        class="setup-step"
                        color="error"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        fas fa-square
                      </v-icon>
                    </template>
                    <v-icon
                      v-else
                      class="skip setup-step"
                      color="success"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      fas fa-square
                    </v-icon>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.censusCollection') }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <template v-if="item.useStaffingMatrix">
                      <v-icon
                        v-if="isStaffingMatrixComplete(item)"
                        class="setup-step"
                        color="success"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        fas fa-square
                      </v-icon>
                      <v-icon
                        v-else
                        class="setup-step"
                        color="error"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        fas fa-square
                      </v-icon>
                    </template>
                    <v-icon
                      v-else
                      class="skip setup-step"
                      color="success"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      fas fa-square
                    </v-icon>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.staffingMatrix') }}
                  </span>
                </v-tooltip>
              </template>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 department-dial"
              :value="(hoveredItem && hoveredItem.id === item.id) || (activeItem && activeItem.id === item.id)"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-menu
                offset-y
                nudge-left="170"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="edit"
                    fab
                    height="30"
                    width="30"
                    :title="$t('labels.editDepartment')"
                    v-on="getEventsForDialButton(item, on)"
                  >
                    <v-icon small>
                      fal fa-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    class="caption edit-details"
                    :title="$t('labels.details')"
                    @click.prevent="openDetailsDialog(item)"
                  >
                    <v-list-item-title>
                      {{ $t('labels.details') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="caption edit-census"
                    :disabled="!item.receiveDailyCensus"
                    :title="$t('labels.censusCollection')"
                    @click.prevent="openCensusDialog(item)"
                  >
                    <v-list-item-title>
                      {{ $t('labels.censusCollection') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="caption edit-staffing-matrix"
                    :disabled="!item.useStaffingMatrix"
                    :title="$t('labels.staffingMatrix')"
                    @click.prevent="openStaffingMatrixDialog(item)"
                  >
                    <v-list-item-title>
                      {{ $t('labels.staffingMatrix') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                class="delete"
                fab
                height="30"
                width="30"
                :title="$t('labels.deleteDepartment')"
                @click="openDeleteConfirmationDialog(item)"
              >
                <v-icon
                  color="error"
                  small
                >
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="py-4 footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showDetailsDialog"
      persistent
      :width="`${detailsDialogWidths[detailsDialogStep]}px`"
      :value="showDetailsDialog"
    >
      <v-card
        id="departmentDetails"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-0 pb-0">
          <span class="body-2 font-weight-medium">
            {{ selectedDepartment.id ? $t('labels.editDepartment') : $t('labels.addDepartment') }}
            {{ selectedDepartment.name ? ` - ${selectedDepartment.name}`: '' }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeDetailsDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0 pb-0">
          <v-form>
            <v-stepper
              v-model="detailsDialogStep"
            >
              <v-stepper-header>
                <v-stepper-step
                  color="secondary"
                  step="1"
                >
                  {{ $t('labels.details') }}
                </v-stepper-step>
                <template v-if="selectedDepartment.partakeInScheduling">
                  <v-divider class="small" />
                  <v-stepper-step
                    color="secondary"
                    step="2"
                  >
                    {{ $t('labels.jobTypeAndGroups') }}
                  </v-stepper-step>
                  <v-divider class="small" />
                  <v-stepper-step
                    color="secondary"
                    step="3"
                  >
                    {{ $t('labels.staffNeeded') }}
                  </v-stepper-step>
                </template>
                <v-spacer />
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content
                  class="px-0 pt-0"
                  step="1"
                >
                  <ValidationObserver
                    v-slot="{ invalid, passes }"
                  >
                    <v-container class="pt-0 px-6">
                      <v-row dense>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <VeeTextField
                            v-model.trim="selectedDepartment.name"
                            dense
                            :label="$t('labels.name')"
                            name="name"
                            outlined
                            :rules="{ required: true, max: 64, excluded: namesInUse }"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <VeeTextField
                            v-model.trim="selectedDepartment.costCenter"
                            dense
                            :label="$t('labels.costCenter')"
                            name="costCenter"
                            outlined
                            rules="required|max:64"
                          />
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col
                          cols="12"
                        >
                          <VeeTextField
                            v-model.trim="selectedDepartment.fullName"
                            dense
                            :label="$t('labels.fullName')"
                            name="fullName"
                            outlined
                            rules="required|max:256"
                          />
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col
                          cols="12"
                        >
                          <v-switch
                            v-model="selectedDepartment.partakeInScheduling"
                            class="pt-0 mt-1 d-inline-block"
                            color="success"
                            dense
                            hide-details
                            inset
                          >
                            <template v-slot:label>
                              <span
                                class="mr-4 body-2 grey--text text--darken-3"
                                :title="$t('labels.involveInScheduling')"
                              >
                                {{ $t('labels.involveInScheduling') }}
                              </span>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col
                          cols="12"
                        >
                          <v-switch
                            v-model="selectedDepartment.receiveDailyCensus"
                            class="pt-0 mt-1 d-inline-block"
                            color="success"
                            dense
                            hide-details
                            inset
                            @change="onReceiveDailyCensusChange"
                          >
                            <template v-slot:label>
                              <span
                                class="mr-4 body-2 grey--text text--darken-3"
                                :title="$t('labels.receiveDailyCensus')"
                              >
                                {{ $t('labels.receiveDailyCensus') }}
                              </span>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col
                          cols="12"
                        >
                          <v-switch
                            v-model="selectedDepartment.useStaffingMatrix"
                            class="pt-0 mt-1 d-inline-block use-staffing-matrix"
                            color="success"
                            dense
                            :disabled="!selectedDepartment.receiveDailyCensus"
                            hide-details
                            inset
                          >
                            <template v-slot:label>
                              <span
                                class="mr-4 body-2 grey--text text--darken-3"
                                :title="$t('labels.useStaffingMatrix')"
                              >
                                {{ $t('labels.useStaffingMatrix') }}
                              </span>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider />
                    <v-row dense>
                      <template v-if="selectedDepartment.partakeInScheduling">
                        <v-col
                          class="text-right pb-0 pt-4 px-8"
                          cols="12"
                        >
                          <v-btn
                            class="mr-4"
                            text
                            @click="closeDetailsDialog"
                          >
                            {{ $t('labels.cancel') }}
                          </v-btn>
                          <v-btn
                            class="next"
                            color="secondary"
                            :disabled="invalid"
                            @click.prevent="passes(() => goToDetailsStep(2))"
                          >
                            {{ $t('labels.next') }}
                          </v-btn>
                        </v-col>
                        <v-spacer />
                      </template>
                      <template v-else>
                        <v-col
                          class="text-right pb-0 pt-4 px-8"
                          cols="12"
                        >
                          <v-btn
                            class="mr-4"
                            :disabled="saving"
                            text
                            @click="closeDetailsDialog"
                          >
                            {{ $t('labels.cancel') }}
                          </v-btn>
                          <v-btn
                            v-if="selectedDepartment.id"
                            class="submit"
                            color="secondary"
                            :disabled="invalid || saving"
                            @click.prevent="passes(updateDetails)"
                          >
                            <v-progress-circular
                              v-if="saving"
                              color="primary lighten-2"
                              indeterminate
                              size="22"
                              width="2"
                            />
                            <span v-else>
                              {{ $t('labels.save') }}
                            </span>
                          </v-btn>
                          <v-btn
                            v-else
                            class="submit"
                            color="secondary"
                            :disabled="invalid || saving"
                            @click.prevent="passes(createDepartment)"
                          >
                            <v-progress-circular
                              v-if="saving"
                              color="primary lighten-2"
                              indeterminate
                              size="22"
                              width="2"
                            />
                            <span v-else>
                              {{ $t('labels.add') }}
                            </span>
                          </v-btn>
                        </v-col>
                        <v-spacer />
                      </template>
                    </v-row>
                  </ValidationObserver>
                </v-stepper-content>
                <v-stepper-content
                  class="px-0 pt-0"
                  step="2"
                >
                  <ValidationObserver
                    v-slot="{ invalid, passes }"
                  >
                    <v-container
                      class="pt-0 px-6"
                      fluid
                    >
                      <v-row dense>
                        <v-col cols="6">
                          <v-row
                            class="mb-1"
                            dense
                          >
                            <v-col
                              class="caption grey--text text--darken-1 pt-0"
                              cols="12"
                            >
                              {{ $t('descriptions.departmentJobTypes') }}
                              {{ $t('descriptions.departmentJobTypesGroup') }}
                              <v-tooltip
                                max-width="400px"
                                top
                              >
                                <template #activator="{ on, attrs }">
                                  <v-icon
                                    class="disclosure-hint"
                                    color="info"
                                    x-small
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    fal fa-question-circle
                                  </v-icon>
                                </template>
                                <span class="body-2">
                                  {{ $t('descriptions.departmentJobTypesExample') }}
                                </span>
                              </v-tooltip>
                              .
                              {{ $t('descriptions.departmentJobTypesDisplay') }}
                            </v-col>
                          </v-row>
                          <draggable
                            v-model="selectedDepartment.settings.staffNeeded"
                            draggable=".job-types-group"
                          >
                            <v-row
                              v-for="(staffNeeded, idx) in selectedDepartment.settings.staffNeeded"
                              :key="idx"
                              class="mb-1 job-types-group"
                              dense
                            >
                              <v-col
                                class="dnd"
                                cols="1"
                              >
                                <v-icon
                                  color="grey"
                                  small
                                >
                                  fas fa-grip-vertical
                                </v-icon>
                              </v-col>
                              <v-col
                                cols="6"
                              >
                                <VeeSelect
                                  v-model="staffNeeded.jobTypes"
                                  dense
                                  hide-details
                                  item-text="label"
                                  item-value="value"
                                  :items="getAvailableJobTypes(idx)"
                                  :label="$t('labels.jobTypeGroup')"
                                  :menu-props="{ top: false, offsetY: true }"
                                  multiple
                                  outlined
                                  rules="required"
                                  small-chips
                                  :vid="`jobTypes${idx}`"
                                />
                              </v-col>
                              <v-col cols="4">
                                <v-btn
                                  v-if="staffNeeded.display.dailySchedule.groupRight"
                                  class="daily-schedule-display"
                                  color="nb-azure"
                                  :disabled="idx === selectedDepartment.settings.staffNeeded.length - 1"
                                  elevation="0"
                                  small
                                  @click="staffNeeded.display.dailySchedule.groupRight = false"
                                >
                                  <v-icon
                                    class="mr-2"
                                    color="secondary"
                                    small
                                  >
                                    fal fa-check
                                  </v-icon>
                                  <span class="secondary--text">
                                    {{ $t('labels.combinedJobTypes') }}
                                  </span>
                                </v-btn>
                                <v-btn
                                  v-else
                                  class="daily-schedule-display"
                                  :disabled="idx === selectedDepartment.settings.staffNeeded.length - 1"
                                  elevation="0"
                                  small
                                  @click="staffNeeded.display.dailySchedule.groupRight = true"
                                >
                                  <v-icon
                                    class="mr-2"
                                    small
                                  >
                                    fal fa-level-down
                                  </v-icon>
                                  <span
                                    :class="idx === selectedDepartment.settings.staffNeeded.length - 1 ? '' : 'grey--text text--darken-3'"
                                  >
                                    {{ $t('labels.combineJobTypes') }}
                                  </span>
                                </v-btn>
                              </v-col>
                              <v-col cols="1">
                                <v-btn
                                  color="secondary"
                                  icon
                                  @click="removeJobType(idx)"
                                >
                                  <v-icon
                                    color="error"
                                    small
                                  >
                                    fal fa-trash-alt
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </draggable>
                          <v-row
                            class="mb-4"
                            dense
                          >
                            <v-col
                              cols="12"
                            >
                              <a
                                class="caption secondary--text add"
                                @click="addJobTypeGroup"
                              >
                                + {{ $t('labels.addJobType') }}
                              </a>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6">
                          <v-row>
                            <v-col
                              cols="12"
                            >
                              <v-card
                                class="lighten-4 pa-4"
                                color="grey"
                                flat
                              >
                                <div class="body-2 grey--text text--darken-2">
                                  {{ $t('labels.previewScheduleJobTypes') }}
                                </div>
                                <div class="caption grey--text text--darken-1 my-3">
                                  {{ $t('descriptions.previewScheduleJobTypes') }}
                                </div>
                                <v-tabs
                                  v-if="jobTypesCombinedForSelectedDepartment.length > 0"
                                  ref="dailyTabs"
                                  class="job-types"
                                  show-arrows
                                  slider-color="accent"
                                  slider-size="3"
                                >
                                  <v-tab
                                    v-for="(jobTypeGroup, index) in jobTypesCombinedForSelectedDepartment"
                                    :key="index"
                                  >
                                    <template
                                      v-for="(jobType, jobIndex) in jobTypeGroup"
                                    >
                                      <span
                                        v-if="jobIndex > 0"
                                        :key="`${jobType.id}-plus`"
                                        class="ml-2"
                                      >
                                        +
                                      </span>
                                      <span
                                        :key="`${jobType.id}-name`"
                                        :class="jobIndex > 0 ? 'ml-2' : ''"
                                      >
                                        {{ jobType.name }}
                                      </span>
                                    </template>
                                  </v-tab>
                                </v-tabs>
                                <v-container
                                  v-else
                                  class="preview"
                                >
                                  {{ $t('labels.previewEmpty') }}
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider />
                    <v-row dense>
                      <v-col
                        class="text-left pb-0 pt-4 px-8"
                        cols="6"
                      >
                        <v-btn
                          class="mr-4"
                          :disabled="invalid || saving"
                          text
                          @click="goToDetailsStep(1)"
                        >
                          {{ $t('labels.back') }}
                        </v-btn>
                      </v-col>
                      <v-col
                        class="text-right pb-0 pt-4 px-8"
                        cols="6"
                      >
                        <v-btn
                          class="mr-4"
                          text
                          @click="closeDetailsDialog"
                        >
                          {{ $t('labels.cancel') }}
                        </v-btn>
                        <v-btn
                          class="next"
                          color="secondary"
                          :disabled="invalid || invalidJobTypes"
                          @click.prevent="passes(() => goToDetailsStep(3))"
                        >
                          {{ $t('labels.next') }}
                        </v-btn>
                      </v-col>
                      <v-spacer />
                    </v-row>
                  </ValidationObserver>
                </v-stepper-content>
                <v-stepper-content
                  class="px-0 pt-0"
                  step="3"
                >
                  <ValidationObserver
                    v-slot="{ invalid, passes }"
                  >
                    <v-container
                      class="pt-0 px-6"
                      fluid
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <div
                            v-if="selfScheduleBidForOpening"
                            class="grey--text text-darken-1 caption mb-4"
                          >
                            {{ $t('descriptions.bidsAllowed') }}
                          </div>
                          <v-card
                            class="staff-needed-container"
                            outlined
                          >
                            <v-tabs
                              class="staff-needed-count"
                              hide-slider
                              vertical
                            >
                              <template
                                v-for="(staffNeeded, idx) in selectedDepartment.settings.staffNeeded"
                              >
                                <v-tab
                                  :key="`label${idx}`"
                                  :href="`#jobType${idx}`"
                                >
                                  {{ getJobTypeName(staffNeeded) }}
                                </v-tab>
                                <v-tab-item
                                  :key="`content${idx}`"
                                  :value="`jobType${idx}`"
                                  eager
                                >
                                  <v-container class="shift-types-needed">
                                    <v-row dense>
                                      <v-col
                                        :cols="selfScheduleBidForOpening ? '6' : '12'"
                                      >
                                        {{ $t('labels.staffNeeded') }}
                                      </v-col>
                                      <v-col
                                        v-if="selfScheduleBidForOpening"
                                        cols="6"
                                      >
                                        {{ $t('labels.bidsAllowed') }}
                                      </v-col>
                                    </v-row>
                                    <v-row
                                      v-for="shiftType in getShiftTypes(staffNeeded)"
                                      :key="shiftType.id"
                                    >
                                      <v-col
                                        :cols="selfScheduleBidForOpening ? '6' : '12'"
                                      >
                                        <VeeTextField
                                          v-model.number="staffNeeded.shiftTypes[shiftType.id]"
                                          class="d-inline-block count"
                                          dense
                                          hide-details
                                          :label="shiftType.name"
                                          outlined
                                          :rules="{ required: true, numeric: true, min_value: 0 }"
                                          style="width: 100px"
                                          :vid="`${idx}.${shiftType.id}`"
                                          @input="updateMaxStaffNeeded(idx, shiftType.id)"
                                        />
                                      </v-col>
                                      <v-col
                                        v-if="selfScheduleBidForOpening"
                                        cols="6"
                                      >
                                        <VeeTextField
                                          v-model.number="staffNeeded.maxShiftTypes[shiftType.id]"
                                          class="d-inline-block count"
                                          dense
                                          hide-details
                                          :label="shiftType.name"
                                          outlined
                                          :rules="{ required: true, numeric: true, min_value: staffNeeded.shiftTypes[shiftType.id] }"
                                          style="width: 100px"
                                          :vid="`${idx}.${shiftType.id}`"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-tab-item>
                              </template>
                            </v-tabs>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider />
                    <v-row dense>
                      <v-col
                        class="text-left pb-0 pt-4 px-8"
                        cols="6"
                      >
                        <v-btn
                          class="mr-4"
                          :disabled="invalid || saving"
                          text
                          @click="goToDetailsStep(2)"
                        >
                          {{ $t('labels.back') }}
                        </v-btn>
                      </v-col>
                      <v-col
                        class="text-right pb-0 pt-4 px-8"
                        cols="6"
                      >
                        <v-btn
                          class="mr-4"
                          :disabled="saving"
                          text
                          @click="closeDetailsDialog"
                        >
                          {{ $t('labels.cancel') }}
                        </v-btn>
                        <v-btn
                          v-if="selectedDepartment.id"
                          class="submit"
                          color="secondary"
                          :disabled="invalid || saving"
                          @click.prevent="passes(updateDetails)"
                        >
                          <v-progress-circular
                            v-if="saving"
                            color="primary lighten-2"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          <span v-else>
                            {{ $t('labels.save') }}
                          </span>
                        </v-btn>
                        <v-btn
                          v-else
                          class="submit"
                          color="secondary"
                          :disabled="invalid || saving"
                          @click.prevent="passes(createDepartment)"
                        >
                          <v-progress-circular
                            v-if="saving"
                            color="primary lighten-2"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          <span v-else>
                            {{ $t('labels.add') }}
                          </span>
                        </v-btn>
                      </v-col>
                      <v-spacer />
                    </v-row>
                  </ValidationObserver>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showCensusDialog"
      persistent
      :width="`${censusDialogWidths[censusDialogStep]}px`"
      :value="showCensusDialog"
    >
      <v-card
        id="departmentCensus"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-0 pb-0">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.editDepartmentCensus') }}
            {{ ` - ${selectedDepartment.name}` }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeCensusDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0 pb-0">
          <v-form>
            <v-stepper
              v-model="censusDialogStep"
            >
              <v-stepper-header>
                <v-stepper-step
                  color="secondary"
                  step="1"
                >
                  {{ $t('labels.acuity') }}
                </v-stepper-step>
                <v-divider class="small" />
                <v-stepper-step
                  color="secondary"
                  step="2"
                >
                  {{ $t('labels.specialty') }}
                </v-stepper-step>
                <v-spacer />
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content
                  class="px-0 pt-0"
                  step="1"
                >
                  <ValidationObserver
                    v-slot="{ invalid, passes }"
                  >
                    <v-container class="pt-0 px-6">
                      <v-row>
                        <v-col cols="6">
                          <v-row dense>
                            <v-col
                              class="py-0"
                              cols="12"
                            >
                              <v-select
                                class="subtitle-2 acuity-select"
                                dense
                                :items="[{ text: '1', value: 1 }, { text: '2', value: 2 }, { text: '3', value: 3 }, { text: '4', value: 4 }, { text: '5', value: 5 }]"
                                :label="$t('labels.acuity')"
                                outlined
                                :value="selectedDepartment.settings.acuity.length"
                                @change="updateAcuityCount"
                              />
                            </v-col>
                          </v-row>
                          <v-row
                            v-for="acuityClass in selectedDepartment.settings.acuity"
                            :key="acuityClass.acuity"
                            dense
                          >
                            <v-col
                              class="py-0"
                              cols="12"
                            >
                              <VeeTextField
                                v-model.trim="acuityClass.value.label"
                                dense
                                :label="$t('labels.acuityClassification', { acuity: acuityClass.acuity })"
                                :name="`class${acuityClass.acuity}`"
                                outlined
                                rules="required|max:64"
                              />
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col
                              cols="12"
                            >
                              <v-switch
                                v-model="selectedDepartment.settings.showTotalAcuityByClass"
                                class="pt-0 mt-1 d-inline-block"
                                color="success"
                                dense
                                hide-details
                                inset
                              >
                                <template v-slot:label>
                                  <span
                                    class="mr-4 body-2 grey--text text--darken-3"
                                    :title="$t('labels.collectCensusAcuity')"
                                  >
                                    {{ $t('labels.collectCensusAcuity') }}
                                  </span>
                                </template>
                              </v-switch>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6">
                          <v-card
                            class="lighten-4 pa-4 acuity"
                            color="grey"
                            flat
                          >
                            <div class="body-2 grey--text text--darken-2 mb-4">
                              {{ $t('labels.preview') }}
                            </div>
                            <template v-if="selectedDepartment.settings.showTotalAcuityByClass">
                              <v-row
                                class="mb-1"
                                no-gutters
                              >
                                <span class="caption row-label" />

                                <span
                                  v-for="(acuity, key) in selectedDepartment.settings.acuity"
                                  :key="key"
                                  class="caption header text-truncate"
                                >
                                  {{ acuity.value.label }}
                                </span>
                              </v-row>
                              <v-row
                                class="mb-2"
                                no-gutters
                              >
                                <span class="caption row-label">
                                  {{ $t('labels.acuity') }}
                                </span>
                                <v-text-field
                                  v-for="(acuity, key) in selectedDepartment.settings.acuity"
                                  :key="key"
                                  class="acuity-input"
                                  dense
                                  disabled
                                  hide-details
                                  outlined
                                />
                              </v-row>
                              <v-row
                                class="mb-3"
                                no-gutters
                              >
                                <span class="caption row-label">
                                  {{ $t('labels.total') }}
                                </span>
                                <v-text-field
                                  class="acuity-input"
                                  dense
                                  disabled
                                  hide-details
                                  outlined
                                />
                              </v-row>
                            </template>
                            <span
                              v-else
                              class="grey--text text--darken-2 caption"
                            >
                              {{ $t('descriptions.collectCensusAcuityOff') }}
                            </span>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider />
                    <v-row dense>
                      <v-col
                        class="text-right pb-0 pt-4 px-8"
                        cols="12"
                      >
                        <v-btn
                          class="mr-4"
                          text
                          @click="closeCensusDialog"
                        >
                          {{ $t('labels.cancel') }}
                        </v-btn>
                        <v-btn
                          class="next"
                          color="secondary"
                          :disabled="invalid"
                          @click.prevent="passes(() => goToCensusStep(2))"
                        >
                          {{ $t('labels.next') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </ValidationObserver>
                </v-stepper-content>
                <v-stepper-content
                  class="px-0 pt-0"
                  step="2"
                >
                  <ValidationObserver
                    v-slot="{ invalid, passes }"
                  >
                    <v-container
                      class="pt-0 px-6 specialty"
                      fluid
                    >
                      <v-row dense>
                        <v-col cols="7">
                          <v-card
                            class="pa-0"
                            outlined
                          >
                            <v-row dense>
                              <v-col
                                class="pa-4 list"
                                cols="6"
                              >
                                <div>
                                  <span class="ml-4">
                                    {{ $t('labels.list') }}
                                  </span>
                                  <a
                                    v-if="selectedDepartment.settings.censusBySpecialty.length < 50"
                                    class="caption secondary--text add-specialty float-right"
                                    @click="addSpecialty"
                                  >
                                    + {{ $t('labels.specialty') }}
                                  </a>
                                </div>
                                <v-list dense>
                                  <v-list-item
                                    v-for="(specialty, idx) in selectedDepartment.settings.censusBySpecialty"
                                    :key="idx"
                                    :class="['pl-3 pr-1', selectedSpecialtyIdx === idx ? 'active' : '']"
                                    dense
                                    @click="selectedSpecialtyIdx = idx"
                                  >
                                    <v-list-item-content>
                                      <span
                                        class="grey--text text--darken-3 body-2 d-inline-block text-truncate"
                                        style="width: 100px;"
                                      >
                                        {{ specialty.name }}
                                      </span>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn
                                        icon
                                        @click="removeSpecialty(idx)"
                                      >
                                        <v-icon
                                          color="error"
                                          small
                                        >
                                          fal fa-trash-alt
                                        </v-icon>
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                              <v-col
                                class="pa-4"
                                cols="6"
                              >
                                <template v-if="selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx]">
                                  <VeeTextField
                                    v-model.lazy="selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].name"
                                    class="specialty-name"
                                    dense
                                    hide-details
                                    :label="$t('labels.specialty')"
                                    :name="`specialty${selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].name}`"
                                    outlined
                                    rules="required|max:64"
                                  />
                                  <v-switch
                                    v-model="selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].acuityBreakdown"
                                    class="pt-0 mt-5 d-inline-block"
                                    color="success"
                                    dense
                                    hide-details
                                    inset
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="mr-4 body-2 grey--text text--darken-3"
                                        :title="$t('labels.breakdownByAcuity')"
                                      >
                                        {{ $t('labels.breakdownByAcuity') }}
                                      </span>
                                    </template>
                                  </v-switch>
                                  <v-switch
                                    v-model="selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].showAcuitySubtotal"
                                    class="pt-0 mt-1 d-inline-block"
                                    color="success"
                                    dense
                                    hide-details
                                    inset
                                  >
                                    <template v-slot:label>
                                      <span
                                        class="mr-4 body-2 grey--text text--darken-3"
                                        :title="$t('labels.showSubtotal')"
                                      >
                                        {{ $t('labels.showSubtotal') }}
                                      </span>
                                    </template>
                                  </v-switch>
                                </template>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                        <v-col
                          class="specialty-preview pl-4"
                          cols="5"
                        >
                          <v-card
                            class="lighten-4 pa-4 acuity"
                            color="grey"
                            flat
                          >
                            <v-row dense>
                              <v-col
                                cols="12"
                              >
                                <span class="body-2 grey--text text--darken-2">
                                  {{ $t('labels.preview') }}
                                </span>
                                <v-switch
                                  v-model="showAllSpecialties"
                                  class="mt-n1 float-right"
                                  color="success"
                                  dense
                                  hide-details
                                  inset
                                >
                                  <template v-slot:label>
                                    <span
                                      class="mr-4 body-2 grey--text text--darken-3"
                                      :title="$t('labels.showAllSpecialties')"
                                    >
                                      {{ $t('labels.showAllSpecialties') }}
                                    </span>
                                  </template>
                                </v-switch>
                                <v-container class="pa-0 mt-2">
                                  <template v-if="showAllSpecialties">
                                    <v-container
                                      v-for="(specialty, idx) in selectedDepartment.settings.censusBySpecialty"
                                      :key="specialty.name"
                                      class="pa-0"
                                    >
                                      <template v-if="specialty.acuityBreakdown">
                                        <v-row
                                          class="px-1 pt-3 pb-0"
                                          dense
                                          style="width: 100%"
                                        >
                                          <span class="caption row-label" />
                                          <span
                                            v-for="(acuity) in selectedDepartment.settings.acuity"
                                            :key="acuity.acuity"
                                            class="caption header text-truncate"
                                          >
                                            {{ acuity.value.label }}
                                          </span>
                                        </v-row>
                                        <v-row
                                          class="px-1 pt-3 pb-0"
                                          dense
                                        >
                                          <span class="caption row-label text-truncate">{{ specialty.name }}</span>
                                          <v-text-field
                                            v-for="(acuity) in selectedDepartment.settings.acuity"
                                            :key="acuity.acuity"
                                            class="acuity-input"
                                            dense
                                            disabled
                                            hide-details
                                            outlined
                                          />
                                        </v-row>
                                      </template>
                                      <v-row
                                        v-if="specialty.showAcuitySubtotal"
                                        class="px-1 pt-3 pb-0"
                                        dense
                                      >
                                        <span class="caption row-label text-truncate">
                                          {{ specialty.acuityBreakdown ? $t('labels.subtotal') : specialty.name }}
                                        </span>
                                        <v-text-field
                                          class="acuity-input"
                                          dense
                                          disabled
                                          hide-details
                                          outlined
                                        />
                                      </v-row>
                                      <v-divider
                                        v-if="idx !== selectedDepartment.settings.censusBySpecialty.length - 1"
                                        class="mt-2"
                                      />
                                    </v-container>
                                  </template>
                                  <template v-else>
                                    <v-container
                                      class="pa-0"
                                    >
                                      <template v-if="selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].acuityBreakdown">
                                        <v-row
                                          class="px-1 pt-3 pb-0"
                                          dense
                                          style="width: 100%"
                                        >
                                          <span class="caption row-label" />
                                          <span
                                            v-for="(acuity) in selectedDepartment.settings.acuity"
                                            :key="acuity.acuity"
                                            class="caption header text-truncate"
                                          >
                                            {{ acuity.value.label }}
                                          </span>
                                        </v-row>
                                        <v-row
                                          class="px-1 pt-3 pb-0"
                                          dense
                                        >
                                          <span class="caption row-label text-truncate">{{ selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].name }}</span>
                                          <v-text-field
                                            v-for="(acuity) in selectedDepartment.settings.acuity"
                                            :key="acuity.acuity"
                                            class="acuity-input"
                                            dense
                                            disabled
                                            hide-details
                                            outlined
                                          />
                                        </v-row>
                                      </template>
                                      <v-row
                                        v-if="selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].showAcuitySubtotal"
                                        class="px-1 pt-3 pb-0"
                                        dense
                                      >
                                        <span class="caption row-label text-truncate">
                                          {{ selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].acuityBreakdown ? $t('labels.subtotal') : selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].name }}
                                        </span>
                                        <v-text-field
                                          class="acuity-input"
                                          dense
                                          disabled
                                          hide-details
                                          outlined
                                        />
                                      </v-row>
                                    </v-container>
                                  </template>
                                </v-container>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider />
                    <v-row dense>
                      <v-col
                        class="text-left pb-0 pt-4 px-8"
                        cols="6"
                      >
                        <v-btn
                          class="mr-4"
                          :disabled="invalid || saving || invalidSpecialties"
                          text
                          @click="goToCensusStep(1)"
                        >
                          {{ $t('labels.back') }}
                        </v-btn>
                      </v-col>
                      <v-col
                        class="text-right pb-0 pt-4 px-8"
                        cols="6"
                      >
                        <v-btn
                          class="mr-4"
                          :disabled="saving"
                          text
                          @click="closeCensusDialog"
                        >
                          {{ $t('labels.cancel') }}
                        </v-btn>
                        <v-btn
                          class="submit"
                          color="secondary"
                          :disabled="invalid || saving || invalidSpecialties"
                          @click.prevent="passes(updateCensus)"
                        >
                          <v-progress-circular
                            v-if="saving"
                            color="primary lighten-2"
                            indeterminate
                            size="22"
                            width="2"
                          />
                          <span v-else>
                            {{ $t('labels.save') }}
                          </span>
                        </v-btn>
                      </v-col>
                      <v-spacer />
                    </v-row>
                  </ValidationObserver>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="showStaffingMatrixDialog"
      persistent
      width="560px"
      :value="showStaffingMatrixDialog"
    >
      <v-card
        id="departmentStaffingMatrix"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-0 pb-0">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.editStaffingMatrix') }}
            {{ selectedDepartment.name ? ` - ${selectedDepartment.name}`: '' }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeStaffingMatrixDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0 pb-0">
          <v-form>
            <v-container class="pt-0 px-6">
              <v-tabs
                v-model="selectedStaffingMatrixIdx"
                class="job-types"
                show-arrows
                slider-color="accent"
                slider-size="3"
                @change="onStaffingMatrixChange"
              >
                <v-tab
                  v-for="(jobType, idx) in jobTypesForSelectedDepartment"
                  :key="jobType.id"
                >
                  {{ jobType.name }}
                  <v-icon
                    v-if="invalidStaffingMatrix[idx]"
                    class="error--text ml-1 mr-1"
                    x-small
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </v-tab>
              </v-tabs>
              <v-container class="pa-0">
                <v-row
                  class="my-2"
                  dense
                >
                  <v-col cols="12">
                    <v-switch
                      v-model="staffingMatrixOptions.sameAcrossAllShifts"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.useSameStaffingMatrix')"
                        >
                          {{ $t('labels.useSameStaffingMatrix') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <ValidationObserver
                  v-slot="{ invalid, passes }"
                >
                  <v-row
                    class="my-2"
                    dense
                  >
                    <v-col
                      class="py-0 font-weight-medium grey--text text--darken-3"
                      cols="12"
                    >
                      {{ $t('labels.censusRange') }}
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-2"
                    dense
                  >
                    <v-col cols="3">
                      <VeeTextField
                        v-model.number="staffingMatrixOptions.censusRange.start"
                        class="census-start"
                        dense
                        hide-details
                        :label="$t('labels.from')"
                        name="censusRangeStart"
                        outlined
                        rules="required|numeric|min_value:1"
                      />
                    </v-col>
                    <v-col cols="3">
                      <VeeTextField
                        v-model.number="staffingMatrixOptions.censusRange.end"
                        class="census-end"
                        dense
                        hide-details
                        :label="$t('labels.to')"
                        name="censusRangeEnd"
                        outlined
                        :rules="{ required: true, numeric: true, min_value: { min: staffingMatrixOptions.censusRange.start } }"
                      />
                    </v-col>
                    <v-col cols="3">
                      <VeeSelect
                        v-model="staffingMatrixOptions.censusRange.interval"
                        class="interval"
                        dense
                        hide-details
                        :items="[{ text: '1', value: 1 }, { text: '2', value: 2 }, { text: '3', value: 3 }]"
                        :label="$t('labels.interval')"
                        outlined
                        rules="required"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        class="apply-census-range"
                        color="secondary"
                        :disabled="invalid"
                        @click.prevent="passes(updateCensusRange)"
                      >
                        <span>
                          {{ staffingMatrixData.length > 0 ? $t('labels.update') : $t('labels.apply') }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-0 mb-3"
                    dense
                  >
                    <v-col
                      class="py-0 grey--text caption"
                      cols="12"
                    >
                      {{ $t('descriptions.changeCensusRange') }}
                    </v-col>
                  </v-row>
                </ValidationObserver>
                <div>
                  <v-col
                    class="text-center grey lighten-4 shift-header py-1"
                    cols="12"
                  >
                    {{ $t('labels.shift') }}
                  </v-col>
                </div>
                <v-data-table
                  dense
                  fixed-header
                  :headers="staffingMatrixHeaders"
                  height="444px"
                  hide-default-footer
                  hide-default-header
                  :items="staffingMatrixData"
                  :items-per-page="staffingMatrixData.length"
                  mobile-breakpoint=""
                >
                  <template #header="{ props }">
                    <thead>
                      <tr>
                        <th
                          v-for="header in props.headers"
                          :key="header.value"
                          :class="['text-start', header.value]"
                          :style="header.width === '*' ? { height: '40px' } : { height: '40px', 'min-width': `${header.width}px`, width: `${header.width}px` }"
                        >
                          <span class="grey--text text--darken-3 font-weight-regular">
                            {{ header.text }}
                          </span>
                          <v-menu
                            v-if="header.value !== 'census' && staffingMatrixData.length > 0"
                            :ref="`menu${header.value}`"
                            :close-on-content-click="false"
                            nudge-bottom="32px"
                          >
                            <template v-slot:activator="{ on, value }">
                              <v-btn
                                :class="[value ? 'primary' : 'grey lighten-3', 'set-values']"
                                elevation="0"
                                icon
                                small
                                v-on="on"
                              >
                                <v-icon
                                  :class="value ? 'white--text' : 'grey--text text--darken-3'"
                                  small
                                >
                                  fal fa-sliders-h
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-container
                              class="px-4"
                              style="background-color: white"
                            >
                              <ValidationObserver
                                v-slot="{ invalid }"
                              >
                                <v-row
                                  class="my-2"
                                  dense
                                >
                                  <v-col>
                                    <span>
                                      {{ $t('labels.updateCensusValue1') }}
                                    </span>
                                    <VeeTextField
                                      v-model.number="staffingMatrixOptions.valueRange.value"
                                      class="census-start d-inline-block"
                                      dense
                                      hide-details
                                      name="censusValue"
                                      placeholder="#"
                                      outlined
                                      rules="required|numeric|min_value:0"
                                      style="width: 48px"
                                    />
                                    <span>
                                      {{ $t('labels.updateCensusValue2') }}
                                    </span>
                                    <VeeTextField
                                      v-model.number="staffingMatrixOptions.valueRange.start"
                                      class="census-start d-inline-block"
                                      dense
                                      hide-details
                                      name="censusValueStart"
                                      placeholder="#"
                                      outlined
                                      rules="required|numeric|min_value:1"
                                      style="width: 48px"
                                    />
                                    <span>
                                      {{ $t('labels.updateCensusValue3') }}
                                    </span>
                                    <VeeTextField
                                      v-model.number="staffingMatrixOptions.valueRange.end"
                                      class="census-end d-inline-block"
                                      dense
                                      hide-details
                                      name="censusValueEnd"
                                      placeholder="#"
                                      outlined
                                      :rules="{ required: true, numeric: true, min_value: { min: staffingMatrixOptions.valueRange.start } }"
                                      style="width: 48px"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row dense>
                                  <v-col
                                    class="text-right"
                                    cols="12"
                                  >
                                    <v-btn
                                      class="apply-census-values"
                                      color="secondary"
                                      :disabled="invalid"
                                      @click="updateCensusValue(staffingMatrixOptions.valueRange.start, staffingMatrixOptions.valueRange.end, staffingMatrixOptions.valueRange.value, header.value)"
                                    >
                                      <span>
                                        {{ $t('labels.apply') }}
                                      </span>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </ValidationObserver>
                            </v-container>
                          </v-menu>
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template #item="{ item, headers: tableHeaders }">
                    <tr
                      @mouseenter="hoveredItem = item"
                      @mouseleave="hoveredItem = null"
                    >
                      <td
                        v-for="header in tableHeaders"
                        :key="header.value"
                        :class="['text-start', header.value]"
                      >
                        <span
                          v-if="header.value === 'census'"
                          class="grey--text text--darken-3 text-truncate d-block"
                          :style="{ width: `${header.width}px` }"
                          :title="item.census"
                        >
                          {{ item.census }}
                        </span>
                        <template v-else>
                          <span
                            v-if="header.value === 'all'"
                            :class="['text-truncate d-block', isValidCensusValue(item.shiftTypes[header.shiftTypeId]) ? 'grey--text text--darken-3' : 'error--text']"
                          >
                            <v-edit-dialog
                              :return-value.sync="item.shiftTypes[header.shiftTypeId]"
                              @save="updateCensusValue(item.census, item.census, item.shiftTypes[header.shiftTypeId])"
                            >
                              {{ item.shiftTypes[header.shiftTypeId] }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model.number="item.shiftTypes[header.shiftTypeId]"
                                  dense
                                  hide-details
                                  single-line
                                />
                              </template>
                            </v-edit-dialog>
                          </span>
                          <span
                            v-else
                            :class="['text-truncate d-block', isValidCensusValue(item.shiftTypes[header.value]) ? 'grey--text text--darken-3' : 'error--text']"
                          >
                            <v-edit-dialog
                              :return-value.sync="item.shiftTypes[header.value]"
                            >
                              {{ item.shiftTypes[header.value] }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model.number="item.shiftTypes[header.value]"
                                  dense
                                  hide-details
                                  single-line
                                />
                              </template>
                            </v-edit-dialog>
                          </span>
                        </template>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </v-container>
            <v-divider />
            <v-row dense>
              <v-col
                class="text-right py-4 px-8"
                cols="12"
              >
                <v-btn
                  class="mr-4"
                  :disabled="saving"
                  text
                  @click="closeStaffingMatrixDialog"
                >
                  {{ $t('labels.cancel') }}
                </v-btn>
                <v-btn
                  class="submit"
                  color="secondary"
                  :disabled="saving || invalidStaffingMatrix.some(invalid => invalid)"
                  @click.prevent="updateStaffingMatrix"
                >
                  <v-progress-circular
                    v-if="saving"
                    color="primary lighten-2"
                    indeterminate
                    size="22"
                    width="2"
                  />
                  <span v-else>
                    {{ $t('labels.save') }}
                  </span>
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      v-if="deleteDialog.show"
      action="org/checkDepartmentDependencies"
      :delete-body="$t('descriptions.continueDeleteDepartment', { name: deleteDialog.department.name })"
      :dependencies-body="$t('descriptions.hasDependenciesDepartment', { name: deleteDialog.department.name })"
      :item="deleteDialog.department"
      :title="$t('labels.deleteDepartment?')"
      @close="closeDeleteConfirmationDialog"
      @del="deleteDepartment"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import draggable from 'vuedraggable';
import MultiSelectionList from '@/components/MultiSelectionList';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import DeleteConfirmation from '@/components/admin/DeleteConfirmation';
import { showStatus } from '@/plugins/vue-notification';
import { ERROR_CODES } from '@/services/constants';

export default {
  components: {
    DeleteConfirmation,
    draggable,
    MultiSelectionList,
    VeeSelect,
    VeeTextField
  },
  data () {
    return {
      activeItem: null,
      censusDialogStep: 1,
      censusDialogWidths: { 1: 800, 2: 1000 },
      deleteDialog: {
        show: false,
        department: null
      },
      detailsDialogStep: 1,
      detailsDialogWidths: { 1: 600, 2: 1096, 3: 600 },
      filters: {
        jobTypes: [],
        name: ''
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedDepartment: null,
      selectedDepartmentOriginal: null,
      selectedSpecialtyIdx: null,
      selectedStaffingMatrixIdx: 0,
      showAllSpecialties: true,
      showCensusDialog: false,
      showDetailsDialog: false,
      showStaffingMatrixDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      },
      staffingMatrixOptions: {
        censusRange: {
          end: null,
          interval: null,
          start: null
        },
        sameAcrossAllShifts: true,
        shiftTypeIds: [],
        valueRange: {
          end: null,
          value: null,
          start: null
        }
      }
    };
  },
  computed: {
    departments () {
      const departments = _.cloneDeep(this.$store.state.org.departments);
      for (let i = 0, count = departments.length; i < count; i++) {
        departments[i].receiveDailyCensus = this.receiveDailyCensusEnabled(departments[i]);
        departments[i].useStaffingMatrix = this.useStaffingMatrixEnabled(departments[i]);
      }
      return departments;
    },
    filteredDepartments () {
      const filters = [];

      if (this.filters.name) {
        filters.push((department) => department.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }

      if (this.filters.jobTypes.length > 0) {
        filters.push((department) => {
          const staffNeeded = _.get(department, 'settings.staffNeeded', []);
          const jobTypeIds = _.uniq(staffNeeded.reduce(
            (arr, settings) => {
              arr.push(...settings['jobTypes']);
              return arr;
            }, // eslint-disable-line no-return-assign, no-sequences
            []
          ));
          return _.intersection(this.filters.jobTypes, jobTypeIds).length > 0;
        });
      }

      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let departments = this.departments;
      if (filters.length > 0) {
        departments = _.filter(this.departments, (department) => filters.reduce((matches, filter) => {
          matches &= filter(department);
          return matches;
        }, true));
      }

      return _.orderBy(departments, this.sort.by, orders);
    },
    headers () {
      return [
        {
          sortable: false,
          text: this.$t('labels.setUpProgress'),
          value: 'setUpProgress',
          width: 120
        },
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 130
        },
        {
          sortable: false,
          text: this.$t('labels.fullName'),
          value: 'fullName',
          width: 200
        },
        {
          sortable: false,
          text: this.$tc('labels.jobType', 2),
          value: 'jobTypes',
          width: 200
        },
        {
          sortable: false,
          text: this.$t('labels.costCenter'),
          value: 'costCenter',
          width: 130
        },
        {
          sortable: true,
          text: this.$t('labels.receiveDailyCensus'),
          value: 'receiveDailyCensus',
          width: 180
        },
        {
          sortable: true,
          text: this.$t('labels.involveInScheduling'),
          value: 'partakeInScheduling',
          width: '*'
        }
      ];
    },
    invalidJobTypes () {
      if (this.selectedDepartment.partakeInScheduling) {
        if (this.selectedDepartment.settings.staffNeeded.length === 0) {
          return true;
        }

        for (let i = 0, count = this.selectedDepartment.settings.staffNeeded.length; i < count; i++) {
          if (this.selectedDepartment.settings.staffNeeded[i].jobTypes.length === 0) {
            return true;
          }
        }
      }

      return false;
    },
    invalidSpecialties () {
      for (let i = 0, count = this.selectedDepartment.settings.censusBySpecialty.length; i < count; i++) {
        if (!this.selectedDepartment.settings.censusBySpecialty[i].name) {
          return true;
        }
      }
      return false;
    },
    invalidStaffingMatrix () {
      const invalidStaffingMatrix = [];
      for (let i = 0, count = this.selectedDepartment.settings.staffNeeded.length; i < count; i++) {
        const staffingMatrix = this.selectedDepartment.settings.staffNeeded[i].staffingMatrix;
        let invalid = false;
        if (staffingMatrix.length === 0) {
          invalid = true;
        } else {
          for (let j = 0, censusCount = staffingMatrix.length; j < censusCount; j++) {
            if (_.values(staffingMatrix[j].shiftTypes).some(censusValue => !_.isNumber(censusValue))) {
              invalid = true;
            }
          }
        }
        invalidStaffingMatrix[i] = invalid;
      }
      return invalidStaffingMatrix;
    },
    jobTypesAvailable () {
      const jobTypes = _.sortBy(_.filter(this.$store.state.org.jobTypes, (jt) => jt.partakeInScheduling), ['name']);
      return jobTypes.map((jt) => {
        return {
          label: jt.name,
          value: jt.id,
          shiftTypes: jt.settings.associatedShiftTypes
        };
      });
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    jobTypesCombinedForSelectedDepartment () {
      const staffNeeded = _.cloneDeep(_.get(this.selectedDepartment, ['settings', 'staffNeeded'], []));
      const jobTypesMap = this.$store.state.org.jobTypes.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
      const jobTypes = [];
      for (let i = 0, count = staffNeeded.length; i < count; i++) {
        const associatedJobTypes = [];
        const descriptions = [];
        const names = [];
        let partakeInScheduling = false;
        for (let j = 0, typeCount = staffNeeded[i].jobTypes.length; j < typeCount; j++) {
          const jobTypeInfo = jobTypesMap[staffNeeded[i].jobTypes[j]];
          if (jobTypeInfo) {
            associatedJobTypes.push(jobTypeInfo.id);
            descriptions.push(jobTypeInfo.description);
            names.push(jobTypeInfo.name);
            if (jobTypeInfo.partakeInScheduling) {
              partakeInScheduling = true;
            }
          }
        }
        if (partakeInScheduling) {
          jobTypes.push({
            description: descriptions.join(' / '),
            id: staffNeeded[i].jobTypes.join('|'),
            name: names.join(' / '),
            associatedJobTypes,
            associatedShiftTypes: _.keys(staffNeeded[i].shiftTypes).map(id => parseInt(id)),
            staffNeeded: staffNeeded[i].shiftTypes,
            staffingMatrix: staffNeeded[i].staffingMatrix,
            groupRight: _.get(staffNeeded[i], 'display.dailySchedule.groupRight', false)
          });
        }
      }
      const groupedJobTypes = [];
      if (jobTypes.length > 0) {
        let group = [jobTypes[0]];
        for (let i = 1, count = jobTypes.length; i < count; i++) {
          if (group[group.length - 1].groupRight) {
            group.push(jobTypes[i]);
          } else {
            groupedJobTypes.push([...group]);
            group = [jobTypes[i]];
          }
        }
        groupedJobTypes.push(group);
      }

      return groupedJobTypes;
    },
    jobTypesForSelectedDepartment () {
      const jobTypes = [];
      const staffNeeded = this.selectedDepartment.settings.staffNeeded;
      for (let i = 0, count = staffNeeded.length; i < count; i++) {
        const name = this.getJobTypeName(staffNeeded[i]);
        if (name) {
          jobTypes.push({
            id: staffNeeded[i].jobTypes.join('|'),
            name: name
          });
        }
      }

      return jobTypes;
    },
    namesInUse () {
      let departments = this.departments;
      if (this.selectedDepartment) {
        departments = _.filter(this.departments, (st) => st.id !== this.selectedDepartment.id);
      }
      return departments.map((st) => st.name);
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredDepartments.length / this.pagination.itemsPerPage);
    },
    paginatedDepartments () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredDepartments.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredDepartments;
    },
    selfScheduleBidForOpening () {
      return this.$store.state.org.settings.scheduling.nurseSelfSchedule.bidForOpening;
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    staffingMatrixData () {
      return _.sortBy(this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix, ['census']);
    },
    staffingMatrixHeaders () {
      const headers = [{
        sortable: false,
        text: this.$t('labels.census'),
        value: 'census',
        width: 50
      }];
      const shiftTypeIds = _.keys(this.selectedDepartmentOriginal.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix).map((id) => parseInt(id));
      if (this.staffingMatrixOptions.sameAcrossAllShifts) {
        const shiftTypeNames = shiftTypeIds.map((id) => this.shiftTypesById[id].name);
        headers.push({
          shiftTypeId: shiftTypeIds[0],
          sortable: false,
          text: shiftTypeNames.join('/'),
          value: 'all',
          width: '*'
        });
      } else {
        const shiftTypes = shiftTypeIds.map((id) => {
          return {
            sortable: false,
            text: this.shiftTypesById[id].name,
            value: id,
            width: '*'
          };
        });
        headers.push(...shiftTypes);
      }
      return headers;
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    addJobTypeGroup () {
      this.selectedDepartment.settings.staffNeeded.push({
        display: {
          dailySchedule: {
            groupRight: false
          },
          masterSchedule: {
            groupRight: false
          }
        },
        jobTypes: [],
        maxShiftTypes: {},
        shiftTypes: {},
        staffingMatrix: {}
      });
    },
    addSpecialty () {
      const acuityBreakdown = this.selectedDepartment.settings.acuity.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.acuity] = { value: null };
        return accumulator;
      }, {});
      const count = this.selectedDepartment.settings.censusBySpecialty.length + 1;
      const specialty = {
        acuityBreakdown,
        name: `Spec. ${count}`,
        showAcuitySubtotal: true,
        subtotal: null
      };
      this.selectedDepartment.settings.censusBySpecialty.push(specialty);
      this.selectedSpecialtyIdx = this.selectedDepartment.settings.censusBySpecialty.length - 1;
    },
    createDepartment () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/createDepartment', this.getDetailsPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.departmentSaveSuccess'),
            type: 'success'
          });
          this.closeDetailsDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.departmentSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeCensusDialog () {
      this.showCensusDialog = false;
      this.selectedDepartment = null;
      this.selectedSpecialtyIdx = null;
      this.showAllSpecialties = true;
      this.censusDialogStep = 1;
    },
    closeDeleteConfirmationDialog () {
      this.deleteDialog.show = false;
      this.deleteDialog.department = null;
    },
    closeDetailsDialog () {
      this.showDetailsDialog = false;
      this.selectedDepartment = null;
      this.detailsDialogStep = 1;
    },
    closeStaffingMatrixDialog () {
      this.showStaffingMatrixDialog = false;
      this.selectedDepartment = null;
      this.selectedDepartmentOriginal = null;
      this.selectedStaffingMatrixIdx = 0;
      this.staffingMatrixOptions = {
        censusRange: {
          end: null,
          interval: null,
          start: null
        },
        sameAcrossAllShifts: true,
        valueRange: {
          end: null,
          value: null,
          start: null
        }
      };
    },
    deleteDepartment (department) {
      this.dispatch('org/deleteDepartment', department.id).then(() => {
        showStatus({
          text: this.$t('descriptions.departmentDeletionSuccess', { name: department.name }),
          type: 'success'
        });
        this.$emit('close');
      }).catch((error) => {
        const status = _.get(error, 'response.status', '');
        const responseData = {
          error: _.get(error, 'response.data')
        };

        let text = '';
        if (status === ERROR_CODES.http412PreconditionFailed) {
          text = this.$t('descriptions.departmentCannotDelete', { name: department.name });
        } else {
          text = this.$t('descriptions.departmentDeletionFail', { name: department.name });
        }

        showStatus({
          text,
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.closeDeleteConfirmationDialog();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatTime (time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    },
    getAvailableJobTypes (idx) {
      let shiftTypes = [];
      const jobTypeIdsInUse = _.uniq(this.selectedDepartment.settings.staffNeeded.reduce(
        (arr, currentValue, currentIndex) => {
          if (idx !== currentIndex) {
            arr.push(...currentValue['jobTypes']);
          } else if (currentValue['jobTypes'][0]) {
            shiftTypes = this.jobTypesById[currentValue['jobTypes'][0]].settings.associatedShiftTypes;
          }
          return arr;
        }, // eslint-disable-line no-return-assign, no-sequences
        []
      ));
      return _.filter(this.jobTypesAvailable, (jobType) => {
        return !jobTypeIdsInUse.includes(jobType.value) &&
                (shiftTypes.length === 0 || (jobType.shiftTypes.length === shiftTypes.length && _.intersection(shiftTypes, jobType.shiftTypes).length === shiftTypes.length));
      });
    },
    getCensusPayload () {
      const payload = _.cloneDeep(this.selectedDepartment);
      const acuityBreakdown = payload.settings.acuity.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.acuity] = { value: null };
        return accumulator;
      }, {});
      payload.settings.acuity = payload.settings.acuity.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.acuity] = currentValue.value;
        return accumulator;
      }, {});

      if (payload.settings.censusBySpecialty.length === 0) {
        payload.settings.censusBySpecialty = null;
      } else {
        for (let i = 0, count = payload.settings.censusBySpecialty.length; i < count; i++) {
          if (payload.settings.censusBySpecialty[i].acuityBreakdown) {
            payload.settings.censusBySpecialty[i].acuityBreakdown = _.cloneDeep(acuityBreakdown);
          } else {
            payload.settings.censusBySpecialty[i].acuityBreakdown = null;
          }
        }
      }
      return payload;
    },
    getDetailsPayload () {
      const payload = _.cloneDeep(this.selectedDepartment);
      if (payload.receiveDailyCensus) {
        if (payload.settings.acuity === null) {
          payload.settings.acuity = {};
        }
      } else {
        payload.settings.acuity = null;
        payload.settings.censusBySpecialty = null;
        payload.settings.showTotalAcuityByClass = true;
      }

      return payload;
    },
    getEventsForDialButton (department, on) {
      const click = on.click;
      on.click = (e) => {
        this.activeItem = department;
        click(e);
      };
      return on;
    },
    getJobTypeName (staffNeeded) {
      const names = [];
      for (let j = 0, typeCount = staffNeeded.jobTypes.length; j < typeCount; j++) {
        const jobTypeInfo = this.jobTypesById[staffNeeded.jobTypes[j]];
        if (jobTypeInfo) {
          names.push(jobTypeInfo.name);
        }
      }

      return names.join(' / ');
    },
    getJobTypesDisplay (department) {
      const staffNeeded = _.get(department, 'settings.staffNeeded', []);
      const jobTypeIds = _.uniq(staffNeeded.reduce(
        (arr, settings) => {
          arr.push(...settings['jobTypes']);
          return arr;
        }, // eslint-disable-line no-return-assign, no-sequences
        []
      ));
      const jobTypes = [];
      for (let i = 0, count = jobTypeIds.length; i < count; i++) {
        if (this.jobTypesById[jobTypeIds[i]]) {
          jobTypes.push(this.jobTypesById[jobTypeIds[i]].name);
        }
      }
      return jobTypes.join(', ');
    },
    getShiftTypeCount (jobTypes) {
      let count = null;
      const staffNeeded = _.find(this.selectedDepartment.settings.staffNeeded, (sn) => sn.jobTypes.includes(jobTypes[0]));
      if (staffNeeded) {
        count = _.values(staffNeeded.shiftTypes)[0];
      }
      return count;
    },
    getShiftTypes (staffNeeded) {
      const shiftTypeIds = _.keys(staffNeeded.shiftTypes).map((id) => parseInt(id));
      return _.sortBy(_.filter(this.$store.state.org.shiftTypes, (st) => shiftTypeIds.includes(st.id)), ['name']);
    },
    getStaffingMatrixPayload () {
      const payload = _.cloneDeep(this.selectedDepartment);
      for (let i = 0, count = payload.settings.staffNeeded.length; i < count; i++) {
        const staffNeeded = payload.settings.staffNeeded[i];
        const staffingMatrix = {};
        if (staffNeeded.staffingMatrix.length > 0) {
          for (let censusIndex = 0, censusCount = staffNeeded.staffingMatrix.length; censusIndex < censusCount; censusIndex++) {
            const item = staffNeeded.staffingMatrix[censusIndex];
            for (let shiftTypeId in item.shiftTypes) {
              if (!staffingMatrix[shiftTypeId]) {
                staffingMatrix[shiftTypeId] = {};
              }
              staffingMatrix[shiftTypeId][item.census] = {
                value: item.shiftTypes[shiftTypeId]
              };
            }
          }
        } else {
          const shiftTypeIds = _.keys(this.selectedDepartmentOriginal.settings.staffNeeded[i].staffingMatrix);
          for (let shiftTypeIndex = 0, shiftTypeCount = shiftTypeIds.length; shiftTypeIndex < shiftTypeCount; shiftTypeIndex++) {
            staffingMatrix[shiftTypeIds[shiftTypeIndex]] = {};
          }
        }
        staffNeeded.staffingMatrix = staffingMatrix;
      }
      return payload;
    },
    getUniqueId () {
      return _.uniqueId();
    },
    goToCensusStep (step) {
      this.censusDialogStep = step;
    },
    goToDetailsStep (step) {
      switch (step) {
        case 1:
          this.detailsDialogStep = 1;
          break;
        case 2:
          this.detailsDialogStep = 2;
          this.$nextTick(() => {
            if (this.$refs.masterTabs) {
              this.$refs.masterTabs.callSlider();
            }
            if (this.$refs.dailyTabs) {
              this.$refs.dailyTabs.callSlider();
            }
          });
          break;
        case 3:
          this.updateStaffNeededShiftTypes();
          this.detailsDialogStep = 3;
          this.$nextTick(() => {
            if (this.$refs.staffNeededTabs) {
              this.$refs.staffNeededTabs.callSlider();
            }
          });
          break;
      }
    },
    isStaffingMatrixComplete (department) {
      for (let i = 0, count = department.settings.staffNeeded.length; i < count; i++) {
        for (let shiftTypeId in department.settings.staffNeeded[i].staffingMatrix) {
          if (_.isEmpty(department.settings.staffNeeded[i].staffingMatrix[shiftTypeId])) {
            return false;
          }
        }
      }
      return true;
    },
    isValidCensusValue (value) {
      return _.isNumber(value);
    },
    needsProjectedStaff (department) {
      const staffNeeded = _.get(department, 'settings.staffNeeded', []);
      let staffNeededCompleted = true;
      for (let i = 0, count = staffNeeded.length; i < count; i++) {
        const settings = staffNeeded[i];
        if (settings['jobTypes'].length === 0 || _.isEmpty(settings['shiftTypes'])) {
          staffNeededCompleted = false;
          break;
        }

        for (let shiftTypeId in settings['shiftTypes']) {
          if (settings['shiftTypes'][shiftTypeId] === null) {
            staffNeededCompleted = false;
            break;
          }
        }

        if (!staffNeededCompleted) {
          break;
        }
      }

      return !staffNeededCompleted;
    },
    onReceiveDailyCensusChange (receiveDailyCensus) {
      if (!receiveDailyCensus) {
        this.selectedDepartment.useStaffingMatrix = false;
      }
    },
    onStaffingMatrixChange () {
      let sameAcrossAllShifts = true;
      let censusRangeStart = null;
      let censusRangeEnd = null;
      let interval = null;
      const staffingMatrix = this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix;
      if (staffingMatrix.length === 1) {
        censusRangeStart = staffingMatrix[0].census;
        censusRangeEnd = staffingMatrix[0].census;
        interval = 1;
      } else if (staffingMatrix.length > 1) {
        censusRangeStart = staffingMatrix[0].census;
        censusRangeEnd = staffingMatrix[staffingMatrix.length - 1].census;
        interval = staffingMatrix[1].census - staffingMatrix[0].census;
      }
      for (let i = 0, count = staffingMatrix.length; i < count; i++) {
        sameAcrossAllShifts &= _.uniq(_.values(staffingMatrix[i].shiftTypes)).length === 1;
      }
      this.staffingMatrixOptions.sameAcrossAllShifts = sameAcrossAllShifts;
      this.staffingMatrixOptions.censusRange.start = censusRangeStart;
      this.staffingMatrixOptions.censusRange.end = censusRangeEnd;
      this.staffingMatrixOptions.censusRange.interval = interval;
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openCensusDialog (department) {
      const departmentCopy = _.cloneDeep(department);
      const acuity = _.get(departmentCopy, 'settings.acuity', {});
      const acuityParsed = [];
      if (acuity) {
        for (let acuityClass in acuity) {
          acuityParsed.push({
            acuity: parseInt(acuityClass),
            value: acuity[acuityClass]
          });
        }
      }
      if (acuityParsed.length === 0) {
        acuityParsed.push({
          acuity: 1,
          value: {
            label: ''
          }
        });
      }
      departmentCopy.settings.acuity = _.sortBy(acuityParsed, ['acuity']);
      if (!departmentCopy.settings.censusBySpecialty) {
        departmentCopy.settings.censusBySpecialty = [];
      }
      for (let i = 0, count = departmentCopy.settings.censusBySpecialty.length; i < count; i++) {
        if (departmentCopy.settings.censusBySpecialty[i].acuityBreakdown) {
          departmentCopy.settings.censusBySpecialty[i].acuityBreakdown = true;
        } else {
          departmentCopy.settings.censusBySpecialty[i].acuityBreakdown = false;
        }
      }
      if (departmentCopy.settings.censusBySpecialty.length > 0) {
        this.selectedSpecialtyIdx = 0;
      }
      this.selectedDepartment = departmentCopy;
      this.showCensusDialog = true;
      this.activeItem = null;
    },
    openDeleteConfirmationDialog (department) {
      this.deleteDialog.department = department;
      this.deleteDialog.show = true;
    },
    openDetailsDialog (department) {
      let selectedDepartment = {
        costCenter: '',
        fullName: '',
        name: '',
        partakeInScheduling: true,
        receiveDailyCensus: true,
        scheduleRules: {},
        settings: {
          acuity: null,
          censusBySpecialty: null,
          showTotalAcuityByClass: true,
          staffNeeded: []
        },
        useStaffingMatrix: true
      };
      if (department) {
        selectedDepartment = _.cloneDeep(department);
        selectedDepartment.settings = {
          acuity: null,
          censusBySpecialty: null,
          showTotalAcuityByClass: true,
          staffNeeded: [],
          ...selectedDepartment.settings
        };
      }
      this.selectedDepartment = selectedDepartment;
      this.showDetailsDialog = true;
      this.activeItem = null;
    },
    openStaffingMatrixDialog (department) {
      const departmentCopy = _.cloneDeep(department);
      let sameAcrossAllShifts = true;
      let censusRangeStart = null;
      let censusRangeEnd = null;
      let interval = null;
      for (let i = 0, count = departmentCopy.settings.staffNeeded.length; i < count; i++) {
        const staffNeeded = departmentCopy.settings.staffNeeded[i];
        const staffingMatrix = [];
        if (staffNeeded.staffingMatrix) {
          const census = _.sortBy(_.keys(_.values(staffNeeded.staffingMatrix)[0]).map((c) => parseInt(c)));
          for (let j = 0, count = census.length; j < count; j++) {
            const item = {
              census: census[j],
              shiftTypes: {}
            };
            for (let shiftTypeId in staffNeeded.staffingMatrix) {
              item.shiftTypes[shiftTypeId] = _.get(staffNeeded.staffingMatrix, [shiftTypeId, census[j], 'value'], 0);
            }
            if (i === 0) {
              sameAcrossAllShifts &= _.uniq(_.values(item.shiftTypes)).length === 1;
            }
            staffingMatrix.push(item);
          }
          if (i === 0) {
            if (census.length === 1) {
              censusRangeStart = census[0];
              censusRangeEnd = census[0];
              interval = 1;
            } else if (census.length > 1) {
              censusRangeStart = census[0];
              censusRangeEnd = census[census.length - 1];
              interval = census[1] - census[0];
            }
          }
        }
        staffNeeded.staffingMatrix = staffingMatrix;
      }
      this.selectedDepartment = departmentCopy;
      this.selectedDepartmentOriginal = department;
      this.staffingMatrixOptions.sameAcrossAllShifts = sameAcrossAllShifts;
      this.staffingMatrixOptions.censusRange.start = censusRangeStart;
      this.staffingMatrixOptions.censusRange.end = censusRangeEnd;
      this.staffingMatrixOptions.censusRange.interval = interval;
      this.showStaffingMatrixDialog = true;
      this.activeItem = null;
    },
    receiveDailyCensusEnabled (department) {
      const census = _.get(department, 'settings.acuity', null);
      return census !== null && census !== undefined;
    },
    removeJobType (idx) {
      this.selectedDepartment.settings.staffNeeded.splice(idx, 1);
    },
    removeSpecialty (idx) {
      this.selectedDepartment.settings.censusBySpecialty.splice(idx, 1);
    },
    setFilter (field, value) {
      this.filters[field] = value;
    },
    toggleAcuityBreakdown () {
      if (this.selectedDepartment.settings.censusBySpecialty[this.selectedSpecialtyIdx].acuityBreakdown === null) {
        const acuityBreakdown = this.selectedDepartment.settings.acuity.reduce(function (accumulator, currentValue) {
          accumulator[currentValue.acuity] = { value: null };
          return accumulator;
        }, {});
        this.selectedDepartment.settings.censusBySpecialty[this.selectedSpecialtyIdx].acuityBreakdown = acuityBreakdown;
      } else {
        this.selectedDepartment.settings.censusBySpecialty[this.selectedSpecialtyIdx].acuityBreakdown = null;
      }
    },
    updateAcuityCount (count) {
      if (this.selectedDepartment.settings.acuity.length < count) {
        for (let i = this.selectedDepartment.settings.acuity.length; i < count; i++) {
          this.selectedDepartment.settings.acuity.push({
            acuity: i + 1,
            value: {
              label: ''
            }
          });
        }
      } else if (this.selectedDepartment.settings.acuity.length > count) {
        this.selectedDepartment.settings.acuity.splice(count);
      }
    },
    updateCensus () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateDepartment', this.getCensusPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.departmentSaveSuccess'),
            type: 'success'
          });
          this.closeCensusDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.departmentSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateCensusRange () {
      const map = this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix.reduce(
        (obj, data) => {
          obj[data.census] = data.shiftTypes;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
      const staffingMatrix = [];
      const shiftTypeIds = _.keys(this.selectedDepartmentOriginal.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix);
      const defaultValues = shiftTypeIds.reduce(
        (obj, id) => {
          obj[id] = 0;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
      for (let i = this.staffingMatrixOptions.censusRange.start; i <= this.staffingMatrixOptions.censusRange.end; i += this.staffingMatrixOptions.censusRange.interval) {
        staffingMatrix.push({
          census: i,
          shiftTypes: map[i] || _.cloneDeep(defaultValues)
        });
      }
      this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix = staffingMatrix;
    },
    updateCensusValue (start, end, value, shiftTypeId) {
      for (let i = start; i <= end; i++) {
        const index = _.findIndex(this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix, (data) => data.census === i);
        if (index >= 0) {
          if (_.isNumber(shiftTypeId)) {
            this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix[index].shiftTypes[shiftTypeId] = value;
          } else {
            for (let id in this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix[index].shiftTypes) {
              this.selectedDepartment.settings.staffNeeded[this.selectedStaffingMatrixIdx].staffingMatrix[index].shiftTypes[id] = value;
            }
          }
        }
      }
    },
    updateDetails () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateDepartment', this.getDetailsPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.departmentSaveSuccess'),
            type: 'success'
          });
          this.closeDetailsDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.departmentSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateMaxStaffNeeded (idx, shiftType) {
      const staffNeeded = this.selectedDepartment.settings.staffNeeded[idx];
      if (_.isNumber(staffNeeded.shiftTypes[shiftType])) {
        staffNeeded.maxShiftTypes[shiftType] = Math.max(staffNeeded.maxShiftTypes[shiftType], staffNeeded.shiftTypes[shiftType]);
      }
    },
    updateStaffingMatrix () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateDepartment', this.getStaffingMatrixPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.departmentSaveSuccess'),
            type: 'success'
          });
          this.closeStaffingMatrixDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.departmentSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateStaffNeededShiftTypes () {
      for (let staffNeededIdx = 0, staffNeededCount = this.selectedDepartment.settings.staffNeeded.length; staffNeededIdx < staffNeededCount; staffNeededIdx++) {
        const staffNeeded = this.selectedDepartment.settings.staffNeeded[staffNeededIdx];
        const associatedShiftTypes = this.jobTypesById[staffNeeded['jobTypes'][0]].settings.associatedShiftTypes;
        const existingShiftTypes = _.keys(staffNeeded.shiftTypes).map((id) => parseInt(id));
        const newShiftTypes = associatedShiftTypes.reduce(
          (obj, shiftTypeId) => {
            obj[shiftTypeId] = null;
            return obj;
          }, // eslint-disable-line no-return-assign, no-sequences
          {}
        );
        for (let i = 0, count = existingShiftTypes.length; i < count; i++) {
          if (!associatedShiftTypes.includes(existingShiftTypes[i])) {
            delete staffNeeded.shiftTypes[existingShiftTypes[i]];
            delete staffNeeded.maxShiftTypes[existingShiftTypes[i]];
          }
        }
        this.selectedDepartment.settings.staffNeeded[staffNeededIdx].shiftTypes = {
          ...newShiftTypes,
          ...staffNeeded.shiftTypes
        };

        this.selectedDepartment.settings.staffNeeded[staffNeededIdx].maxShiftTypes = {
          ...newShiftTypes,
          ...staffNeeded.maxShiftTypes
        };

        if (this.selectedDepartment.useStaffingMatrix) {
          let existingStaffingMatrix = [];
          let previousStaffingMatrix = staffNeeded.staffingMatrix;
          if (previousStaffingMatrix) {
            existingStaffingMatrix = _.keys(previousStaffingMatrix).map((id) => parseInt(id));
          } else {
            previousStaffingMatrix = null;
          }
          const newStaffingMatrix = associatedShiftTypes.reduce(
            (obj, shiftTypeId) => {
              obj[shiftTypeId] = {};
              return obj;
            }, // eslint-disable-line no-return-assign, no-sequences
            {}
          );
          for (let i = 0, count = existingStaffingMatrix.length; i < count; i++) {
            if (!associatedShiftTypes.includes(existingStaffingMatrix[i])) {
              delete this.selectedDepartment.settings.staffNeeded[staffNeededIdx].staffingMatrix[existingStaffingMatrix[i]];
            }
          }
          this.selectedDepartment.settings.staffNeeded[staffNeededIdx].staffingMatrix = {
            ...newStaffingMatrix,
            ...previousStaffingMatrix
          };
        } else {
          this.selectedDepartment.settings.staffNeeded[staffNeededIdx].staffingMatrix = null;
        }
      }
    },
    useStaffingMatrixEnabled (department) {
      let enabled = false;
      if (department.settings.staffNeeded) {
        const staffNeeded = department.settings.staffNeeded[0];
        if (this.receiveDailyCensusEnabled(department) && staffNeeded) {
          enabled = staffNeeded.staffingMatrix !== null;
        }
      }
      return enabled;
    }
  }
};
</script>

<style lang="scss">
#departments {
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .department-dial {
    right: 0px !important;
  }
  .setup-step {
    margin-right: 4px;
    &.skip {
      opacity: 0.3;
    }
  }
  thead th span {
    font-size: 12px !important;
  }
}
#departmentCensus {
  $height-int: 28;
  $input-margin-x-int: 4;
  $line-height-int: 26;
  $width-int: 48;

  $height: #{$height-int}px;
  $input-margin-x: #{$input-margin-x-int}px;
  $line-height: #{$line-height-int}px;
  $width: #{$width-int}px;
  .header {
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    width: #{$width-int + $input-margin-x-int * 2}px;
  }
  .row-label {
    line-height: $line-height;
    width: $width;
  }
  .acuity {
    fieldset {
      height: $height;
      width: $width;
    }
    .acuity-input {
      display: inline-block;
      flex: none;
      font-size: 14px;
      height: $height;
      line-height: $line-height;
      margin-left: $input-margin-x;
      margin-right: $input-margin-x;
      text-align: center;
      width: $width;
      .v-input__slot {
        min-height: 20px !important;
      }
      .v-text-field__slot {
        height: 24px;
        input {
          text-align: center;
        }
      }
    }
  }
  .specialty {
    .active {
      background: map-get($grey, 'lighten-4');
    }
    .list {
      border-right: 1px solid map-get($grey, 'lighten-2');
      height: 410px;
      overflow: auto;

      .v-list-item__content {
        padding: 0px !important;
      }
      .v-list-item__action {
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
  }
  .specialty-preview {
    height: 410px;
    overflow: auto;
  }
}
#departmentDetails {
  .daily-schedule-display {
    margin-left: 12px;
    margin-top: 5px;
    span {
      text-transform: initial !important;
      width: 122px;
    }
  }
  .dnd {
    text-align: center;
    .v-icon {
      cursor: pointer;
      padding-top: 12px;
    }
  }
  .job-count {
    background-color: $primary-lighten-2;
    border-radius: 4px;
    color: white;
    display: inline-block;
    height: 16px;
    line-height: 13px;
    padding-top: 0px;
    margin-top: -2px;
    min-width: 16px;
    text-align: center;
    span {
      font-size: 10px;
    }
  }
  .preview {
    background-color: white;
    color: map-get($grey, 'darken-2');
    text-align: center;
  }
  .shift-types-needed {
    border-left: 1px solid map-get($grey, 'lighten-2');
    height: 310px;
    overflow-y: scroll;
    &> .row {
      text-align: center;
    }
  }
  .staff-needed-container {
    height: 310px;
  }
  .staff-needed-count {
    height: 308px;
    .v-tab {
      border-radius: 4px;
      height: 40px;
      justify-content: left;
      &.v-tab--active {
        background-color: map-get($grey, 'lighten-4');
      }

    }
    .v-tabs-bar {
      padding: 12px;
      width: 275px;
    }
  }
  .v-chip__content {
    color: map-get($grey, 'darken-3');
    text-shadow: none;
  }
  .v-select__selections {
    margin-top: 4px !important;
  }
}
#departmentStaffingMatrix {
  .shift-header {
    border-left: 1px solid map-get($grey, 'lighten-2') !important;
    border-right: 1px solid map-get($grey, 'lighten-2') !important;
    border-top: 1px solid map-get($grey, 'lighten-2') !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .v-data-table {
    border: 1px solid map-get($grey, 'lighten-2');
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    .census {
      background-color: map-get($grey, 'lighten-4');
    }
  }
}
.v-small-dialog__menu-content {
  margin-left: -16px !important;
  margin-top: -6px !important;
  input {
    padding-bottom: 4px !important;
  }
}
</style>
