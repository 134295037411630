var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: _vm.dialogStyle.width },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pa-0",
          attrs: { flat: "", height: _vm.dialogStyle.height }
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("UserProfile", {
                attrs: {
                  "show-hint": _vm.showHint,
                  "show-title": "",
                  user: _vm.user
                },
                on: {
                  close: function($event) {
                    return _vm.$emit("close")
                  },
                  saved: _vm.updateUser
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }