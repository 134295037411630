var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "delete-confirmation",
        persistent: "",
        width: "560px"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0" },
        [
          _c(
            "v-card-title",
            { staticClass: "body-2 d-block mb-0 pb-0" },
            [
              _c("span", { staticClass: "body-2 font-weight-medium" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "float-right mt-n1",
                  attrs: { disabled: _vm.deleting, icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("\n          fal fa-times\n        ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.state === "checkingDependencies"
            ? [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                color: "info",
                                indeterminate: "",
                                size: "75",
                                width: "6"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "text-center" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("descriptions.checkingDependencies")
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm.state === "cannotDelete"
            ? [
                _c("v-card-text", { staticClass: "delete-department-body" }, [
                  _c("div", {
                    staticClass: "body-2 mt-3",
                    domProps: { innerHTML: _vm._s(_vm.dependenciesBody) }
                  })
                ])
              ]
            : _vm.state === "deleteConfirmation"
            ? [
                _c("v-card-text", [
                  _c("div", {
                    staticClass: "body-2 mt-3",
                    domProps: { innerHTML: _vm._s(_vm.deleteBody) }
                  })
                ]),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-3 px-5",
                        attrs: { disabled: _vm.deleting, text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.cancel")) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-3 px-5",
                        attrs: { color: "accent", disabled: _vm.deleting },
                        on: { click: _vm.del }
                      },
                      [
                        _vm.deleting
                          ? _c("v-progress-circular", {
                              attrs: {
                                color: "primary lighten-2",
                                indeterminate: "",
                                size: "22",
                                width: "2"
                              }
                            })
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("labels.delete")) +
                                  "\n          "
                              )
                            ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c("v-card-text", { staticClass: "delete-department-body" }, [
                  _c("div", {
                    staticClass: "body-2 mt-3",
                    domProps: { innerHTML: _vm._s(_vm.error) }
                  })
                ])
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }