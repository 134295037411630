<template>
  <v-container
    class="px-0"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $t('labels.hospital'), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      class="mx-10 my-3 hospital py-3"
    >
      <ValidationObserver
        v-slot="{ invalid, passes }"
      >
        <v-form>
          <v-container class="px-4 pt-3 pb-0">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeTextField
                  v-model.lazy="name"
                  dense
                  :label="$t('labels.name')"
                  name="name"
                  outlined
                  rules="required|max:256"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeTextField
                  v-model.lazy="ccn"
                  dense
                  :label="$t('labels.ccn')"
                  name="ccn"
                  outlined
                  rules="required|max:8"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeTextField
                  v-model.lazy="address1"
                  dense
                  :label="$t('labels.address')"
                  name="address1"
                  outlined
                  rules="required|max:512"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeTextField
                  v-model.lazy="city"
                  dense
                  :label="$t('labels.city')"
                  name="city"
                  outlined
                  rules="required|max:128"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeSelect
                  v-model="state"
                  dense
                  :items="availableStates"
                  :label="$tc('labels.state', 1)"
                  name="state"
                  outlined
                  rules="required"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeTextField
                  v-model.lazy="zipCode"
                  dense
                  :label="$t('labels.zipCode')"
                  name="zipCode"
                  outlined
                  rules="required|digits:5"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeTextField
                  v-model.lazy="phoneDefault"
                  v-mask="phoneMask"
                  class="phone-number"
                  dense
                  :label="$t('labels.phone')"
                  name="phoneDefault"
                  prefix="+1"
                  outlined
                  :rules="{ max: 30, phoneRegex_US: phoneRegex}"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <VeeSelect
                  v-model="timezone"
                  dense
                  :items="availableTimezones"
                  :label="$t('labels.timezone')"
                  name="timezone"
                  outlined
                  rules="required"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
          <v-row class="px-4 pt-0">
            <v-spacer />
            <v-col
              class="text-right"
              cols="12"
              sm="6"
            >
              <v-btn
                class="mt-4 submit"
                color="secondary"
                :disabled="invalid || saving"
                @click.prevent="passes(save)"
              >
                <v-progress-circular
                  v-if="saving"
                  color="primary lighten-2"
                  indeterminate
                  size="22"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.save') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import { COUNTRY_NAMES, PHONE_REGEX_US, STATE_NAMES_US, TZ_NAMES_US } from '@/components/form_controls/constants';
import { processPhoneNumberForUIDisplay } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';

export default {
  components: {
    VeeSelect,
    VeeTextField
  },
  data () {
    const phones = {
      phoneDefault: this.$store.state.org.info.phoneDefault
    };
    processPhoneNumberForUIDisplay(phones);
    return {
      address1: this.$store.state.org.info.address1,
      availableCountries: COUNTRY_NAMES,
      availableStates: STATE_NAMES_US,
      availableTimezones: TZ_NAMES_US,
      ccn: this.$store.state.org.info.ccn,
      city: this.$store.state.org.info.city,
      country: 'US',
      name: this.$store.state.org.info.name,
      phoneDefault: phones.phoneDefault,
      phoneCountryCode: '+1',
      phoneMask: '(###) ###-####',
      phoneRegex: PHONE_REGEX_US,
      saving: false,
      state: this.$store.state.org.info.state,
      timezone: this.$store.state.org.info.timezone,
      zipCode: this.$store.state.org.info.zipCode
    };
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    save () {
      if (!this.saving) {
        this.saving = true;
        const payload = {
          address1: this.address1,
          ccn: this.ccn,
          city: this.city,
          country: this.country,
          name: this.name,
          state: this.state,
          timezone: this.timezone,
          zipCode: this.zipCode
        };

        if (this.phoneDefault) {
          payload.phoneDefault = this.phoneCountryCode + this.phoneDefault;
        } else {
          payload.phoneDefault = '';
        }

        this.dispatch('org/updateHospitalInfo', payload).then(() => {
          showStatus({
            text: this.$t('descriptions.hospitalUpdateSuccess'),
            type: 'success'
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.hospitalUpdateFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
.hospital {
  max-width: 800px;

  .phone-number .v-text-field__prefix {
    color: map-get($grey, 'base');
  }

  .row {
     > div {
       padding-top: 0px;
     }
  }
}
</style>
