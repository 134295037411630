var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0", attrs: { id: "scheduleValidations", fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$t("labels.schedule"), disabled: false },
                { text: _vm.$tc("labels.validation", 2), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-10 my-3 validations", attrs: { elevation: "4" } },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  var passes = ref.passes
                  return [
                    _c(
                      "v-container",
                      { staticClass: "pa-4", attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "body-2 font-weight-medium text--grey text--darken-3"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "labels.excessiveConsecutiveShifts"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", { staticClass: "py-0" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption grey--text text--darken-1"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "descriptions.excessiveConsecutiveShifts"
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _vm.consecutiveShiftsValidationData.length > 0
                          ? _c(
                              "v-row",
                              { staticClass: "my-2", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "job-types pr-4",
                                    attrs: { cols: "4" }
                                  },
                                  _vm._l(
                                    _vm.consecutiveShiftsValidationData,
                                    function(validation, validationIdx) {
                                      return _c(
                                        "v-row",
                                        { key: validationIdx },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("VeeSelect", {
                                                staticClass:
                                                  "job-type-selection",
                                                attrs: {
                                                  dense: "",
                                                  "hide-details": "",
                                                  item: "",
                                                  "item-text": "label",
                                                  "item-value": "value",
                                                  items: _vm.getAvailableJobTypes(
                                                    validationIdx
                                                  ),
                                                  label: _vm.$tc(
                                                    "labels.jobType",
                                                    2
                                                  ),
                                                  multiple: "",
                                                  outlined: "",
                                                  rules: "required",
                                                  vid:
                                                    "jobTypes" + validationIdx
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.updateShiftTypes(
                                                      validationIdx
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        var index = ref.index
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mt-n1"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    index !==
                                                                      validation
                                                                        .jobTypes
                                                                        .length -
                                                                        1
                                                                      ? ", "
                                                                      : ""
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: validation.jobTypes,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      validation,
                                                      "jobTypes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "validation.jobTypes"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "shift-types grey lighten-4",
                                    attrs: { cols: "7" }
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      {
                                        staticClass: "pa-0",
                                        attrs: { fluid: "" }
                                      },
                                      _vm._l(
                                        _vm.consecutiveShiftsValidationData,
                                        function(validation, validationIdx) {
                                          return _c(
                                            "v-row",
                                            { key: validationIdx },
                                            [
                                              _c(
                                                "v-col",
                                                _vm._l(
                                                  _vm.getShiftTypes(validation),
                                                  function(
                                                    shiftType,
                                                    shiftIdx
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: shiftIdx,
                                                        staticClass:
                                                          "d-inline-block shift-count-container"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 grey--text text--darken-3 mx-4 text-truncate shift-name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  shiftType.name
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        ),
                                                        _c("VeeTextField", {
                                                          staticClass:
                                                            "shift-count d-inline-block",
                                                          staticStyle: {
                                                            width: "80px"
                                                          },
                                                          attrs: {
                                                            dense: "",
                                                            "hide-details": "",
                                                            label: _vm.$t(
                                                              "labels.max"
                                                            ),
                                                            name: "shiftCount",
                                                            outlined: "",
                                                            rules:
                                                              "required|numeric|min_value:0",
                                                            vid:
                                                              "shiftCount" +
                                                              shiftIdx +
                                                              "-" +
                                                              validationIdx
                                                          },
                                                          model: {
                                                            value:
                                                              validation
                                                                .shiftTypes[
                                                                shiftType.id
                                                              ],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                validation.shiftTypes,
                                                                shiftType.id,
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "validation.shiftTypes[shiftType.id]"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "1" } },
                                  _vm._l(
                                    _vm.consecutiveShiftsValidationData,
                                    function(validation, validationIdx) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: validationIdx,
                                          style: { height: "64px" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ml-2 remove-consecutive-validation",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeConsecutiveShiftValidation(
                                                        validationIdx
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        small: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    fal fa-trash-alt\n                  "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "caption add-consecutive-validation secondary--text",
                                  on: {
                                    click: _vm.addConsecutiveShiftValidation
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              + " +
                                      _vm._s(
                                        _vm.$t("labels.addValidationRule")
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-row",
                      { staticClass: "pa-4", attrs: { dense: "" } },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { staticClass: "text-right", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-3 save-validations",
                                attrs: {
                                  color: "secondary",
                                  disabled: invalid || _vm.saving
                                },
                                on: {
                                  click: function($event) {
                                    return passes(_vm.save)
                                  }
                                }
                              },
                              [
                                _vm.saving
                                  ? _c("v-progress-circular", {
                                      attrs: {
                                        color: "secondary",
                                        indeterminate: "",
                                        size: "22",
                                        width: "2"
                                      }
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.save")) +
                                          "\n            "
                                      )
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }