var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-10 my-3", attrs: { fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$tc("labels.general", 2), disabled: false },
                { text: _vm.$tc("labels.department", 2), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { id: "departments" } },
        [
          _c(
            "v-row",
            { staticClass: "actions", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass:
                      "name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block",
                    attrs: {
                      "append-icon": _vm.filters.name ? "" : "fal fa-search",
                      clearable: !!_vm.filters.name,
                      dense: "",
                      "hide-details": "",
                      placeholder: _vm.$t("labels.searchByName"),
                      solo: ""
                    },
                    model: {
                      value: _vm.filters.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.filters,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "filters.name"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right mt-2 mr-4",
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.openDetailsDialog()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.addDepartment")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            directives: [
              {
                name: "resize",
                rawName: "v-resize.quiet",
                value: _vm.onWindowResized,
                expression: "onWindowResized",
                modifiers: { quiet: true }
              }
            ],
            attrs: {
              "fixed-header": "",
              headers: _vm.headers,
              "header-props": { sortIcon: "fa fa-arrow-up" },
              "hide-default-footer": "",
              items: _vm.paginatedDepartments,
              "items-per-page": _vm.pagination.itemsPerPage,
              "must-sort": "",
              "single-select": "",
              "sort-by": _vm.sort.by,
              "sort-desc": _vm.sort.desc
            },
            on: {
              "update:sortBy": function($event) {
                return _vm.$set(_vm.sort, "by", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.sort, "by", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.sort, "desc", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.sort, "desc", $event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "header.fullName",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "header.jobTypes",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("MultiSelectionList", {
                      attrs: { selection: _vm.jobTypesAvailable },
                      on: {
                        selectionConfirmed: function($event) {
                          return _vm.setFilter(header.value, $event)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "ml-n4 subtitle-2 text-capitalize",
                                      attrs: { color: "secondary", text: "" }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(header.text) +
                                        "\n              "
                                    ),
                                    _vm.filters[header.value].length > 0
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "grey",
                                              right: "",
                                              "x-small": ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-filter\n              "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "grey",
                                              right: "",
                                              "x-small": ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fal fa-filter\n              "
                                            )
                                          ]
                                        )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              },
              {
                key: "header.costCenter",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var tableHeaders = ref.headers
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          mouseenter: function($event) {
                            _vm.hoveredItem = item
                          },
                          mouseleave: function($event) {
                            _vm.hoveredItem = null
                          }
                        }
                      },
                      [
                        _vm._l(tableHeaders, function(header) {
                          return _c(
                            "td",
                            { key: header.value, staticClass: "text-start" },
                            [
                              header.value === "name"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.name }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "fullName"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.fullName }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.fullName) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "jobTypes"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: {
                                        title: _vm.getJobTypesDisplay(item)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getJobTypesDisplay(item)) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "costCenter"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.costCenter }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.costCenter) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "receiveDailyCensus"
                                ? [
                                    _vm.receiveDailyCensusEnabled(item)
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "success",
                                              small: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fas fa-check-circle\n              "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "grey", small: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                far fa-times-circle\n              "
                                            )
                                          ]
                                        )
                                  ]
                                : _vm._e(),
                              header.value === "partakeInScheduling"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-inline-block",
                                        style: { "min-width": "150px" }
                                      },
                                      [
                                        item.partakeInScheduling
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "success",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fas fa-check-circle\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  far fa-times-circle\n                "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              header.value === "setUpProgress"
                                ? [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "setup-step",
                                                          attrs: {
                                                            color: "success",
                                                            "x-small": ""
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                    fas fa-square\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.details")) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  item.receiveDailyCensus
                                                    ? [
                                                        Object.keys(
                                                          item.settings.acuity
                                                        ).length > 0
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "setup-step",
                                                                    attrs: {
                                                                      color:
                                                                        "success",
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      fas fa-square\n                    "
                                                                )
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "setup-step",
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      fas fa-square\n                    "
                                                                )
                                                              ]
                                                            )
                                                      ]
                                                    : _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "skip setup-step",
                                                              attrs: {
                                                                color:
                                                                  "success",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    fas fa-square\n                  "
                                                          )
                                                        ]
                                                      )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.censusCollection"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  item.useStaffingMatrix
                                                    ? [
                                                        _vm.isStaffingMatrixComplete(
                                                          item
                                                        )
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "setup-step",
                                                                    attrs: {
                                                                      color:
                                                                        "success",
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      fas fa-square\n                    "
                                                                )
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "setup-step",
                                                                    attrs: {
                                                                      color:
                                                                        "error",
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      fas fa-square\n                    "
                                                                )
                                                              ]
                                                            )
                                                      ]
                                                    : _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "skip setup-step",
                                                              attrs: {
                                                                color:
                                                                  "success",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    fas fa-square\n                  "
                                                          )
                                                        ]
                                                      )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.staffingMatrix")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        _c(
                          "v-speed-dial",
                          {
                            staticClass: "mt-6 department-dial",
                            attrs: {
                              absolute: "",
                              value:
                                (_vm.hoveredItem &&
                                  _vm.hoveredItem.id === item.id) ||
                                (_vm.activeItem &&
                                  _vm.activeItem.id === item.id),
                              right: "",
                              direction: "left",
                              transition: "slide-x-reverse-transition"
                            }
                          },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "", "nudge-left": "170" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "edit",
                                                attrs: {
                                                  fab: "",
                                                  height: "30",
                                                  width: "30",
                                                  title: _vm.$t(
                                                    "labels.editDepartment"
                                                  )
                                                }
                                              },
                                              _vm.getEventsForDialButton(
                                                item,
                                                on
                                              )
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [
                                                  _vm._v(
                                                    "\n                    fal fa-pencil\n                  "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass: "caption edit-details",
                                        attrs: {
                                          title: _vm.$t("labels.details")
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.openDetailsDialog(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("labels.details")) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass: "caption edit-census",
                                        attrs: {
                                          disabled: !item.receiveDailyCensus,
                                          title: _vm.$t(
                                            "labels.censusCollection"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.openCensusDialog(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.censusCollection"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        staticClass:
                                          "caption edit-staffing-matrix",
                                        attrs: {
                                          disabled: !item.useStaffingMatrix,
                                          title: _vm.$t("labels.staffingMatrix")
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.openStaffingMatrixDialog(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("labels.staffingMatrix")
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "delete",
                                attrs: {
                                  fab: "",
                                  height: "30",
                                  width: "30",
                                  title: _vm.$t("labels.deleteDepartment")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openDeleteConfirmationDialog(
                                      item
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "error", small: "" } },
                                  [
                                    _vm._v(
                                      "\n                fal fa-trash-alt\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("v-pagination", {
                      staticClass: "py-4 footer",
                      attrs: {
                        color: "secondary",
                        length: _vm.numberOfPages,
                        "next-icon": "far fa-chevron-right",
                        "prev-icon": "far fa-chevron-left",
                        "total-visible": _vm.pagination.maxPaginationControls
                      },
                      model: {
                        value: _vm.pagination.currentPage,
                        callback: function($$v) {
                          _vm.$set(_vm.pagination, "currentPage", $$v)
                        },
                        expression: "pagination.currentPage"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm.showDetailsDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: _vm.detailsDialogWidths[_vm.detailsDialogStep] + "px",
                value: _vm.showDetailsDialog
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-0", attrs: { id: "departmentDetails" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-0 pb-0" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selectedDepartment.id
                                ? _vm.$t("labels.editDepartment")
                                : _vm.$t("labels.addDepartment")
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.selectedDepartment.name
                                ? " - " + _vm.selectedDepartment.name
                                : ""
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeDetailsDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0 pb-0" },
                    [
                      _c(
                        "v-form",
                        [
                          _c(
                            "v-stepper",
                            {
                              model: {
                                value: _vm.detailsDialogStep,
                                callback: function($$v) {
                                  _vm.detailsDialogStep = $$v
                                },
                                expression: "detailsDialogStep"
                              }
                            },
                            [
                              _c(
                                "v-stepper-header",
                                [
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: { color: "secondary", step: "1" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("labels.details")) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _vm.selectedDepartment.partakeInScheduling
                                    ? [
                                        _c("v-divider", {
                                          staticClass: "small"
                                        }),
                                        _c(
                                          "v-stepper-step",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              step: "2"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.jobTypeAndGroups"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _c("v-divider", {
                                          staticClass: "small"
                                        }),
                                        _c(
                                          "v-stepper-step",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              step: "3"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("labels.staffNeeded")
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _c("v-spacer")
                                ],
                                2
                              ),
                              _c(
                                "v-stepper-items",
                                [
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0 pt-0",
                                      attrs: { step: "1" }
                                    },
                                    [
                                      _c("ValidationObserver", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var invalid = ref.invalid
                                                var passes = ref.passes
                                                return [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticClass: "pt-0 px-6"
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "VeeTextField",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label: _vm.$t(
                                                                      "labels.name"
                                                                    ),
                                                                    name:
                                                                      "name",
                                                                    outlined:
                                                                      "",
                                                                    rules: {
                                                                      required: true,
                                                                      max: 64,
                                                                      excluded:
                                                                        _vm.namesInUse
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .selectedDepartment
                                                                        .name,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.selectedDepartment,
                                                                        "name",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "selectedDepartment.name"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "VeeTextField",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label: _vm.$t(
                                                                      "labels.costCenter"
                                                                    ),
                                                                    name:
                                                                      "costCenter",
                                                                    outlined:
                                                                      "",
                                                                    rules:
                                                                      "required|max:64"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .selectedDepartment
                                                                        .costCenter,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.selectedDepartment,
                                                                        "costCenter",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "selectedDepartment.costCenter"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "VeeTextField",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    label: _vm.$t(
                                                                      "labels.fullName"
                                                                    ),
                                                                    name:
                                                                      "fullName",
                                                                    outlined:
                                                                      "",
                                                                    rules:
                                                                      "required|max:256"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .selectedDepartment
                                                                        .fullName,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.selectedDepartment,
                                                                        "fullName",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "selectedDepartment.fullName"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "pt-0 mt-1 d-inline-block",
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  inset: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mr-4 body-2 grey--text text--darken-3",
                                                                              attrs: {
                                                                                title: _vm.$t(
                                                                                  "labels.involveInScheduling"
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "labels.involveInScheduling"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .selectedDepartment
                                                                      .partakeInScheduling,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.selectedDepartment,
                                                                      "partakeInScheduling",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "selectedDepartment.partakeInScheduling"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "pt-0 mt-1 d-inline-block",
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  inset: ""
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.onReceiveDailyCensusChange
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mr-4 body-2 grey--text text--darken-3",
                                                                              attrs: {
                                                                                title: _vm.$t(
                                                                                  "labels.receiveDailyCensus"
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "labels.receiveDailyCensus"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .selectedDepartment
                                                                      .receiveDailyCensus,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.selectedDepartment,
                                                                      "receiveDailyCensus",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "selectedDepartment.receiveDailyCensus"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "pt-0 mt-1 d-inline-block use-staffing-matrix",
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  dense: "",
                                                                  disabled: !_vm
                                                                    .selectedDepartment
                                                                    .receiveDailyCensus,
                                                                  "hide-details":
                                                                    "",
                                                                  inset: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "label",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "mr-4 body-2 grey--text text--darken-3",
                                                                              attrs: {
                                                                                title: _vm.$t(
                                                                                  "labels.useStaffingMatrix"
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "labels.useStaffingMatrix"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .selectedDepartment
                                                                      .useStaffingMatrix,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.selectedDepartment,
                                                                      "useStaffingMatrix",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "selectedDepartment.useStaffingMatrix"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-row",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _vm.selectedDepartment
                                                        .partakeInScheduling
                                                        ? [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "text-right pb-0 pt-4 px-8",
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-4",
                                                                    attrs: {
                                                                      text: ""
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.closeDetailsDialog
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.cancel"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "next",
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      disabled: invalid
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        passes(
                                                                          function() {
                                                                            return _vm.goToDetailsStep(
                                                                              2
                                                                            )
                                                                          }
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.next"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-spacer")
                                                          ]
                                                        : [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "text-right pb-0 pt-4 px-8",
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mr-4",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.saving,
                                                                      text: ""
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.closeDetailsDialog
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.cancel"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm
                                                                  .selectedDepartment
                                                                  .id
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "submit",
                                                                        attrs: {
                                                                          color:
                                                                            "secondary",
                                                                          disabled:
                                                                            invalid ||
                                                                            _vm.saving
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return passes(
                                                                              _vm.updateDetails
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm.saving
                                                                          ? _c(
                                                                              "v-progress-circular",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary lighten-2",
                                                                                  indeterminate:
                                                                                    "",
                                                                                  size:
                                                                                    "22",
                                                                                  width:
                                                                                    "2"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.save"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "submit",
                                                                        attrs: {
                                                                          color:
                                                                            "secondary",
                                                                          disabled:
                                                                            invalid ||
                                                                            _vm.saving
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            return passes(
                                                                              _vm.createDepartment
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm.saving
                                                                          ? _c(
                                                                              "v-progress-circular",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary lighten-2",
                                                                                  indeterminate:
                                                                                    "",
                                                                                  size:
                                                                                    "22",
                                                                                  width:
                                                                                    "2"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                            " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.add"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                          "
                                                                                )
                                                                              ]
                                                                            )
                                                                      ],
                                                                      1
                                                                    )
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-spacer")
                                                          ]
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1455133854
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0 pt-0",
                                      attrs: { step: "2" }
                                    },
                                    [
                                      _c("ValidationObserver", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var invalid = ref.invalid
                                                var passes = ref.passes
                                                return [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticClass: "pt-0 px-6",
                                                      attrs: { fluid: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "caption grey--text text--darken-1 pt-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "descriptions.departmentJobTypes"
                                                                            )
                                                                          ) +
                                                                          "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "descriptions.departmentJobTypesGroup"
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      ),
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            "max-width":
                                                                              "400px",
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "disclosure-hint",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "info",
                                                                                              "x-small":
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  fal fa-question-circle\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "descriptions.departmentJobTypesExample"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        "\n                            .\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "descriptions.departmentJobTypesDisplay"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "draggable",
                                                                {
                                                                  attrs: {
                                                                    draggable:
                                                                      ".job-types-group"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .selectedDepartment
                                                                        .settings
                                                                        .staffNeeded,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .selectedDepartment
                                                                          .settings,
                                                                        "staffNeeded",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "selectedDepartment.settings.staffNeeded"
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm
                                                                    .selectedDepartment
                                                                    .settings
                                                                    .staffNeeded,
                                                                  function(
                                                                    staffNeeded,
                                                                    idx
                                                                  ) {
                                                                    return _c(
                                                                      "v-row",
                                                                      {
                                                                        key: idx,
                                                                        staticClass:
                                                                          "mb-1 job-types-group",
                                                                        attrs: {
                                                                          dense:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "dnd",
                                                                            attrs: {
                                                                              cols:
                                                                                "1"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "grey",
                                                                                  small:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                fas fa-grip-vertical\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "6"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "VeeSelect",
                                                                              {
                                                                                attrs: {
                                                                                  dense:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                  "item-text":
                                                                                    "label",
                                                                                  "item-value":
                                                                                    "value",
                                                                                  items: _vm.getAvailableJobTypes(
                                                                                    idx
                                                                                  ),
                                                                                  label: _vm.$t(
                                                                                    "labels.jobTypeGroup"
                                                                                  ),
                                                                                  "menu-props": {
                                                                                    top: false,
                                                                                    offsetY: true
                                                                                  },
                                                                                  multiple:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  rules:
                                                                                    "required",
                                                                                  "small-chips":
                                                                                    "",
                                                                                  vid:
                                                                                    "jobTypes" +
                                                                                    idx
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    staffNeeded.jobTypes,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      staffNeeded,
                                                                                      "jobTypes",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "staffNeeded.jobTypes"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "4"
                                                                            }
                                                                          },
                                                                          [
                                                                            staffNeeded
                                                                              .display
                                                                              .dailySchedule
                                                                              .groupRight
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticClass:
                                                                                      "daily-schedule-display",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "nb-azure",
                                                                                      disabled:
                                                                                        idx ===
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .staffNeeded
                                                                                          .length -
                                                                                          1,
                                                                                      elevation:
                                                                                        "0",
                                                                                      small:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        staffNeeded.display.dailySchedule.groupRight = false
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "secondary",
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  fal fa-check\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "secondary--text"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.combinedJobTypes"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    staticClass:
                                                                                      "daily-schedule-display",
                                                                                    attrs: {
                                                                                      disabled:
                                                                                        idx ===
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .staffNeeded
                                                                                          .length -
                                                                                          1,
                                                                                      elevation:
                                                                                        "0",
                                                                                      small:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        staffNeeded.display.dailySchedule.groupRight = true
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs: {
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  fal fa-level-down\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        class:
                                                                                          idx ===
                                                                                          _vm
                                                                                            .selectedDepartment
                                                                                            .settings
                                                                                            .staffNeeded
                                                                                            .length -
                                                                                            1
                                                                                            ? ""
                                                                                            : "grey--text text--darken-3"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.combineJobTypes"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "1"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  color:
                                                                                    "secondary",
                                                                                  icon:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.removeJobType(
                                                                                      idx
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "error",
                                                                                      small:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                  fal fa-trash-alt\n                                "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mb-4",
                                                                  attrs: {
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "caption secondary--text add",
                                                                          on: {
                                                                            click:
                                                                              _vm.addJobTypeGroup
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              + " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.addJobType"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticClass:
                                                                            "lighten-4 pa-4",
                                                                          attrs: {
                                                                            color:
                                                                              "grey",
                                                                            flat:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "body-2 grey--text text--darken-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "labels.previewScheduleJobTypes"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "caption grey--text text--darken-1 my-3"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "descriptions.previewScheduleJobTypes"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .jobTypesCombinedForSelectedDepartment
                                                                            .length >
                                                                          0
                                                                            ? _c(
                                                                                "v-tabs",
                                                                                {
                                                                                  ref:
                                                                                    "dailyTabs",
                                                                                  staticClass:
                                                                                    "job-types",
                                                                                  attrs: {
                                                                                    "show-arrows":
                                                                                      "",
                                                                                    "slider-color":
                                                                                      "accent",
                                                                                    "slider-size":
                                                                                      "3"
                                                                                  }
                                                                                },
                                                                                _vm._l(
                                                                                  _vm.jobTypesCombinedForSelectedDepartment,
                                                                                  function(
                                                                                    jobTypeGroup,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-tab",
                                                                                      {
                                                                                        key: index
                                                                                      },
                                                                                      [
                                                                                        _vm._l(
                                                                                          jobTypeGroup,
                                                                                          function(
                                                                                            jobType,
                                                                                            jobIndex
                                                                                          ) {
                                                                                            return [
                                                                                              jobIndex >
                                                                                              0
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      key:
                                                                                                        jobType.id +
                                                                                                        "-plus",
                                                                                                      staticClass:
                                                                                                        "ml-2"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                      +\n                                    "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  key:
                                                                                                    jobType.id +
                                                                                                    "-name",
                                                                                                  class:
                                                                                                    jobIndex >
                                                                                                    0
                                                                                                      ? "ml-2"
                                                                                                      : ""
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                      " +
                                                                                                      _vm._s(
                                                                                                        jobType.name
                                                                                                      ) +
                                                                                                      "\n                                    "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      2
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                1
                                                                              )
                                                                            : _c(
                                                                                "v-container",
                                                                                {
                                                                                  staticClass:
                                                                                    "preview"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "labels.previewEmpty"
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-row",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-left pb-0 pt-4 px-8",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                disabled:
                                                                  invalid ||
                                                                  _vm.saving,
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.goToDetailsStep(
                                                                    1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.back"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right pb-0 pt-4 px-8",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                text: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeDetailsDialog
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.cancel"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "next",
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                disabled:
                                                                  invalid ||
                                                                  _vm.invalidJobTypes
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  passes(
                                                                    function() {
                                                                      return _vm.goToDetailsStep(
                                                                        3
                                                                      )
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.next"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1887875977
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0 pt-0",
                                      attrs: { step: "3" }
                                    },
                                    [
                                      _c("ValidationObserver", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var invalid = ref.invalid
                                                var passes = ref.passes
                                                return [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticClass: "pt-0 px-6",
                                                      attrs: { fluid: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _vm.selfScheduleBidForOpening
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text text-darken-1 caption mb-4"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "descriptions.bidsAllowed"
                                                                            )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "staff-needed-container",
                                                                  attrs: {
                                                                    outlined: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tabs",
                                                                    {
                                                                      staticClass:
                                                                        "staff-needed-count",
                                                                      attrs: {
                                                                        "hide-slider":
                                                                          "",
                                                                        vertical:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        _vm
                                                                          .selectedDepartment
                                                                          .settings
                                                                          .staffNeeded,
                                                                        function(
                                                                          staffNeeded,
                                                                          idx
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "v-tab",
                                                                              {
                                                                                key:
                                                                                  "label" +
                                                                                  idx,
                                                                                attrs: {
                                                                                  href:
                                                                                    "#jobType" +
                                                                                    idx
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                " +
                                                                                    _vm._s(
                                                                                      _vm.getJobTypeName(
                                                                                        staffNeeded
                                                                                      )
                                                                                    ) +
                                                                                    "\n                              "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-tab-item",
                                                                              {
                                                                                key:
                                                                                  "content" +
                                                                                  idx,
                                                                                attrs: {
                                                                                  value:
                                                                                    "jobType" +
                                                                                    idx,
                                                                                  eager:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-container",
                                                                                  {
                                                                                    staticClass:
                                                                                      "shift-types-needed"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        attrs: {
                                                                                          dense:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          {
                                                                                            attrs: {
                                                                                              cols: _vm.selfScheduleBidForOpening
                                                                                                ? "6"
                                                                                                : "12"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                      " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "labels.staffNeeded"
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                    "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm.selfScheduleBidForOpening
                                                                                          ? _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                attrs: {
                                                                                                  cols:
                                                                                                    "6"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                      " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "labels.bidsAllowed"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                    "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._l(
                                                                                      _vm.getShiftTypes(
                                                                                        staffNeeded
                                                                                      ),
                                                                                      function(
                                                                                        shiftType
                                                                                      ) {
                                                                                        return _c(
                                                                                          "v-row",
                                                                                          {
                                                                                            key:
                                                                                              shiftType.id
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-col",
                                                                                              {
                                                                                                attrs: {
                                                                                                  cols: _vm.selfScheduleBidForOpening
                                                                                                    ? "6"
                                                                                                    : "12"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "VeeTextField",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "d-inline-block count",
                                                                                                    staticStyle: {
                                                                                                      width:
                                                                                                        "100px"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      dense:
                                                                                                        "",
                                                                                                      "hide-details":
                                                                                                        "",
                                                                                                      label:
                                                                                                        shiftType.name,
                                                                                                      outlined:
                                                                                                        "",
                                                                                                      rules: {
                                                                                                        required: true,
                                                                                                        numeric: true,
                                                                                                        min_value: 0
                                                                                                      },
                                                                                                      vid:
                                                                                                        idx +
                                                                                                        "." +
                                                                                                        shiftType.id
                                                                                                    },
                                                                                                    on: {
                                                                                                      input: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.updateMaxStaffNeeded(
                                                                                                          idx,
                                                                                                          shiftType.id
                                                                                                        )
                                                                                                      }
                                                                                                    },
                                                                                                    model: {
                                                                                                      value:
                                                                                                        staffNeeded
                                                                                                          .shiftTypes[
                                                                                                          shiftType
                                                                                                            .id
                                                                                                        ],
                                                                                                      callback: function(
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          staffNeeded.shiftTypes,
                                                                                                          shiftType.id,
                                                                                                          _vm._n(
                                                                                                            $$v
                                                                                                          )
                                                                                                        )
                                                                                                      },
                                                                                                      expression:
                                                                                                        "staffNeeded.shiftTypes[shiftType.id]"
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _vm.selfScheduleBidForOpening
                                                                                              ? _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      cols:
                                                                                                        "6"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "VeeTextField",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "d-inline-block count",
                                                                                                        staticStyle: {
                                                                                                          width:
                                                                                                            "100px"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          dense:
                                                                                                            "",
                                                                                                          "hide-details":
                                                                                                            "",
                                                                                                          label:
                                                                                                            shiftType.name,
                                                                                                          outlined:
                                                                                                            "",
                                                                                                          rules: {
                                                                                                            required: true,
                                                                                                            numeric: true,
                                                                                                            min_value:
                                                                                                              staffNeeded
                                                                                                                .shiftTypes[
                                                                                                                shiftType
                                                                                                                  .id
                                                                                                              ]
                                                                                                          },
                                                                                                          vid:
                                                                                                            idx +
                                                                                                            "." +
                                                                                                            shiftType.id
                                                                                                        },
                                                                                                        model: {
                                                                                                          value:
                                                                                                            staffNeeded
                                                                                                              .maxShiftTypes[
                                                                                                              shiftType
                                                                                                                .id
                                                                                                            ],
                                                                                                          callback: function(
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.$set(
                                                                                                              staffNeeded.maxShiftTypes,
                                                                                                              shiftType.id,
                                                                                                              _vm._n(
                                                                                                                $$v
                                                                                                              )
                                                                                                            )
                                                                                                          },
                                                                                                          expression:
                                                                                                            "staffNeeded.maxShiftTypes[shiftType.id]"
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _vm._e()
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  2
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-row",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-left pb-0 pt-4 px-8",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                disabled:
                                                                  invalid ||
                                                                  _vm.saving,
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.goToDetailsStep(
                                                                    2
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.back"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right pb-0 pt-4 px-8",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.saving,
                                                                text: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeDetailsDialog
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.cancel"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _vm.selectedDepartment
                                                            .id
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "submit",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    disabled:
                                                                      invalid ||
                                                                      _vm.saving
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return passes(
                                                                        _vm.updateDetails
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm.saving
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary lighten-2",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.save"
                                                                                )
                                                                              ) +
                                                                              "\n                        "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "submit",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    disabled:
                                                                      invalid ||
                                                                      _vm.saving
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return passes(
                                                                        _vm.createDepartment
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm.saving
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "primary lighten-2",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.add"
                                                                                )
                                                                              ) +
                                                                              "\n                        "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3639219295
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showCensusDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: _vm.censusDialogWidths[_vm.censusDialogStep] + "px",
                value: _vm.showCensusDialog
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-0", attrs: { id: "departmentCensus" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-0 pb-0" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.editDepartmentCensus")) +
                            "\n          " +
                            _vm._s(" - " + _vm.selectedDepartment.name) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeCensusDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0 pb-0" },
                    [
                      _c(
                        "v-form",
                        [
                          _c(
                            "v-stepper",
                            {
                              model: {
                                value: _vm.censusDialogStep,
                                callback: function($$v) {
                                  _vm.censusDialogStep = $$v
                                },
                                expression: "censusDialogStep"
                              }
                            },
                            [
                              _c(
                                "v-stepper-header",
                                [
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: { color: "secondary", step: "1" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("labels.acuity")) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-divider", { staticClass: "small" }),
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: { color: "secondary", step: "2" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("labels.specialty")) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-items",
                                [
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0 pt-0",
                                      attrs: { step: "1" }
                                    },
                                    [
                                      _c("ValidationObserver", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var invalid = ref.invalid
                                                var passes = ref.passes
                                                return [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticClass: "pt-0 px-6"
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      staticClass:
                                                                        "py-0",
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 acuity-select",
                                                                          attrs: {
                                                                            dense:
                                                                              "",
                                                                            items: [
                                                                              {
                                                                                text:
                                                                                  "1",
                                                                                value: 1
                                                                              },
                                                                              {
                                                                                text:
                                                                                  "2",
                                                                                value: 2
                                                                              },
                                                                              {
                                                                                text:
                                                                                  "3",
                                                                                value: 3
                                                                              },
                                                                              {
                                                                                text:
                                                                                  "4",
                                                                                value: 4
                                                                              },
                                                                              {
                                                                                text:
                                                                                  "5",
                                                                                value: 5
                                                                              }
                                                                            ],
                                                                            label: _vm.$t(
                                                                              "labels.acuity"
                                                                            ),
                                                                            outlined:
                                                                              "",
                                                                            value:
                                                                              _vm
                                                                                .selectedDepartment
                                                                                .settings
                                                                                .acuity
                                                                                .length
                                                                          },
                                                                          on: {
                                                                            change:
                                                                              _vm.updateAcuityCount
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._l(
                                                                _vm
                                                                  .selectedDepartment
                                                                  .settings
                                                                  .acuity,
                                                                function(
                                                                  acuityClass
                                                                ) {
                                                                  return _c(
                                                                    "v-row",
                                                                    {
                                                                      key:
                                                                        acuityClass.acuity,
                                                                      attrs: {
                                                                        dense:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "py-0",
                                                                          attrs: {
                                                                            cols:
                                                                              "12"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "VeeTextField",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  "",
                                                                                label: _vm.$t(
                                                                                  "labels.acuityClassification",
                                                                                  {
                                                                                    acuity:
                                                                                      acuityClass.acuity
                                                                                  }
                                                                                ),
                                                                                name:
                                                                                  "class" +
                                                                                  acuityClass.acuity,
                                                                                outlined:
                                                                                  "",
                                                                                rules:
                                                                                  "required|max:64"
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  acuityClass
                                                                                    .value
                                                                                    .label,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    acuityClass.value,
                                                                                    "label",
                                                                                    typeof $$v ===
                                                                                      "string"
                                                                                      ? $$v.trim()
                                                                                      : $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "acuityClass.value.label"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  attrs: {
                                                                    dense: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0 mt-1 d-inline-block",
                                                                          attrs: {
                                                                            color:
                                                                              "success",
                                                                            dense:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            inset:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "label",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-4 body-2 grey--text text--darken-3",
                                                                                        attrs: {
                                                                                          title: _vm.$t(
                                                                                            "labels.collectCensusAcuity"
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "labels.collectCensusAcuity"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .selectedDepartment
                                                                                .settings
                                                                                .showTotalAcuityByClass,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .selectedDepartment
                                                                                  .settings,
                                                                                "showTotalAcuityByClass",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "selectedDepartment.settings.showTotalAcuityByClass"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "6"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "lighten-4 pa-4 acuity",
                                                                  attrs: {
                                                                    color:
                                                                      "grey",
                                                                    flat: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "body-2 grey--text text--darken-2 mb-4"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.preview"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm
                                                                    .selectedDepartment
                                                                    .settings
                                                                    .showTotalAcuityByClass
                                                                    ? [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs: {
                                                                              "no-gutters":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption row-label"
                                                                              }
                                                                            ),
                                                                            _vm._l(
                                                                              _vm
                                                                                .selectedDepartment
                                                                                .settings
                                                                                .acuity,
                                                                              function(
                                                                                acuity,
                                                                                key
                                                                              ) {
                                                                                return _c(
                                                                                  "span",
                                                                                  {
                                                                                    key: key,
                                                                                    staticClass:
                                                                                      "caption header text-truncate"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          acuity
                                                                                            .value
                                                                                            .label
                                                                                        ) +
                                                                                        "\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              }
                                                                            )
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            staticClass:
                                                                              "mb-2",
                                                                            attrs: {
                                                                              "no-gutters":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption row-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.acuity"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                              "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._l(
                                                                              _vm
                                                                                .selectedDepartment
                                                                                .settings
                                                                                .acuity,
                                                                              function(
                                                                                acuity,
                                                                                key
                                                                              ) {
                                                                                return _c(
                                                                                  "v-text-field",
                                                                                  {
                                                                                    key: key,
                                                                                    staticClass:
                                                                                      "acuity-input",
                                                                                    attrs: {
                                                                                      dense:
                                                                                        "",
                                                                                      disabled:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      outlined:
                                                                                        ""
                                                                                    }
                                                                                  }
                                                                                )
                                                                              }
                                                                            )
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            staticClass:
                                                                              "mb-3",
                                                                            attrs: {
                                                                              "no-gutters":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "caption row-label"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.total"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                              "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-text-field",
                                                                              {
                                                                                staticClass:
                                                                                  "acuity-input",
                                                                                attrs: {
                                                                                  dense:
                                                                                    "",
                                                                                  disabled:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                  outlined:
                                                                                    ""
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    : _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "grey--text text--darken-2 caption"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "descriptions.collectCensusAcuityOff"
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-row",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right pb-0 pt-4 px-8",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                text: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeCensusDialog
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.cancel"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "next",
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                disabled: invalid
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  passes(
                                                                    function() {
                                                                      return _vm.goToCensusStep(
                                                                        2
                                                                      )
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.next"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          56436412
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    {
                                      staticClass: "px-0 pt-0",
                                      attrs: { step: "2" }
                                    },
                                    [
                                      _c("ValidationObserver", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var invalid = ref.invalid
                                                var passes = ref.passes
                                                return [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticClass:
                                                        "pt-0 px-6 specialty",
                                                      attrs: { fluid: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "7"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "pa-0",
                                                                  attrs: {
                                                                    outlined: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "pa-4 list",
                                                                          attrs: {
                                                                            cols:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-4"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                  " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "labels.list"
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm
                                                                                .selectedDepartment
                                                                                .settings
                                                                                .censusBySpecialty
                                                                                .length <
                                                                              50
                                                                                ? _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "caption secondary--text add-specialty float-right",
                                                                                      on: {
                                                                                        click:
                                                                                          _vm.addSpecialty
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                  + " +
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "labels.specialty"
                                                                                            )
                                                                                          ) +
                                                                                          "\n                                "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-list",
                                                                            {
                                                                              attrs: {
                                                                                dense:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            _vm._l(
                                                                              _vm
                                                                                .selectedDepartment
                                                                                .settings
                                                                                .censusBySpecialty,
                                                                              function(
                                                                                specialty,
                                                                                idx
                                                                              ) {
                                                                                return _c(
                                                                                  "v-list-item",
                                                                                  {
                                                                                    key: idx,
                                                                                    class: [
                                                                                      "pl-3 pr-1",
                                                                                      _vm.selectedSpecialtyIdx ===
                                                                                      idx
                                                                                        ? "active"
                                                                                        : ""
                                                                                    ],
                                                                                    attrs: {
                                                                                      dense:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.selectedSpecialtyIdx = idx
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item-content",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "grey--text text--darken-3 body-2 d-inline-block text-truncate",
                                                                                            staticStyle: {
                                                                                              width:
                                                                                                "100px"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                      " +
                                                                                                _vm._s(
                                                                                                  specialty.name
                                                                                                ) +
                                                                                                "\n                                    "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item-action",
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              icon:
                                                                                                ""
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.removeSpecialty(
                                                                                                  idx
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "error",
                                                                                                  small:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                        fal fa-trash-alt\n                                      "
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "pa-4",
                                                                          attrs: {
                                                                            cols:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .selectedDepartment
                                                                            .settings
                                                                            .censusBySpecialty[
                                                                            _vm
                                                                              .selectedSpecialtyIdx
                                                                          ]
                                                                            ? [
                                                                                _c(
                                                                                  "VeeTextField",
                                                                                  {
                                                                                    staticClass:
                                                                                      "specialty-name",
                                                                                    attrs: {
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      label: _vm.$t(
                                                                                        "labels.specialty"
                                                                                      ),
                                                                                      name:
                                                                                        "specialty" +
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .censusBySpecialty[
                                                                                          _vm
                                                                                            .selectedSpecialtyIdx
                                                                                        ]
                                                                                          .name,
                                                                                      outlined:
                                                                                        "",
                                                                                      rules:
                                                                                        "required|max:64"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .censusBySpecialty[
                                                                                          _vm
                                                                                            .selectedSpecialtyIdx
                                                                                        ]
                                                                                          .name,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .selectedDepartment
                                                                                            .settings
                                                                                            .censusBySpecialty[
                                                                                            _vm
                                                                                              .selectedSpecialtyIdx
                                                                                          ],
                                                                                          "name",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].name"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-switch",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pt-0 mt-5 d-inline-block",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "success",
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      inset:
                                                                                        ""
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "label",
                                                                                          fn: function() {
                                                                                            return [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-4 body-2 grey--text text--darken-3",
                                                                                                  attrs: {
                                                                                                    title: _vm.$t(
                                                                                                      "labels.breakdownByAcuity"
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                      " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "labels.breakdownByAcuity"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                    "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          },
                                                                                          proxy: true
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .censusBySpecialty[
                                                                                          _vm
                                                                                            .selectedSpecialtyIdx
                                                                                        ]
                                                                                          .acuityBreakdown,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .selectedDepartment
                                                                                            .settings
                                                                                            .censusBySpecialty[
                                                                                            _vm
                                                                                              .selectedSpecialtyIdx
                                                                                          ],
                                                                                          "acuityBreakdown",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].acuityBreakdown"
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _c(
                                                                                  "v-switch",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pt-0 mt-1 d-inline-block",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "success",
                                                                                      dense:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "",
                                                                                      inset:
                                                                                        ""
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "label",
                                                                                          fn: function() {
                                                                                            return [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-4 body-2 grey--text text--darken-3",
                                                                                                  attrs: {
                                                                                                    title: _vm.$t(
                                                                                                      "labels.showSubtotal"
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                      " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "labels.showSubtotal"
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                    "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          },
                                                                                          proxy: true
                                                                                        }
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .censusBySpecialty[
                                                                                          _vm
                                                                                            .selectedSpecialtyIdx
                                                                                        ]
                                                                                          .showAcuitySubtotal,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm
                                                                                            .selectedDepartment
                                                                                            .settings
                                                                                            .censusBySpecialty[
                                                                                            _vm
                                                                                              .selectedSpecialtyIdx
                                                                                          ],
                                                                                          "showAcuitySubtotal",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "selectedDepartment.settings.censusBySpecialty[selectedSpecialtyIdx].showAcuitySubtotal"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ]
                                                                            : _vm._e()
                                                                        ],
                                                                        2
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "specialty-preview pl-4",
                                                              attrs: {
                                                                cols: "5"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "lighten-4 pa-4 acuity",
                                                                  attrs: {
                                                                    color:
                                                                      "grey",
                                                                    flat: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        dense:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "12"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-2 grey--text text--darken-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "labels.preview"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-switch",
                                                                            {
                                                                              staticClass:
                                                                                "mt-n1 float-right",
                                                                              attrs: {
                                                                                color:
                                                                                  "success",
                                                                                dense:
                                                                                  "",
                                                                                "hide-details":
                                                                                  "",
                                                                                inset:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "label",
                                                                                    fn: function() {
                                                                                      return [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mr-4 body-2 grey--text text--darken-3",
                                                                                            attrs: {
                                                                                              title: _vm.$t(
                                                                                                "labels.showAllSpecialties"
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "labels.showAllSpecialties"
                                                                                                  )
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    },
                                                                                    proxy: true
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                              model: {
                                                                                value:
                                                                                  _vm.showAllSpecialties,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.showAllSpecialties = $$v
                                                                                },
                                                                                expression:
                                                                                  "showAllSpecialties"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-container",
                                                                            {
                                                                              staticClass:
                                                                                "pa-0 mt-2"
                                                                            },
                                                                            [
                                                                              _vm.showAllSpecialties
                                                                                ? _vm._l(
                                                                                    _vm
                                                                                      .selectedDepartment
                                                                                      .settings
                                                                                      .censusBySpecialty,
                                                                                    function(
                                                                                      specialty,
                                                                                      idx
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-container",
                                                                                        {
                                                                                          key:
                                                                                            specialty.name,
                                                                                          staticClass:
                                                                                            "pa-0"
                                                                                        },
                                                                                        [
                                                                                          specialty.acuityBreakdown
                                                                                            ? [
                                                                                                _c(
                                                                                                  "v-row",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "px-1 pt-3 pb-0",
                                                                                                    staticStyle: {
                                                                                                      width:
                                                                                                        "100%"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      dense:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "caption row-label"
                                                                                                      }
                                                                                                    ),
                                                                                                    _vm._l(
                                                                                                      _vm
                                                                                                        .selectedDepartment
                                                                                                        .settings
                                                                                                        .acuity,
                                                                                                      function(
                                                                                                        acuity
                                                                                                      ) {
                                                                                                        return _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            key:
                                                                                                              acuity.acuity,
                                                                                                            staticClass:
                                                                                                              "caption header text-truncate"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "\n                                          " +
                                                                                                                _vm._s(
                                                                                                                  acuity
                                                                                                                    .value
                                                                                                                    .label
                                                                                                                ) +
                                                                                                                "\n                                        "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  2
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-row",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "px-1 pt-3 pb-0",
                                                                                                    attrs: {
                                                                                                      dense:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "caption row-label text-truncate"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            specialty.name
                                                                                                          )
                                                                                                        )
                                                                                                      ]
                                                                                                    ),
                                                                                                    _vm._l(
                                                                                                      _vm
                                                                                                        .selectedDepartment
                                                                                                        .settings
                                                                                                        .acuity,
                                                                                                      function(
                                                                                                        acuity
                                                                                                      ) {
                                                                                                        return _c(
                                                                                                          "v-text-field",
                                                                                                          {
                                                                                                            key:
                                                                                                              acuity.acuity,
                                                                                                            staticClass:
                                                                                                              "acuity-input",
                                                                                                            attrs: {
                                                                                                              dense:
                                                                                                                "",
                                                                                                              disabled:
                                                                                                                "",
                                                                                                              "hide-details":
                                                                                                                "",
                                                                                                              outlined:
                                                                                                                ""
                                                                                                            }
                                                                                                          }
                                                                                                        )
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  2
                                                                                                )
                                                                                              ]
                                                                                            : _vm._e(),
                                                                                          specialty.showAcuitySubtotal
                                                                                            ? _c(
                                                                                                "v-row",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "px-1 pt-3 pb-0",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "caption row-label text-truncate"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "\n                                        " +
                                                                                                          _vm._s(
                                                                                                            specialty.acuityBreakdown
                                                                                                              ? _vm.$t(
                                                                                                                  "labels.subtotal"
                                                                                                                )
                                                                                                              : specialty.name
                                                                                                          ) +
                                                                                                          "\n                                      "
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-text-field",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "acuity-input",
                                                                                                      attrs: {
                                                                                                        dense:
                                                                                                          "",
                                                                                                        disabled:
                                                                                                          "",
                                                                                                        "hide-details":
                                                                                                          "",
                                                                                                        outlined:
                                                                                                          ""
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          idx !==
                                                                                          _vm
                                                                                            .selectedDepartment
                                                                                            .settings
                                                                                            .censusBySpecialty
                                                                                            .length -
                                                                                            1
                                                                                            ? _c(
                                                                                                "v-divider",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mt-2"
                                                                                                }
                                                                                              )
                                                                                            : _vm._e()
                                                                                        ],
                                                                                        2
                                                                                      )
                                                                                    }
                                                                                  )
                                                                                : [
                                                                                    _c(
                                                                                      "v-container",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pa-0"
                                                                                      },
                                                                                      [
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .censusBySpecialty[
                                                                                          _vm
                                                                                            .selectedSpecialtyIdx
                                                                                        ]
                                                                                          .acuityBreakdown
                                                                                          ? [
                                                                                              _c(
                                                                                                "v-row",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "px-1 pt-3 pb-0",
                                                                                                  staticStyle: {
                                                                                                    width:
                                                                                                      "100%"
                                                                                                  },
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "caption row-label"
                                                                                                    }
                                                                                                  ),
                                                                                                  _vm._l(
                                                                                                    _vm
                                                                                                      .selectedDepartment
                                                                                                      .settings
                                                                                                      .acuity,
                                                                                                    function(
                                                                                                      acuity
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          key:
                                                                                                            acuity.acuity,
                                                                                                          staticClass:
                                                                                                            "caption header text-truncate"
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                          " +
                                                                                                              _vm._s(
                                                                                                                acuity
                                                                                                                  .value
                                                                                                                  .label
                                                                                                              ) +
                                                                                                              "\n                                        "
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                2
                                                                                              ),
                                                                                              _c(
                                                                                                "v-row",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "px-1 pt-3 pb-0",
                                                                                                  attrs: {
                                                                                                    dense:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "caption row-label text-truncate"
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .selectedDepartment
                                                                                                            .settings
                                                                                                            .censusBySpecialty[
                                                                                                            _vm
                                                                                                              .selectedSpecialtyIdx
                                                                                                          ]
                                                                                                            .name
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._l(
                                                                                                    _vm
                                                                                                      .selectedDepartment
                                                                                                      .settings
                                                                                                      .acuity,
                                                                                                    function(
                                                                                                      acuity
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "v-text-field",
                                                                                                        {
                                                                                                          key:
                                                                                                            acuity.acuity,
                                                                                                          staticClass:
                                                                                                            "acuity-input",
                                                                                                          attrs: {
                                                                                                            dense:
                                                                                                              "",
                                                                                                            disabled:
                                                                                                              "",
                                                                                                            "hide-details":
                                                                                                              "",
                                                                                                            outlined:
                                                                                                              ""
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    }
                                                                                                  )
                                                                                                ],
                                                                                                2
                                                                                              )
                                                                                            ]
                                                                                          : _vm._e(),
                                                                                        _vm
                                                                                          .selectedDepartment
                                                                                          .settings
                                                                                          .censusBySpecialty[
                                                                                          _vm
                                                                                            .selectedSpecialtyIdx
                                                                                        ]
                                                                                          .showAcuitySubtotal
                                                                                          ? _c(
                                                                                              "v-row",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "px-1 pt-3 pb-0",
                                                                                                attrs: {
                                                                                                  dense:
                                                                                                    ""
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "caption row-label text-truncate"
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                        " +
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .selectedDepartment
                                                                                                            .settings
                                                                                                            .censusBySpecialty[
                                                                                                            _vm
                                                                                                              .selectedSpecialtyIdx
                                                                                                          ]
                                                                                                            .acuityBreakdown
                                                                                                            ? _vm.$t(
                                                                                                                "labels.subtotal"
                                                                                                              )
                                                                                                            : _vm
                                                                                                                .selectedDepartment
                                                                                                                .settings
                                                                                                                .censusBySpecialty[
                                                                                                                _vm
                                                                                                                  .selectedSpecialtyIdx
                                                                                                              ]
                                                                                                                .name
                                                                                                        ) +
                                                                                                        "\n                                      "
                                                                                                    )
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-text-field",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "acuity-input",
                                                                                                    attrs: {
                                                                                                      dense:
                                                                                                        "",
                                                                                                      disabled:
                                                                                                        "",
                                                                                                      "hide-details":
                                                                                                        "",
                                                                                                      outlined:
                                                                                                        ""
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      2
                                                                                    )
                                                                                  ]
                                                                            ],
                                                                            2
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-row",
                                                    { attrs: { dense: "" } },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-left pb-0 pt-4 px-8",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                disabled:
                                                                  invalid ||
                                                                  _vm.saving ||
                                                                  _vm.invalidSpecialties,
                                                                text: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.goToCensusStep(
                                                                    1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.back"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right pb-0 pt-4 px-8",
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mr-4",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.saving,
                                                                text: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.closeCensusDialog
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.cancel"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "submit",
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                disabled:
                                                                  invalid ||
                                                                  _vm.saving ||
                                                                  _vm.invalidSpecialties
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                  return passes(
                                                                    _vm.updateCensus
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm.saving
                                                                ? _c(
                                                                    "v-progress-circular",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "primary lighten-2",
                                                                        indeterminate:
                                                                          "",
                                                                        size:
                                                                          "22",
                                                                        width:
                                                                          "2"
                                                                      }
                                                                    }
                                                                  )
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.save"
                                                                          )
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2244069924
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showStaffingMatrixDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: "560px",
                value: _vm.showStaffingMatrixDialog
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-0",
                  attrs: { id: "departmentStaffingMatrix" }
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-0 pb-0" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.editStaffingMatrix")) +
                            "\n          " +
                            _vm._s(
                              _vm.selectedDepartment.name
                                ? " - " + _vm.selectedDepartment.name
                                : ""
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeStaffingMatrixDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0 pb-0" },
                    [
                      _c(
                        "v-form",
                        [
                          _c(
                            "v-container",
                            { staticClass: "pt-0 px-6" },
                            [
                              _c(
                                "v-tabs",
                                {
                                  staticClass: "job-types",
                                  attrs: {
                                    "show-arrows": "",
                                    "slider-color": "accent",
                                    "slider-size": "3"
                                  },
                                  on: { change: _vm.onStaffingMatrixChange },
                                  model: {
                                    value: _vm.selectedStaffingMatrixIdx,
                                    callback: function($$v) {
                                      _vm.selectedStaffingMatrixIdx = $$v
                                    },
                                    expression: "selectedStaffingMatrixIdx"
                                  }
                                },
                                _vm._l(
                                  _vm.jobTypesForSelectedDepartment,
                                  function(jobType, idx) {
                                    return _c(
                                      "v-tab",
                                      { key: jobType.id },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(jobType.name) +
                                            "\n                "
                                        ),
                                        _vm.invalidStaffingMatrix[idx]
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "error--text ml-1 mr-1",
                                                attrs: { "x-small": "" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fas fa-exclamation-circle\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "my-2",
                                      attrs: { dense: "" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "pt-0 mt-1 d-inline-block",
                                            attrs: {
                                              color: "success",
                                              dense: "",
                                              "hide-details": "",
                                              inset: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "mr-4 body-2 grey--text text--darken-3",
                                                          attrs: {
                                                            title: _vm.$t(
                                                              "labels.useSameStaffingMatrix"
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.useSameStaffingMatrix"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              3877841512
                                            ),
                                            model: {
                                              value:
                                                _vm.staffingMatrixOptions
                                                  .sameAcrossAllShifts,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.staffingMatrixOptions,
                                                  "sameAcrossAllShifts",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "staffingMatrixOptions.sameAcrossAllShifts"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("ValidationObserver", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var invalid = ref.invalid
                                            var passes = ref.passes
                                            return [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "my-2",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "py-0 font-weight-medium grey--text text--darken-3",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.censusRange"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-2",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c("VeeTextField", {
                                                        staticClass:
                                                          "census-start",
                                                        attrs: {
                                                          dense: "",
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "labels.from"
                                                          ),
                                                          name:
                                                            "censusRangeStart",
                                                          outlined: "",
                                                          rules:
                                                            "required|numeric|min_value:1"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .staffingMatrixOptions
                                                              .censusRange
                                                              .start,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .staffingMatrixOptions
                                                                .censusRange,
                                                              "start",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "staffingMatrixOptions.censusRange.start"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c("VeeTextField", {
                                                        staticClass:
                                                          "census-end",
                                                        attrs: {
                                                          dense: "",
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "labels.to"
                                                          ),
                                                          name:
                                                            "censusRangeEnd",
                                                          outlined: "",
                                                          rules: {
                                                            required: true,
                                                            numeric: true,
                                                            min_value: {
                                                              min:
                                                                _vm
                                                                  .staffingMatrixOptions
                                                                  .censusRange
                                                                  .start
                                                            }
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .staffingMatrixOptions
                                                              .censusRange.end,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .staffingMatrixOptions
                                                                .censusRange,
                                                              "end",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "staffingMatrixOptions.censusRange.end"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c("VeeSelect", {
                                                        staticClass: "interval",
                                                        attrs: {
                                                          dense: "",
                                                          "hide-details": "",
                                                          items: [
                                                            {
                                                              text: "1",
                                                              value: 1
                                                            },
                                                            {
                                                              text: "2",
                                                              value: 2
                                                            },
                                                            {
                                                              text: "3",
                                                              value: 3
                                                            }
                                                          ],
                                                          label: _vm.$t(
                                                            "labels.interval"
                                                          ),
                                                          outlined: "",
                                                          rules: "required"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .staffingMatrixOptions
                                                              .censusRange
                                                              .interval,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .staffingMatrixOptions
                                                                .censusRange,
                                                              "interval",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "staffingMatrixOptions.censusRange.interval"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "apply-census-range",
                                                          attrs: {
                                                            color: "secondary",
                                                            disabled: invalid
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return passes(
                                                                _vm.updateCensusRange
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm
                                                                    .staffingMatrixData
                                                                    .length > 0
                                                                    ? _vm.$t(
                                                                        "labels.update"
                                                                      )
                                                                    : _vm.$t(
                                                                        "labels.apply"
                                                                      )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-0 mb-3",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "py-0 grey--text caption",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.changeCensusRange"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3142569682
                                    )
                                  }),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center grey lighten-4 shift-header py-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.shift")) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-data-table", {
                                    attrs: {
                                      dense: "",
                                      "fixed-header": "",
                                      headers: _vm.staffingMatrixHeaders,
                                      height: "444px",
                                      "hide-default-footer": "",
                                      "hide-default-header": "",
                                      items: _vm.staffingMatrixData,
                                      "items-per-page":
                                        _vm.staffingMatrixData.length,
                                      "mobile-breakpoint": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function(ref) {
                                            var props = ref.props
                                            return [
                                              _c("thead", [
                                                _c(
                                                  "tr",
                                                  _vm._l(
                                                    props.headers,
                                                    function(header) {
                                                      return _c(
                                                        "th",
                                                        {
                                                          key: header.value,
                                                          class: [
                                                            "text-start",
                                                            header.value
                                                          ],
                                                          style:
                                                            header.width === "*"
                                                              ? {
                                                                  height: "40px"
                                                                }
                                                              : {
                                                                  height:
                                                                    "40px",
                                                                  "min-width":
                                                                    header.width +
                                                                    "px",
                                                                  width:
                                                                    header.width +
                                                                    "px"
                                                                }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-3 font-weight-regular"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    header.text
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          header.value !==
                                                            "census" &&
                                                          _vm.staffingMatrixData
                                                            .length > 0
                                                            ? _c(
                                                                "v-menu",
                                                                {
                                                                  ref:
                                                                    "menu" +
                                                                    header.value,
                                                                  refInFor: true,
                                                                  attrs: {
                                                                    "close-on-content-click": false,
                                                                    "nudge-bottom":
                                                                      "32px"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var value =
                                                                            ref.value
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                {
                                                                                  class: [
                                                                                    value
                                                                                      ? "primary"
                                                                                      : "grey lighten-3",
                                                                                    "set-values"
                                                                                  ],
                                                                                  attrs: {
                                                                                    elevation:
                                                                                      "0",
                                                                                    icon:
                                                                                      "",
                                                                                    small:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    class: value
                                                                                      ? "white--text"
                                                                                      : "grey--text text--darken-3",
                                                                                    attrs: {
                                                                                      small:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                fal fa-sliders-h\n                              "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-container",
                                                                    {
                                                                      staticClass:
                                                                        "px-4",
                                                                      staticStyle: {
                                                                        "background-color":
                                                                          "white"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ValidationObserver",
                                                                        {
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "default",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var invalid =
                                                                                    ref.invalid
                                                                                  return [
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        staticClass:
                                                                                          "my-2",
                                                                                        attrs: {
                                                                                          dense:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "labels.updateCensusValue1"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "VeeTextField",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "census-start d-inline-block",
                                                                                                staticStyle: {
                                                                                                  width:
                                                                                                    "48px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  dense:
                                                                                                    "",
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                  name:
                                                                                                    "censusValue",
                                                                                                  placeholder:
                                                                                                    "#",
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    "required|numeric|min_value:0"
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .staffingMatrixOptions
                                                                                                      .valueRange
                                                                                                      .value,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .staffingMatrixOptions
                                                                                                        .valueRange,
                                                                                                      "value",
                                                                                                      _vm._n(
                                                                                                        $$v
                                                                                                      )
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "staffingMatrixOptions.valueRange.value"
                                                                                                }
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "labels.updateCensusValue2"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "VeeTextField",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "census-start d-inline-block",
                                                                                                staticStyle: {
                                                                                                  width:
                                                                                                    "48px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  dense:
                                                                                                    "",
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                  name:
                                                                                                    "censusValueStart",
                                                                                                  placeholder:
                                                                                                    "#",
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  rules:
                                                                                                    "required|numeric|min_value:1"
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .staffingMatrixOptions
                                                                                                      .valueRange
                                                                                                      .start,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .staffingMatrixOptions
                                                                                                        .valueRange,
                                                                                                      "start",
                                                                                                      _vm._n(
                                                                                                        $$v
                                                                                                      )
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "staffingMatrixOptions.valueRange.start"
                                                                                                }
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                    " +
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "labels.updateCensusValue3"
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                  "
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "VeeTextField",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "census-end d-inline-block",
                                                                                                staticStyle: {
                                                                                                  width:
                                                                                                    "48px"
                                                                                                },
                                                                                                attrs: {
                                                                                                  dense:
                                                                                                    "",
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                  name:
                                                                                                    "censusValueEnd",
                                                                                                  placeholder:
                                                                                                    "#",
                                                                                                  outlined:
                                                                                                    "",
                                                                                                  rules: {
                                                                                                    required: true,
                                                                                                    numeric: true,
                                                                                                    min_value: {
                                                                                                      min:
                                                                                                        _vm
                                                                                                          .staffingMatrixOptions
                                                                                                          .valueRange
                                                                                                          .start
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .staffingMatrixOptions
                                                                                                      .valueRange
                                                                                                      .end,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .staffingMatrixOptions
                                                                                                        .valueRange,
                                                                                                      "end",
                                                                                                      _vm._n(
                                                                                                        $$v
                                                                                                      )
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "staffingMatrixOptions.valueRange.end"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-row",
                                                                                      {
                                                                                        attrs: {
                                                                                          dense:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-col",
                                                                                          {
                                                                                            staticClass:
                                                                                              "text-right",
                                                                                            attrs: {
                                                                                              cols:
                                                                                                "12"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-btn",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "apply-census-values",
                                                                                                attrs: {
                                                                                                  color:
                                                                                                    "secondary",
                                                                                                  disabled: invalid
                                                                                                },
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.updateCensusValue(
                                                                                                      _vm
                                                                                                        .staffingMatrixOptions
                                                                                                        .valueRange
                                                                                                        .start,
                                                                                                      _vm
                                                                                                        .staffingMatrixOptions
                                                                                                        .valueRange
                                                                                                        .end,
                                                                                                      _vm
                                                                                                        .staffingMatrixOptions
                                                                                                        .valueRange
                                                                                                        .value,
                                                                                                      header.value
                                                                                                    )
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                      " +
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "labels.apply"
                                                                                                          )
                                                                                                        ) +
                                                                                                        "\n                                    "
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var tableHeaders = ref.headers
                                            return [
                                              _c(
                                                "tr",
                                                {
                                                  on: {
                                                    mouseenter: function(
                                                      $event
                                                    ) {
                                                      _vm.hoveredItem = item
                                                    },
                                                    mouseleave: function(
                                                      $event
                                                    ) {
                                                      _vm.hoveredItem = null
                                                    }
                                                  }
                                                },
                                                _vm._l(tableHeaders, function(
                                                  header
                                                ) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: header.value,
                                                      class: [
                                                        "text-start",
                                                        header.value
                                                      ]
                                                    },
                                                    [
                                                      header.value === "census"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-3 text-truncate d-block",
                                                              style: {
                                                                width:
                                                                  header.width +
                                                                  "px"
                                                              },
                                                              attrs: {
                                                                title:
                                                                  item.census
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.census
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        : [
                                                            header.value ===
                                                            "all"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    class: [
                                                                      "text-truncate d-block",
                                                                      _vm.isValidCensusValue(
                                                                        item
                                                                          .shiftTypes[
                                                                          header
                                                                            .shiftTypeId
                                                                        ]
                                                                      )
                                                                        ? "grey--text text--darken-3"
                                                                        : "error--text"
                                                                    ]
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-edit-dialog",
                                                                      {
                                                                        attrs: {
                                                                          "return-value":
                                                                            item
                                                                              .shiftTypes[
                                                                              header
                                                                                .shiftTypeId
                                                                            ]
                                                                        },
                                                                        on: {
                                                                          "update:returnValue": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              item.shiftTypes,
                                                                              header.shiftTypeId,
                                                                              $event
                                                                            )
                                                                          },
                                                                          "update:return-value": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              item.shiftTypes,
                                                                              header.shiftTypeId,
                                                                              $event
                                                                            )
                                                                          },
                                                                          save: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.updateCensusValue(
                                                                              item.census,
                                                                              item.census,
                                                                              item
                                                                                .shiftTypes[
                                                                                header
                                                                                  .shiftTypeId
                                                                              ]
                                                                            )
                                                                          }
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "input",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        dense:
                                                                                          "",
                                                                                        "hide-details":
                                                                                          "",
                                                                                        "single-line":
                                                                                          ""
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .shiftTypes[
                                                                                            header
                                                                                              .shiftTypeId
                                                                                          ],
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.shiftTypes,
                                                                                            header.shiftTypeId,
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.shiftTypes[header.shiftTypeId]"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              item
                                                                                .shiftTypes[
                                                                                header
                                                                                  .shiftTypeId
                                                                              ]
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    class: [
                                                                      "text-truncate d-block",
                                                                      _vm.isValidCensusValue(
                                                                        item
                                                                          .shiftTypes[
                                                                          header
                                                                            .value
                                                                        ]
                                                                      )
                                                                        ? "grey--text text--darken-3"
                                                                        : "error--text"
                                                                    ]
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-edit-dialog",
                                                                      {
                                                                        attrs: {
                                                                          "return-value":
                                                                            item
                                                                              .shiftTypes[
                                                                              header
                                                                                .value
                                                                            ]
                                                                        },
                                                                        on: {
                                                                          "update:returnValue": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              item.shiftTypes,
                                                                              header.value,
                                                                              $event
                                                                            )
                                                                          },
                                                                          "update:return-value": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              item.shiftTypes,
                                                                              header.value,
                                                                              $event
                                                                            )
                                                                          }
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "input",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "v-text-field",
                                                                                    {
                                                                                      attrs: {
                                                                                        dense:
                                                                                          "",
                                                                                        "hide-details":
                                                                                          "",
                                                                                        "single-line":
                                                                                          ""
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .shiftTypes[
                                                                                            header
                                                                                              .value
                                                                                          ],
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.shiftTypes,
                                                                                            header.value,
                                                                                            _vm._n(
                                                                                              $$v
                                                                                            )
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.shiftTypes[header.value]"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              item
                                                                                .shiftTypes[
                                                                                header
                                                                                  .value
                                                                              ]
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                          ]
                                                    ],
                                                    2
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3511761706
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right py-4 px-8",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-4",
                                      attrs: { disabled: _vm.saving, text: "" },
                                      on: {
                                        click: _vm.closeStaffingMatrixDialog
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("labels.cancel")) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "submit",
                                      attrs: {
                                        color: "secondary",
                                        disabled:
                                          _vm.saving ||
                                          _vm.invalidStaffingMatrix.some(
                                            function(invalid) {
                                              return invalid
                                            }
                                          )
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.updateStaffingMatrix.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.saving
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              color: "primary lighten-2",
                                              indeterminate: "",
                                              size: "22",
                                              width: "2"
                                            }
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$t("labels.save")) +
                                                "\n                "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog.show
        ? _c("DeleteConfirmation", {
            attrs: {
              action: "org/checkDepartmentDependencies",
              "delete-body": _vm.$t("descriptions.continueDeleteDepartment", {
                name: _vm.deleteDialog.department.name
              }),
              "dependencies-body": _vm.$t(
                "descriptions.hasDependenciesDepartment",
                { name: _vm.deleteDialog.department.name }
              ),
              item: _vm.deleteDialog.department,
              title: _vm.$t("labels.deleteDepartment?")
            },
            on: {
              close: _vm.closeDeleteConfirmationDialog,
              del: _vm.deleteDepartment
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }