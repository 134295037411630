var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0", attrs: { id: "eventRequest", fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$t("labels.schedule"), disabled: false },
                { text: _vm.$tc("labels.event", 1), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-10 my-3 pa-0" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "dense mt-4",
              attrs: {
                color: "grey darken-3",
                "show-arrows": "",
                "slider-color": "accent",
                "slider-size": "3"
              }
            },
            [
              _c("v-tab", { attrs: { href: "#types" } }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("labels.type")) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-tab", { attrs: { href: "#settings" } }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("labels.setting", 2)) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-tab-item",
                { attrs: { value: "types" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "actions", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass:
                                  "name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block",
                                attrs: {
                                  "append-icon": _vm.filters.name
                                    ? ""
                                    : "fal fa-search",
                                  clearable: !!_vm.filters.name,
                                  dense: "",
                                  "hide-details": "",
                                  placeholder: _vm.$t("labels.searchByName"),
                                  solo: ""
                                },
                                model: {
                                  value: _vm.filters.name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filters,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "filters.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        directives: [
                          {
                            name: "resize",
                            rawName: "v-resize.quiet",
                            value: _vm.onWindowResized,
                            expression: "onWindowResized",
                            modifiers: { quiet: true }
                          }
                        ],
                        attrs: {
                          "fixed-header": "",
                          headers: _vm.headers,
                          "header-props": { sortIcon: "fa fa-arrow-up" },
                          "hide-default-footer": "",
                          items: _vm.paginatedEventTypes,
                          "items-per-page": _vm.pagination.itemsPerPage,
                          "must-sort": "",
                          "single-select": "",
                          "sort-by": _vm.sort.by,
                          "sort-desc": _vm.sort.desc
                        },
                        on: {
                          "update:sortBy": function($event) {
                            return _vm.$set(_vm.sort, "by", $event)
                          },
                          "update:sort-by": function($event) {
                            return _vm.$set(_vm.sort, "by", $event)
                          },
                          "update:sortDesc": function($event) {
                            return _vm.$set(_vm.sort, "desc", $event)
                          },
                          "update:sort-desc": function($event) {
                            return _vm.$set(_vm.sort, "desc", $event)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header.description",
                            fn: function(ref) {
                              var header = ref.header
                              return [
                                _c(
                                  "span",
                                  { staticClass: "grey--text text--darken-3" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(header.text) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "header.availableStatesDisplay",
                            fn: function(ref) {
                              var header = ref.header
                              return [
                                _c("MultiSelectionList", {
                                  attrs: { selection: _vm.statesAvailable },
                                  on: {
                                    selectionConfirmed: function($event) {
                                      return _vm.setFilter(header.value, $event)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "ml-n4 subtitle-2 text-capitalize",
                                                  attrs: {
                                                    color: "secondary",
                                                    text: ""
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(header.text) +
                                                    "\n                    "
                                                ),
                                                _vm.filters[header.value]
                                                  .length > 0
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "grey",
                                                          right: "",
                                                          "x-small": ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      fas fa-filter\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "grey",
                                                          right: "",
                                                          "x-small": ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      fal fa-filter\n                    "
                                                        )
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              var tableHeaders = ref.headers
                              return [
                                _c(
                                  "tr",
                                  {
                                    class: item.obsolete ? "obsolete" : "",
                                    on: {
                                      mouseenter: function($event) {
                                        _vm.hoveredItem = item
                                      },
                                      mouseleave: function($event) {
                                        _vm.hoveredItem = null
                                      }
                                    }
                                  },
                                  [
                                    _vm._l(tableHeaders, function(header) {
                                      return _c(
                                        "td",
                                        {
                                          key: header.value,
                                          staticClass: "text-start"
                                        },
                                        [
                                          header.value === "name"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text text--darken-3 text-truncate d-block",
                                                  style: {
                                                    width: header.width + "px"
                                                  },
                                                  attrs: { title: item.name }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.name) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          header.value === "description"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text text--darken-3 text-truncate d-block",
                                                  style: {
                                                    width: header.width + "px"
                                                  },
                                                  attrs: {
                                                    title: item.description
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.description) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          header.value ===
                                          "availableStatesDisplay"
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text text--darken-3 text-truncate d-block",
                                                  style: {
                                                    width: header.width + "px"
                                                  },
                                                  attrs: {
                                                    title:
                                                      item.availableStatesDisplay
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        item.availableStatesDisplay
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    }),
                                    _c(
                                      "v-speed-dial",
                                      {
                                        staticClass: "mt-6 event-type-dial",
                                        attrs: {
                                          absolute: "",
                                          value:
                                            _vm.hoveredItem &&
                                            _vm.hoveredItem.id === item.id,
                                          right: "",
                                          direction: "left",
                                          transition:
                                            "slide-x-reverse-transition"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "edit",
                                            attrs: {
                                              fab: "",
                                              height: "30",
                                              width: "30",
                                              title: _vm.$t(
                                                "labels.editEventType"
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openEventTypeDialog(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  "\n                      fal fa-pencil\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ]
                            }
                          },
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                _c("v-pagination", {
                                  staticClass: "py-4 footer",
                                  attrs: {
                                    color: "secondary",
                                    length: _vm.numberOfPages,
                                    "next-icon": "far fa-chevron-right",
                                    "prev-icon": "far fa-chevron-left",
                                    "total-visible":
                                      _vm.pagination.maxPaginationControls
                                  },
                                  model: {
                                    value: _vm.pagination.currentPage,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.pagination,
                                        "currentPage",
                                        $$v
                                      )
                                    },
                                    expression: "pagination.currentPage"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "settings" } },
                [
                  _c("ValidationObserver", {
                    ref: "settings",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var invalid = ref.invalid
                          var passes = ref.passes
                          return [
                            _c(
                              "v-container",
                              {
                                staticClass: "px-4 py-0 mx-0 settings",
                                attrs: { fluid: "" }
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "10" } },
                                      [
                                        _c(
                                          "v-radio-group",
                                          {
                                            attrs: { column: "", dense: "" },
                                            model: {
                                              value: _vm.settings.mode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.settings,
                                                  "mode",
                                                  $$v
                                                )
                                              },
                                              expression: "settings.mode"
                                            }
                                          },
                                          [
                                            _c("v-radio", {
                                              staticClass: "single-reviewer",
                                              attrs: { value: "single" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.alwaysReviewedBy"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c("VeeSelect", {
                                                              staticClass:
                                                                "d-inline-block single-reviewer",
                                                              attrs: {
                                                                dense: "",
                                                                disabled:
                                                                  _vm.settings
                                                                    .mode ===
                                                                  "multiple",
                                                                "hide-details":
                                                                  "",
                                                                item: "",
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                                items:
                                                                  _vm.reviewersAvailable,
                                                                label: _vm.$tc(
                                                                  "labels.jobType",
                                                                  1
                                                                ),
                                                                outlined: "",
                                                                rules: {
                                                                  required:
                                                                    _vm.settings
                                                                      .mode ===
                                                                    "single"
                                                                },
                                                                vid:
                                                                  "singleReviewer"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.settings
                                                                    .single,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.settings,
                                                                    "single",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "settings.single"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("v-radio", {
                                              staticClass:
                                                "multiple-reviewer mt-3",
                                              attrs: { value: "multiple" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.eventRequestWithin1"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c("VeeTextField", {
                                                              staticClass:
                                                                "d-inline-block",
                                                              staticStyle: {
                                                                width: "80px"
                                                              },
                                                              attrs: {
                                                                dense: "",
                                                                disabled:
                                                                  _vm.settings
                                                                    .mode ===
                                                                  "single",
                                                                "hide-details":
                                                                  "",
                                                                placeholder: _vm
                                                                  .$t(
                                                                    "labels.number"
                                                                  )
                                                                  .toLowerCase(),
                                                                name:
                                                                  "multipleReviewerLatent",
                                                                outlined: "",
                                                                rules: {
                                                                  required:
                                                                    _vm.settings
                                                                      .mode ===
                                                                    "multiple",
                                                                  min_value: 1,
                                                                  numeric: true
                                                                },
                                                                vid:
                                                                  "multipleReviewerLatent"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.settings
                                                                    .multiple
                                                                    .latent,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.settings
                                                                      .multiple,
                                                                    "latent",
                                                                    _vm._n($$v)
                                                                  )
                                                                },
                                                                expression:
                                                                  "settings.multiple.latent"
                                                              }
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.eventRequestWithin2"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c("VeeSelect", {
                                                              staticClass:
                                                                "d-inline-block multiple-reviewer",
                                                              attrs: {
                                                                dense: "",
                                                                disabled:
                                                                  _vm.settings
                                                                    .mode ===
                                                                  "single",
                                                                "hide-details":
                                                                  "",
                                                                item: "",
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                                items:
                                                                  _vm.reviewersAvailable,
                                                                label: _vm.$tc(
                                                                  "labels.jobType",
                                                                  1
                                                                ),
                                                                outlined: "",
                                                                rules: {
                                                                  required:
                                                                    _vm.settings
                                                                      .mode ===
                                                                    "multiple"
                                                                },
                                                                vid:
                                                                  "multipleReviewer"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.settings
                                                                    .multiple
                                                                    .reviewer,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.settings
                                                                      .multiple,
                                                                    "reviewer",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "settings.multiple.reviewer"
                                                              }
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "mt-3"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "body-2"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.eventRequestWithin3"
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "VeeSelect",
                                                                  {
                                                                    staticClass:
                                                                      "d-inline-block multiple-reviewer-alt",
                                                                    attrs: {
                                                                      dense: "",
                                                                      disabled:
                                                                        _vm
                                                                          .settings
                                                                          .mode ===
                                                                        "single",
                                                                      "hide-details":
                                                                        "",
                                                                      item: "",
                                                                      "item-text":
                                                                        "label",
                                                                      "item-value":
                                                                        "value",
                                                                      items:
                                                                        _vm.reviewersAvailable,
                                                                      label: _vm.$tc(
                                                                        "labels.jobType",
                                                                        1
                                                                      ),
                                                                      outlined:
                                                                        "",
                                                                      rules: {
                                                                        required:
                                                                          _vm
                                                                            .settings
                                                                            .mode ===
                                                                          "multiple"
                                                                      },
                                                                      vid:
                                                                        "multipleReviewerAlt"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .settings
                                                                          .multiple
                                                                          .altReviewer,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .settings
                                                                            .multiple,
                                                                          "altReviewer",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "settings.multiple.altReviewer"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "pa-4", attrs: { dense: "" } },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-3 save-settings",
                                        attrs: {
                                          color: "secondary",
                                          disabled: invalid || _vm.saving
                                        },
                                        on: {
                                          click: function($event) {
                                            return passes(_vm.saveSettings)
                                          }
                                        }
                                      },
                                      [
                                        _vm.saving
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "secondary",
                                                indeterminate: "",
                                                size: "22",
                                                width: "2"
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("labels.save")
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showEventTypeDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: "600px",
                value: _vm.showEventTypeDialog
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-0", attrs: { id: "eventTypeDialog" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-2" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("labels.editRequestRules") +
                                " - " +
                                _vm.selectedEventType.name
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeEventTypeDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var invalid = ref.invalid
                                var passes = ref.passes
                                return [
                                  _c(
                                    "v-form",
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "pt-0 px-6" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-1 caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "descriptions.disabledRequestStates",
                                                            {
                                                              type: _vm.selectedEventType.name.toLowerCase()
                                                            }
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("VeeSelect", {
                                                    staticClass: "states",
                                                    attrs: {
                                                      dense: "",
                                                      item: "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items:
                                                        _vm.statesAvailable,
                                                      label: _vm.$tc(
                                                        "labels.state",
                                                        2
                                                      ),
                                                      multiple: "",
                                                      outlined: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedEventType
                                                          .disabledStates,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedEventType,
                                                          "disabledStates",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedEventType.disabledStates"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pb-0 pt-4 px-8",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    disabled: _vm.saving,
                                                    text: ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.closeEventTypeDialog
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("labels.cancel")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "submit",
                                                  attrs: {
                                                    color: "secondary",
                                                    disabled:
                                                      invalid || _vm.saving
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return passes(
                                                        _vm.saveSelectedEventType
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.saving
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "primary lighten-2",
                                                            indeterminate: "",
                                                            size: "22",
                                                            width: "2"
                                                          }
                                                        }
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.update"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1942201359
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }