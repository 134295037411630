import { render, staticRenderFns } from "./Management.vue?vue&type=template&id=15640724&"
import script from "./Management.vue?vue&type=script&lang=js&"
export * from "./Management.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBreadcrumbs,VContainer,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15640724')) {
      api.createRecord('15640724', component.options)
    } else {
      api.reload('15640724', component.options)
    }
    module.hot.accept("./Management.vue?vue&type=template&id=15640724&", function () {
      api.rerender('15640724', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/users/Management.vue"
export default component.exports