<template>
  <v-dialog
    v-model="show"
    persistent
    width="420"
  >
    <v-card id="verifyUserDialog">
      <ValidationObserver
        v-slot="{ invalid, passes }"
      >
        <v-card-title
          class="grey--text text--darken-3 subtitle-2 pt-4 pb-0 d-block"
        >
          {{ $t('labels.addUser') }}
          <v-btn
            class="float-right"
            icon
            x-small
            @click="$emit('close')"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <VeeTextField
            v-model.lazy="email"
            dense
            hide-details
            :label="$t('labels.email')"
            name="email"
            outlined
            rules="email|required|max:255"
          />
          <span class="grey--text caption ml-3">
            {{ $t('descriptions.verifyEmail') }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="ma-3 px-4"
            text
            @click="$emit('close')"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            class="ma-3 verify"
            color="secondary"
            :disabled="invalid || checking"
            @click="passes(verify)"
          >
            <v-progress-circular
              v-if="checking"
              color="secondary"
              indeterminate
              size="22"
              width="2"
            />
            <span v-else>
              {{ $t('labels.next') }}
            </span>
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { profileResponseTemplate } from '@/services/constants';
import VeeTextField from '@/components/form_controls/VeeTextField';
import { showStatus } from '@/plugins/vue-notification';

export default {
  components: {
    VeeTextField
  },
  data () {
    return {
      checking: false,
      email: '',
      show: true
    };
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    verify () {
      this.checking = true;
      this.dispatch('account/findUsers', { email: this.email }).then((response) => {
        // Emails are unique so if a user is found it should be the first on in the list.
        let user = response.results[0];
        if (user) {
          const userId = user.id;
          let orgProfile = _.find(user.profiles, (p) => p.orgId === this.$store.state.org.id);
          if (!orgProfile) {
            orgProfile = _.cloneDeep(profileResponseTemplate);
          }
          user = {
            ...user,
            ...orgProfile,
            userId
          };
        }
        this.$emit('done', {
          email: this.email,
          user
        });
        this.$emit('close');
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.genericErrorPlain'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.checking = false;
      });
    }
  }
};
</script>

<style lang="scss">
#verifyUserDialog {
  .verify {
    width: 70px;
  }
}
</style>
