var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0", attrs: { id: "shiftRequest", fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$tc("labels.request", 1), disabled: false },
                { text: _vm.$t("labels.shift"), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-10 my-3 pa-0" },
        [
          _c("v-data-table", {
            attrs: {
              "fixed-header": "",
              headers: _vm.headers,
              "header-props": { sortIcon: "fa fa-arrow-up" },
              "hide-default-footer": "",
              items: _vm.requestTypes,
              "items-per-page": _vm.requestTypes.length,
              "single-select": ""
            },
            scopedSlots: _vm._u([
              {
                key: "header.availableStates",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var tableHeaders = ref.headers
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          mouseenter: function($event) {
                            _vm.hoveredItem = item
                          },
                          mouseleave: function($event) {
                            _vm.hoveredItem = null
                          }
                        }
                      },
                      [
                        _vm._l(tableHeaders, function(header) {
                          return _c(
                            "td",
                            { key: header.value, staticClass: "text-start" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "grey--text text--darken-3 text-truncate d-block",
                                  style:
                                    header.width === "*"
                                      ? {}
                                      : { width: header.width + "px" },
                                  attrs: { title: item[header.value] }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item[header.value]) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        _c(
                          "v-speed-dial",
                          {
                            staticClass: "mt-6 dial",
                            attrs: {
                              absolute: "",
                              value:
                                _vm.hoveredItem &&
                                _vm.hoveredItem.type === item.type,
                              right: "",
                              direction: "left",
                              transition: "slide-x-reverse-transition"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "edit",
                                attrs: {
                                  fab: "",
                                  height: "30",
                                  width: "30",
                                  title: _vm.$t("labels.edit")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openEditDialog(item.type)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    "\n                fal fa-pencil\n              "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.showEditDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: "600px",
                value: _vm.showEditDialog
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-0", attrs: { id: "editDialog" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-2" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("labels.editRequestRules") +
                                " - " +
                                _vm
                                  .$tc("labels." + _vm.selectedType.type, 1)
                                  .toLowerCase()
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeEditDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var invalid = ref.invalid
                                var passes = ref.passes
                                return [
                                  _c(
                                    "v-form",
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "pt-0 px-6" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-1 caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "descriptions.cutOffTimeSection"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("VeeTextField", {
                                                    staticClass:
                                                      "d-inline-block",
                                                    staticStyle: {
                                                      width: "110px"
                                                    },
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "labels.cutOffTime"
                                                      ),
                                                      name: "cutOffTime",
                                                      outlined: "",
                                                      rules: {
                                                        required: true,
                                                        numeric: true,
                                                        max_value: 12,
                                                        min_value: 0
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedType
                                                          .cutOffTime,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedType,
                                                          "cutOffTime",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "selectedType.cutOffTime"
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "grey--text text--darken-3 body-2 ml-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.cutOffTimeHint"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-1 caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "descriptions.disabledRequestStates",
                                                            {
                                                              type: _vm
                                                                .$tc(
                                                                  "labels." +
                                                                    _vm
                                                                      .selectedType
                                                                      .type,
                                                                  1
                                                                )
                                                                .toLowerCase()
                                                            }
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("VeeSelect", {
                                                    staticClass: "states",
                                                    attrs: {
                                                      dense: "",
                                                      item: "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items:
                                                        _vm.statesAvailable,
                                                      label: _vm.$tc(
                                                        "labels.state",
                                                        2
                                                      ),
                                                      multiple: "",
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.updateSpecificReviewers
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedType
                                                          .disabledStates,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedType,
                                                          "disabledStates",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedType.disabledStates"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { dense: "" }
                                            },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-1 caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "descriptions.designateReviewers"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grey--text text--darken-3 body-2 mt-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "labels.defaultReviewer"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c("VeeSelect", {
                                                    staticClass:
                                                      "default-reviewer",
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      "item-text": "label",
                                                      "item-value": "value",
                                                      items:
                                                        _vm.reviewersAvailable,
                                                      label: _vm.$tc(
                                                        "labels.jobType",
                                                        1
                                                      ),
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedType
                                                          .reviewers.default,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedType
                                                            .reviewers,
                                                          "default",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedType.reviewers.default"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.selectedType.reviewers.specific,
                                            function(item, idx) {
                                              return _c(
                                                "v-row",
                                                { key: idx },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text text--darken-3 body-2 mt-3"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.reviewBy"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _c("VeeSelect", {
                                                        staticClass:
                                                          "specific-reviewer-type",
                                                        attrs: {
                                                          dense: "",
                                                          "hide-details": "",
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          items:
                                                            _vm.reviewersAvailable,
                                                          label: _vm.$tc(
                                                            "labels.jobType",
                                                            1
                                                          ),
                                                          outlined: "",
                                                          rules: "required",
                                                          vid: "jobType" + idx
                                                        },
                                                        model: {
                                                          value: item.reviewer,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "reviewer",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.reviewer"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "4" } },
                                                    [
                                                      _c("VeeSelect", {
                                                        staticClass:
                                                          "specific-reviewer-state",
                                                        attrs: {
                                                          dense: "",
                                                          "hide-details": "",
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          items: _vm.getSpecificStatesAvailable(
                                                            idx
                                                          ),
                                                          label: _vm.$tc(
                                                            "labels.state",
                                                            1
                                                          ),
                                                          outlined: "",
                                                          rules: "required",
                                                          vid: "state" + idx
                                                        },
                                                        model: {
                                                          value: item.state,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "state",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.state"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pl-0",
                                                      attrs: { cols: "1" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "remove-reviewer",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeReviewer(
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "error",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      fal fa-trash-alt\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "caption add-reviewer secondary--text",
                                              on: { click: _vm.addReviewer }
                                            },
                                            [
                                              _vm._v(
                                                "\n                + " +
                                                  _vm._s(
                                                    _vm.$t("labels.addReviewer")
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pb-0 pt-4 px-8",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    disabled: _vm.saving,
                                                    text: ""
                                                  },
                                                  on: {
                                                    click: _vm.closeEditDialog
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("labels.cancel")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "submit",
                                                  attrs: {
                                                    color: "secondary",
                                                    disabled:
                                                      invalid || _vm.saving
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return passes(_vm.update)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm.saving
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "primary lighten-2",
                                                            indeterminate: "",
                                                            size: "22",
                                                            width: "2"
                                                          }
                                                        }
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.update"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3378657690
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }