var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0", attrs: { id: "scheduleStates", fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$t("labels.schedule"), disabled: false },
                { text: _vm.$tc("labels.state", 2), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-10 my-3 pa-0" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "dense mt-4",
              attrs: {
                color: "grey darken-3",
                "show-arrows": "",
                "slider-color": "accent",
                "slider-size": "3"
              }
            },
            [
              _c("v-tab", { attrs: { href: "#states" } }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("labels.state", 2)) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-tab", { attrs: { href: "#timeFrame" } }, [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("labels.timeFrame")) +
                      "\n        "
                  )
                ])
              ]),
              _c(
                "v-tab-item",
                { attrs: { value: "states" } },
                [
                  _c("ValidationObserver", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var invalid = ref.invalid
                          var passes = ref.passes
                          return [
                            _c(
                              "v-container",
                              {
                                staticClass: "pa-0 mx-0 states",
                                attrs: { fluid: "" }
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "pa-4", attrs: { dense: "" } },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.selfSchedule")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.selfSchedule"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-always-opened",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.selfScheduleAlwaysOpened"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.selfScheduleAlwaysOpened"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.selfScheduleAlwaysOpened,
                                            callback: function($$v) {
                                              _vm.selfScheduleAlwaysOpened = $$v
                                            },
                                            expression:
                                              "selfScheduleAlwaysOpened"
                                          }
                                        }),
                                        _c("v-spacer", { staticClass: "my-2" }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-bid",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            disabled:
                                              _vm.selfScheduleAlwaysOpened,
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-2 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.selfScheduleBid"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.selfScheduleBid"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.selfScheduleBidForOpening,
                                            callback: function($$v) {
                                              _vm.selfScheduleBidForOpening = $$v
                                            },
                                            expression:
                                              "selfScheduleBidForOpening"
                                          }
                                        }),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              "max-width": "400px",
                                              right: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mt-n1",
                                                              attrs: {
                                                                color: "info",
                                                                dense: "",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                      fal fa-question-circle\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "descriptions.selfScheduleBid"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("v-spacer", { staticClass: "my-2" }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-primary",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            disabled:
                                              _vm.selfScheduleBidForOpening,
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.selfSchedulePrimaryShiftOnly"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.selfSchedulePrimaryShiftOnly"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.primaryShiftOnly,
                                            callback: function($$v) {
                                              _vm.primaryShiftOnly = $$v
                                            },
                                            expression: "primaryShiftOnly"
                                          }
                                        }),
                                        _c("v-spacer", { staticClass: "my-2" }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-editing",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.selfScheduleAllowEditing"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.selfScheduleAllowEditing"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.allowEditing.selfSchedule,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.allowEditing,
                                                "selfSchedule",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "allowEditing.selfSchedule"
                                          }
                                        }),
                                        _c("v-spacer", { staticClass: "my-2" }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-overtime",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.allowOvertime"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.allowOvertime"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.allowOvertime,
                                            callback: function($$v) {
                                              _vm.allowOvertime = $$v
                                            },
                                            expression: "allowOvertime"
                                          }
                                        }),
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-snapshot",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.takeSnapshotSelfSchedule"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.takeSnapshotSelfSchedule"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.snapshots.selfSchedule,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.snapshots,
                                                "selfSchedule",
                                                $$v
                                              )
                                            },
                                            expression: "snapshots.selfSchedule"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "pa-4", attrs: { dense: "" } },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.draft")) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.draftState"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block hide-draft",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.hideDraftScheduleFromStaff"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.hideDraftScheduleFromStaff"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.hideDraftScheduleFromStaff,
                                            callback: function($$v) {
                                              _vm.hideDraftScheduleFromStaff = $$v
                                            },
                                            expression:
                                              "hideDraftScheduleFromStaff"
                                          }
                                        }),
                                        _c("v-spacer", { staticClass: "my-3" }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block draft-approval",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.scheduleApprovalRequired"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.scheduleApprovalRequired"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.approvals.post,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.approvals,
                                                "post",
                                                $$v
                                              )
                                            },
                                            expression: "approvals.post"
                                          }
                                        }),
                                        _c("v-spacer", { staticClass: "my-3" }),
                                        _c("VeeSelect", {
                                          staticClass: "post-reviewer",
                                          attrs: {
                                            dense: "",
                                            disabled: !_vm.approvals.post,
                                            "hide-details": "",
                                            "item-text": "name",
                                            "item-value": "id",
                                            items: _vm.groups,
                                            label: _vm.$t("labels.reviewBy"),
                                            name: "draftReviewer",
                                            outlined: "",
                                            rules: _vm.approvals.post
                                              ? "required"
                                              : ""
                                          },
                                          model: {
                                            value: _vm.reviewers.post,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.reviewers,
                                                "post",
                                                $$v
                                              )
                                            },
                                            expression: "reviewers.post"
                                          }
                                        }),
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block draft-snapshot",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.takeSnapshotDraft"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.takeSnapshotDraft"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.snapshots.draft,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.snapshots,
                                                "draft",
                                                $$v
                                              )
                                            },
                                            expression: "snapshots.draft"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "pa-4", attrs: { dense: "" } },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.underStaffReview"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.underStaffReview"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block published-approval",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.scheduleApprovalRequired"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.scheduleApprovalRequired"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.approvals.publish,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.approvals,
                                                "publish",
                                                $$v
                                              )
                                            },
                                            expression: "approvals.publish"
                                          }
                                        }),
                                        _c("v-spacer", { staticClass: "my-3" }),
                                        _c("VeeSelect", {
                                          attrs: {
                                            dense: "",
                                            disabled: !_vm.approvals.publish,
                                            "hide-details": "",
                                            "item-text": "name",
                                            "item-value": "id",
                                            items: _vm.groups,
                                            label: _vm.$t("labels.reviewBy"),
                                            name: "underStaffReviewReviewer",
                                            outlined: "",
                                            rules: _vm.approvals.publish
                                              ? "required"
                                              : ""
                                          },
                                          model: {
                                            value: _vm.reviewers.publish,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.reviewers,
                                                "publish",
                                                $$v
                                              )
                                            },
                                            expression: "reviewers.publish"
                                          }
                                        }),
                                        _c("v-divider", {
                                          staticClass: "my-4"
                                        }),
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block published-snapshot",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.takeSnapshotUnderStaffReview"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.takeSnapshotUnderStaffReview"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.snapshots.published,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.snapshots,
                                                "published",
                                                $$v
                                              )
                                            },
                                            expression: "snapshots.published"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "pa-4", attrs: { dense: "" } },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-3 save-states",
                                        attrs: {
                                          color: "secondary",
                                          disabled: invalid || _vm.saving
                                        },
                                        on: {
                                          click: function($event) {
                                            return passes(_vm.saveStates)
                                          }
                                        }
                                      },
                                      [
                                        _vm.saving
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "secondary",
                                                indeterminate: "",
                                                size: "22",
                                                width: "2"
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("labels.save")
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "timeFrame" } },
                [
                  _c("ValidationObserver", {
                    ref: "timeFrame",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var invalid = ref.invalid
                          var passes = ref.passes
                          return [
                            _c(
                              "v-container",
                              {
                                staticClass: "pa-0 mx-0 time-frame",
                                attrs: { fluid: "" }
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "py-4 pl-4 pr-8",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.scheduleStartDate"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.scheduleStartDate"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            ref: "hiredOnPicker",
                                            attrs: {
                                              "close-on-content-click": false,
                                              "offset-y": "",
                                              "max-width": "290px",
                                              "min-width": "auto",
                                              "nudge-bottom": 0,
                                              "nudge-left": 45
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "VeeTextField",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    "##/##/####",
                                                                  expression:
                                                                    "'##/##/####'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "start-date",
                                                              attrs: {
                                                                "append-icon":
                                                                  "fal fa-calendar-alt",
                                                                autocomplete: false,
                                                                dense: "",
                                                                disabled: "",
                                                                "hide-details":
                                                                  "",
                                                                name:
                                                                  "scheduleStartDate",
                                                                outlined: "",
                                                                placeholder: _vm.$t(
                                                                  "labels.scheduleStartDate"
                                                                ),
                                                                rules: {
                                                                  required: true,
                                                                  dateRegex_MMDDYYYY:
                                                                    _vm.dateRegex
                                                                }
                                                              },
                                                              on: {
                                                                blur: function(
                                                                  $event
                                                                ) {
                                                                  _vm.initialStartDate = _vm.parseDate(
                                                                    _vm.initialStartDateDisplay
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.initialStartDateDisplay,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.initialStartDateDisplay = $$v
                                                                },
                                                                expression:
                                                                  "initialStartDateDisplay"
                                                              }
                                                            },
                                                            "VeeTextField",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.showScheduleStartDatePicker,
                                              callback: function($$v) {
                                                _vm.showScheduleStartDatePicker = $$v
                                              },
                                              expression:
                                                "showScheduleStartDatePicker"
                                            }
                                          },
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                max: _vm.maxScheduleStartDate,
                                                "no-title": ""
                                              },
                                              on: {
                                                input: function($event) {
                                                  _vm.showScheduleStartDatePicker = false
                                                }
                                              },
                                              model: {
                                                value: _vm.initialStartDate,
                                                callback: function($$v) {
                                                  _vm.initialStartDate = $$v
                                                },
                                                expression: "initialStartDate"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "py-4 pl-4 pr-8",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.scheduleDuration"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.scheduleDuration"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("VeeTextField", {
                                          staticClass: "number-of-days",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            name: "scheduleDuration",
                                            outlined: "",
                                            placeholder: _vm.$t(
                                              "labels.numberOfDays"
                                            ),
                                            rules: {
                                              required: true,
                                              numeric: true,
                                              min_value:
                                                _vm.selfSchedulePeriod +
                                                _vm.postLeadTime
                                            }
                                          },
                                          model: {
                                            value: _vm.period,
                                            callback: function($$v) {
                                              _vm.period = _vm._n($$v)
                                            },
                                            expression: "period"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "py-4 pl-4 pr-8",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.selfSchedulePeriod"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.selfSchedulePeriod"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("VeeTextField", {
                                          staticClass: "number-of-days",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            name: "selfSchedulePeriod",
                                            outlined: "",
                                            placeholder: _vm.$t(
                                              "labels.numberOfDays"
                                            ),
                                            rules: {
                                              required: true,
                                              numeric: true,
                                              min_value: 1,
                                              max_value:
                                                _vm.period - _vm.postLeadTime
                                            }
                                          },
                                          model: {
                                            value: _vm.selfSchedulePeriod,
                                            callback: function($$v) {
                                              _vm.selfSchedulePeriod = _vm._n(
                                                $$v
                                              )
                                            },
                                            expression: "selfSchedulePeriod"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-segments",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.useDefaultSelfScheduleWindow"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.useDefaultSelfScheduleWindow"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.useDefaultSelfScheduleWindow,
                                            callback: function($$v) {
                                              _vm.useDefaultSelfScheduleWindow = $$v
                                            },
                                            expression:
                                              "useDefaultSelfScheduleWindow"
                                          }
                                        }),
                                        !_vm.useDefaultSelfScheduleWindow
                                          ? [
                                              _c("v-spacer", {
                                                staticClass: "my-2"
                                              }),
                                              _c(
                                                "v-row",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _vm._l(
                                                        _vm.selfScheduleSegments,
                                                        function(segment, idx) {
                                                          return _c(
                                                            "v-row",
                                                            {
                                                              key: idx,
                                                              staticClass:
                                                                "my-1 segment",
                                                              attrs: {
                                                                dense: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _c(
                                                                    "VeeSelect",
                                                                    {
                                                                      staticClass:
                                                                        "d-inline-block job-status",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        "item-text":
                                                                          "name",
                                                                        "item-value":
                                                                          "id",
                                                                        items: _vm.getAvailableJobStatus(
                                                                          idx
                                                                        ),
                                                                        label: _vm.$t(
                                                                          "labels.jobStatus"
                                                                        ),
                                                                        "menu-props": {
                                                                          top: false,
                                                                          offsetY: true
                                                                        },
                                                                        multiple:
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        rules:
                                                                          "required",
                                                                        vid:
                                                                          "jobStatus" +
                                                                          idx
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "selection",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var item =
                                                                                ref.item
                                                                              var jobStatusIndex =
                                                                                ref.index
                                                                              return [
                                                                                jobStatusIndex ===
                                                                                segment
                                                                                  .allowedJobStatus
                                                                                  .length -
                                                                                  1
                                                                                  ? _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text text--darken-3 body-2"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              item.shortCode
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text text--darken-3 body-2 pr-1"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                " +
                                                                                            _vm._s(
                                                                                              item.shortCode +
                                                                                                ","
                                                                                            ) +
                                                                                            "\n                              "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          segment.allowedJobStatus,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            segment,
                                                                            "allowedJobStatus",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "segment.allowedJobStatus"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "VeeSelect",
                                                                    {
                                                                      staticClass:
                                                                        "d-inline-block rule-type",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        "item-text":
                                                                          "name",
                                                                        "item-value":
                                                                          "id",
                                                                        items: [
                                                                          {
                                                                            name: _vm.$t(
                                                                              "labels.canSelfSchedule"
                                                                            ),
                                                                            id:
                                                                              "allow"
                                                                          },
                                                                          {
                                                                            name: _vm.$t(
                                                                              "labels.cannotSelfSchedule"
                                                                            ),
                                                                            id:
                                                                              "forbid"
                                                                          }
                                                                        ],
                                                                        label: _vm.$t(
                                                                          "labels.ruleType"
                                                                        ),
                                                                        "menu-props": {
                                                                          top: false,
                                                                          offsetY: true
                                                                        },
                                                                        outlined:
                                                                          "",
                                                                        rules:
                                                                          "required",
                                                                        vid:
                                                                          "ruleType" +
                                                                          idx
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          segment.type,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            segment,
                                                                            "type",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "segment.type"
                                                                      }
                                                                    }
                                                                  ),
                                                                  segment.type ===
                                                                  "allow"
                                                                    ? [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "grey--text text--darken-3 body-2 mx-2 text-lowercase"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "labels.from"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "VeeTextField",
                                                                          {
                                                                            staticClass:
                                                                              "latent d-inline-block number-of-days",
                                                                            attrs: {
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              name:
                                                                                "latent",
                                                                              outlined:
                                                                                "",
                                                                              label: _vm.$t(
                                                                                "labels.day"
                                                                              ),
                                                                              rules: {
                                                                                required: true,
                                                                                numeric: true,
                                                                                min_value: 1,
                                                                                max_value:
                                                                                  _vm.selfSchedulePeriod
                                                                              },
                                                                              vid:
                                                                                "latent" +
                                                                                idx
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                segment.latent,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  segment,
                                                                                  "latent",
                                                                                  _vm._n(
                                                                                    $$v
                                                                                  )
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "segment.latent"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 remove-segment",
                                                                      attrs: {
                                                                        icon: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeSegment(
                                                                            idx
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "error",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              fal fa-trash-alt\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c("v-spacer"),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "caption add-segment secondary--text",
                                                          on: {
                                                            click:
                                                              _vm.addSegment
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        + " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.addSelfScheduleWindow"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "grey--text caption mt-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        "*" +
                                                          _vm.$t(
                                                            "descriptions.selfScheduleWindows"
                                                          )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "py-4 pl-4 pr-8",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.postLeadTime")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grey--text caption" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "descriptions.postLeadTime"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("VeeTextField", {
                                          staticClass: "number-of-days",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            name: "postLeadTime",
                                            outlined: "",
                                            placeholder: _vm.$t(
                                              "labels.numberOfDays"
                                            ),
                                            rules: {
                                              required: true,
                                              numeric: true,
                                              min_value: _vm.nurseReviewPeriod,
                                              max_value:
                                                _vm.period -
                                                _vm.selfSchedulePeriod
                                            }
                                          },
                                          model: {
                                            value: _vm.postLeadTime,
                                            callback: function($$v) {
                                              _vm.postLeadTime = _vm._n($$v)
                                            },
                                            expression: "postLeadTime"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text--darken-3 body-2 mr-1"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.nurseReviewPeriod1"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _c("VeeTextField", {
                                          staticClass: "number-of-days",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            name: "nurseReviewPeriod",
                                            outlined: "",
                                            placeholder: _vm.$t(
                                              "labels.numberOfDays"
                                            ),
                                            rules: {
                                              required: true,
                                              numeric: true,
                                              min_value: 0,
                                              max_value: _vm.postLeadTime
                                            }
                                          },
                                          model: {
                                            value: _vm.nurseReviewPeriod,
                                            callback: function($$v) {
                                              _vm.nurseReviewPeriod = _vm._n(
                                                $$v
                                              )
                                            },
                                            expression: "nurseReviewPeriod"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "grey--text text--darken-3 body-2 ml-1"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.nurseReviewPeriod2"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _vm.nurseReviewPeriod
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grey--text caption mt-3"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      "*" +
                                                        _vm.$t(
                                                          "descriptions.nurseReviewPeriod",
                                                          {
                                                            n:
                                                              _vm.nurseReviewPeriod
                                                          }
                                                        )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "py-4 pl-4 pr-8",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.otherSettings")
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-switch", {
                                          staticClass:
                                            "pt-0 mt-0 ml-1 d-inline-block self-schedule-availability",
                                          attrs: {
                                            color: "success",
                                            dense: "",
                                            "hide-details": "",
                                            inset: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-4 body-2 grey--text text--darken-3",
                                                        attrs: {
                                                          title: _vm.$t(
                                                            "labels.selfScheduleAvailability"
                                                          )
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.selfScheduleAvailability"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.allowFlexOn,
                                            callback: function($$v) {
                                              _vm.allowFlexOn = $$v
                                            },
                                            expression: "allowFlexOn"
                                          }
                                        }),
                                        _c("v-spacer"),
                                        _vm.allowFlexOn
                                          ? _c(
                                              "div",
                                              { staticClass: "ml-15" },
                                              [
                                                _c("v-switch", {
                                                  staticClass:
                                                    "mt-1 d-inline-block self-schedule-custom-availability",
                                                  attrs: {
                                                    color: "success",
                                                    dense: "",
                                                    "hide-details": "",
                                                    inset: ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mr-4 body-2 grey--text text--darken-3",
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "labels.allowFlexOnPartial"
                                                                  )
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.allowFlexOnPartial"
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.allowPartialFlexOn,
                                                    callback: function($$v) {
                                                      _vm.allowPartialFlexOn = $$v
                                                    },
                                                    expression:
                                                      "allowPartialFlexOn"
                                                  }
                                                }),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      "max-width": "400px",
                                                      right: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mt-n1",
                                                                      attrs: {
                                                                        color:
                                                                          "info",
                                                                        dense:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                        fal fa-question-circle\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.allowFlexOnPartial"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.allowFlexOn
                                          ? _c(
                                              "div",
                                              { staticClass: "ml-15" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-3 body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.allowFlexOn1"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _c("VeeTextField", {
                                                  staticClass: "number-of-days",
                                                  attrs: {
                                                    dense: "",
                                                    "hide-details": "",
                                                    name: "nurseAvailability",
                                                    outlined: "",
                                                    placeholder: _vm.$t(
                                                      "labels.numberOfHours"
                                                    ),
                                                    rules: {
                                                      required: _vm.allowFlexOn,
                                                      numeric: true,
                                                      min_value: 0
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.flexOnLeadTime,
                                                    callback: function($$v) {
                                                      _vm.flexOnLeadTime = _vm._n(
                                                        $$v
                                                      )
                                                    },
                                                    expression: "flexOnLeadTime"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-3 body-2 ml-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.allowFlexOn2"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "py-4 pl-4 pr-8",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c("v-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("labels.preview")) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 pa-4 preview",
                                    attrs: { dense: "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "10" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "body-2 font-weight-medium grey--text text--darken-3",
                                            attrs: {
                                              dense: "",
                                              "no-gutters": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.selfSchedule"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("labels.draft")
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.staffReview"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("labels.published")
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "10" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "body-2 font-weight-medium grey--text text--darken-3",
                                            attrs: {
                                              dense: "",
                                              "no-gutters": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "self-schedule-preview caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.selfScheduleRangePreview
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "text--darken-3",
                                                    attrs: {
                                                      color: "grey",
                                                      small: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      fas fa-caret-right\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "draft-preview caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.draftRangePreview
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "text--darken-3",
                                                    attrs: {
                                                      color: "grey",
                                                      small: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      fas fa-caret-right\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "staff-review-preview caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.staffReviewRangePreview
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "published-preview caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.publishedRangePreview
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "10" } },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "body-2 font-weight-medium grey--text text--darken-3",
                                            attrs: {
                                              dense: "",
                                              "no-gutters": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("labels.xDays", {
                                                        x:
                                                          _vm.selfSchedulePeriod
                                                      })
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("labels.xDays", {
                                                        x:
                                                          _vm.period -
                                                          _vm.selfSchedulePeriod -
                                                          _vm.postLeadTime
                                                      })
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c("v-spacer"),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("labels.xDays", {
                                                        x: _vm.nurseReviewPeriod
                                                      })
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("labels.xDays", {
                                                        x:
                                                          _vm.postLeadTime -
                                                          _vm.nurseReviewPeriod
                                                      })
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "pa-4", attrs: { dense: "" } },
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-3 save-time-frame",
                                        attrs: {
                                          color: "secondary",
                                          disabled: invalid || _vm.saving
                                        },
                                        on: {
                                          click: function($event) {
                                            return passes(_vm.saveTimeFrame)
                                          }
                                        }
                                      },
                                      [
                                        _vm.saving
                                          ? _c("v-progress-circular", {
                                              attrs: {
                                                color: "secondary",
                                                indeterminate: "",
                                                size: "22",
                                                width: "2"
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("labels.save")
                                                  ) +
                                                  "\n                "
                                              )
                                            ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }