<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $t('labels.daily'), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="dailySchedules"
    >
      <v-tabs
        class="dense mt-4"
        color="grey darken-3"
        show-arrows
        slider-color="accent"
        slider-size="3"
      >
        <v-tab
          href="#rounds"
        >
          <span>
            {{ $t('labels.scheduleRound') }}
          </span>
        </v-tab>
        <v-tab-item value="rounds">
          <v-row
            class="actions"
            no-gutters
          >
            <v-col>
              <v-text-field
                v-model.trim="filters.name"
                :append-icon="filters.name ? '' : 'fal fa-search'"
                class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
                :clearable="!!filters.name"
                dense
                hide-details
                :placeholder="$t('labels.searchByName')"
                solo
              />
              <v-btn
                class="float-right mt-2 mr-4"
                color="secondary"
                @click="openDailyScheduleDialog()"
              >
                {{ $t('labels.addDailySchedule') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            v-resize.quiet="onWindowResized"
            fixed-header
            :headers="headers"
            :header-props="{ sortIcon: 'fa fa-arrow-up' }"
            hide-default-footer
            :items="paginatedDailySchedules"
            :items-per-page="pagination.itemsPerPage"
            must-sort
            single-select
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
          >
            <template #header.description="{ header }">
              <span class="grey--text text--darken-3">
                {{ header.text }}
              </span>
            </template>
            <template #header.associatedShiftTypes="{ header }">
              <MultiSelectionList
                :selection="shiftTypesAvailable"
                @selectionConfirmed="setFilter(header.value, $event)"
              >
                <template #activator="{ on }">
                  <v-btn
                    class="ml-n4 subtitle-2 text-capitalize"
                    color="secondary"
                    text
                    v-on="on"
                  >
                    {{ header.text }}
                    <v-icon
                      v-if="filters[header.value].length > 0"
                      class="ml-2"
                      color="grey"
                      right
                      x-small
                    >
                      fas fa-filter
                    </v-icon>
                    <v-icon
                      v-else
                      class="ml-2"
                      color="grey"
                      right
                      x-small
                    >
                      fal fa-filter
                    </v-icon>
                  </v-btn>
                </template>
              </MultiSelectionList>
            </template>
            <template #item="{ item, headers: tableHeaders }">
              <tr
                :class="item.obsolete ? 'obsolete' : ''"
                @mouseenter="hoveredItem = item"
                @mouseleave="hoveredItem = null"
              >
                <td
                  v-for="header in tableHeaders"
                  :key="header.value"
                  class="text-start"
                >
                  <span
                    v-if="header.value === 'name'"
                    class="grey--text text--darken-3 text-truncate d-block"
                    :style="{ width: `${header.width}px` }"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </span>
                  <span
                    v-if="header.value === 'description'"
                    class="grey--text text--darken-3 text-truncate d-block"
                    :style="{ width: `${header.width}px` }"
                    :title="item.description"
                  >
                    {{ item.description }}
                  </span>
                  <span
                    v-if="header.value === 'associatedShiftTypes'"
                    class="grey--text text--darken-3 text-truncate d-block"
                    :style="{ width: `${header.width}px` }"
                    :title="getAssociatedShiftTypesDisplay(item)"
                  >
                    {{ getAssociatedShiftTypesDisplay(item) }}
                  </span>
                  <span
                    v-if="header.value === 'censusCollectionTimes'"
                    class="grey--text text--darken-3 text-truncate d-block"
                    :style="{ width: `${header.width}px` }"
                    :title="getCensusCollectionTimesDisplay(item)"
                  >
                    {{ getCensusCollectionTimesDisplay(item) }}
                  </span>
                  <template v-if="header.value === 'receiveCensus'">
                    <span
                      class="d-inline-block"
                    >
                      <v-icon
                        v-if="receivesCensus(item)"
                        color="success"
                        small
                      >
                        fas fa-check-circle
                      </v-icon>
                      <v-icon
                        v-else
                        color="grey"
                        small
                      >
                        far fa-times-circle
                      </v-icon>
                    </span>
                  </template>
                </td>
                <v-speed-dial
                  absolute
                  class="mt-6 daily-schedule-dial"
                  :value="hoveredItem && hoveredItem.id === item.id"
                  right
                  :direction="'left'"
                  :transition="'slide-x-reverse-transition'"
                >
                  <v-btn
                    class="edit"
                    fab
                    height="30"
                    width="30"
                    :title="$t('labels.editDailySchedule')"
                    @click="openDailyScheduleDialog(item)"
                  >
                    <v-icon small>
                      fal fa-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="delete"
                    fab
                    height="30"
                    width="30"
                    :title="$t('labels.deleteDailySchedule')"
                    @click="openDeleteConfirmationDialog(item)"
                  >
                    <v-icon
                      color="error"
                      small
                    >
                      fal fa-trash-alt
                    </v-icon>
                  </v-btn>
                </v-speed-dial>
              </tr>
            </template>
            <template #footer>
              <v-pagination
                v-model="pagination.currentPage"
                class="py-4 footer"
                color="secondary"
                :length="numberOfPages"
                next-icon="far fa-chevron-right"
                prev-icon="far fa-chevron-left"
                :total-visible="pagination.maxPaginationControls"
              />
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-dialog
      v-if="showDailyScheduleDialog"
      persistent
      width="500px"
      :value="showDailyScheduleDialog"
    >
      <v-card
        id="dailySchedule"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ selectedDailySchedule.id ? $t('labels.editDailySchedule') : $t('labels.addDailySchedule') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeDailyScheduleDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="daily-schedule">
              <v-container class="pt-0 px-6">
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedDailySchedule.name"
                      dense
                      :label="$t('labels.name')"
                      name="name"
                      outlined
                      :rules="{ required: true, max: 32, excluded: namesInUse }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedDailySchedule.description"
                      dense
                      :label="$t('labels.description')"
                      name="description"
                      outlined
                      rules="max:256"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <VeeSelect
                      v-model="selectedDailySchedule.shiftTypes"
                      class="shift-types"
                      dense
                      item
                      item-text="label"
                      item-value="value"
                      :items="shiftTypesAvailable"
                      :label="$t('labels.associatedShiftTypes')"
                      multiple
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-0"
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedDailyScheduleCollectCensus"
                      class="pt-0 mt-0 ml-1 d-inline-block collect-census"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.collectCensus')"
                        >
                          {{ $t('labels.collectCensus') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <template v-if="selectedDailyScheduleCollectCensus">
                  <v-row
                    v-for="(census, idx) in selectedDailySchedule.settings.census.predefined"
                    :key="idx"
                  >
                    <v-col
                      cols="5"
                    >
                      <VeeSelect
                        v-model="census.time"
                        class="census-time"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        :items="availableTimes"
                        :label="$t('labels.collectionTime')"
                        name="collectionTime"
                        outlined
                        rules="required"
                        :vid="`censusTime${idx}`"
                        @change="updateCensusCollectionName(idx)"
                      />
                    </v-col>
                    <v-col
                      cols="5"
                    >
                      <VeeSelect
                        v-model="census.dayOffset"
                        class="census-offset"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        :items="availableDays"
                        :label="$t('labels.collectionDay')"
                        name="censusOffset"
                        outlined
                        rules="required"
                        :vid="`censusOffset${idx}`"
                      />
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <v-btn
                        class="remove-census"
                        icon
                        @click="removeCensus(idx)"
                      >
                        <v-icon
                          color="error"
                          small
                        >
                          fal fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <a
                    class="caption add-census secondary--text"
                    @click="addCensus"
                  >
                    + {{ $t('labels.addCensusCollectionTime') }}
                  </a>
                </template>
              </v-container>
              <!-- <v-row
                v-if="selectedDailySchedule.id"
                class="grey lighten-4 mb-0 py-4 px-6"
                dense
              >
                <v-col>
                  <div class="grey--text text--darken-2 body-2 mb-3">
                    {{ $t('descriptions.obsolete', { type: $t('labels.scheduleRound').toLowerCase() }) }}
                  </div>
                  <v-switch
                    v-model="selectedDailySchedule.obsolete"
                    class="pt-0 mt-1 d-inline-block"
                    color="success"
                    dense
                    hide-details
                    inset
                  >
                    <template v-slot:label>
                      <span
                        class="mr-4 body-2 grey--text text--darken-3"
                        :title="$t('labels.obsolete')"
                      >
                        {{ $t('labels.obsolete') }}
                      </span>
                    </template>
                  </v-switch>
                </v-col>
              </v-row> -->
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeDailyScheduleDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="selectedDailySchedule.id"
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.save') }}
                    </span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(add)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.add') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      v-if="deleteDialog.show"
      action="org/checkDailyScheduleDependencies"
      :delete-body="$t('descriptions.continueDeleteDailySchedule', { name: deleteDialog.dailySchedule.name })"
      :dependencies-body="$t('descriptions.hasDependenciesDailySchedule', { name: deleteDialog.dailySchedule.name })"
      :item="deleteDialog.dailySchedule"
      :title="$t('labels.deleteDailySchedule?')"
      @close="closeDeleteConfirmationDialog"
      @del="deleteDailySchedule"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import MultiSelectionList from '@/components/MultiSelectionList';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import DeleteConfirmation from '@/components/admin/DeleteConfirmation';
import { showStatus } from '@/plugins/vue-notification';
import { ERROR_CODES } from '@/services/constants';

export default {
  components: {
    DeleteConfirmation,
    MultiSelectionList,
    VeeSelect,
    VeeTextField
  },
  data () {
    return {
      deleteDialog: {
        show: false,
        dailySchedule: null
      },
      collectionDayMapping: {
        '-1': 'previousDay',
        '0': 'sameDay'
      },
      displayOptions: {
        mobile: [
          { text: this.$t('labels.shortcode'), value: 'text' }
        ],
        web: [
          { text: this.$t('labels.startAndEndTime'), value: 'time' },
          { text: this.$t('labels.shortcode'), value: 'text' }
        ]
      },
      filters: {
        associatedShiftTypes: [],
        name: ''
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      report: null,
      saving: false,
      selectedDailySchedule: null,
      selectedDailyScheduleCollectCensus: false,
      showDailyScheduleDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      },
      tabHeight: 500
    };
  },
  computed: {
    availableDays () {
      return [
        {
          id: 'previousDay',
          name: this.$t('labels.theDayBefore')
        },
        {
          id: 'sameDay',
          name: this.$t('labels.theSameDay')
        }
      ];
    },
    availableTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(30, 'minutes');
      }
      return times;
    },
    dailySchedules () {
      return _.cloneDeep(this.$store.state.org.dailyScheduleTypes);
    },
    dateFormatStringLong () {
      return this.$store.getters['org/getDateTimeFormatLong']();
    },
    filteredDailySchedules () {
      const filters = [];

      if (this.filters.name) {
        filters.push((dailySchedule) => dailySchedule.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }

      if (this.filters.associatedShiftTypes.length > 0) {
        filters.push((dailySchedule) => _.intersection(this.filters.associatedShiftTypes, dailySchedule.shiftTypes).length > 0);
      }

      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let dailySchedules = this.dailySchedules;
      if (filters.length > 0) {
        dailySchedules = _.filter(this.dailySchedules, (dailySchedule) => filters.reduce((matches, filter) => {
          matches &= filter(dailySchedule);
          return matches;
        }, true));
      }

      const orderFields = _.cloneDeep(this.sort.by);
      const receiveCensusIdx = _.findIndex(orderFields, (f) => f === 'receiveCensus');
      if (receiveCensusIdx >= 0) {
        orderFields[receiveCensusIdx] = (o) => this.receivesCensus(o);
      }
      const censusCollectionTimesIdx = _.findIndex(orderFields, (f) => f === 'censusCollectionTimes');
      if (censusCollectionTimesIdx >= 0) {
        orderFields[censusCollectionTimesIdx] = (o) => this.getCensusCollectionTimesDisplay(o);
      }

      return _.orderBy(dailySchedules, orderFields, orders);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 150
        },
        {
          sortable: false,
          text: this.$t('labels.description'),
          value: 'description',
          width: 225
        },
        {
          sortable: true,
          text: this.$t('labels.receiveCensus'),
          value: 'receiveCensus',
          width: 200
        },
        {
          sortable: true,
          text: this.$t('labels.censusCollectionTimes'),
          value: 'censusCollectionTimes',
          width: 200
        },
        {
          sortable: false,
          text: this.$t('labels.associatedShiftTypes'),
          value: 'associatedShiftTypes',
          width: '*'
        }
      ];
    },
    loadingContainerStyle () {
      return {
        height: `${this.tabHeight - 64}px`
      };
    },
    namesInUse () {
      let dailySchedules = this.dailySchedules;
      if (this.selectedDailySchedule) {
        dailySchedules = _.filter(this.dailySchedules, (st) => st.id !== this.selectedDailySchedule.id);
      }
      return dailySchedules.map((st) => st.name);
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredDailySchedules.length / this.pagination.itemsPerPage);
    },
    paginatedDailySchedules () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredDailySchedules.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredDailySchedules;
    },
    reportContainerStyle () {
      return {
        height: `${this.tabHeight}px`
      };
    },
    reportStyle () {
      return {
        height: `${this.tabHeight - 64}px`,
        width: '100%'
      };
    },
    shiftTypesAvailable () {
      const shiftTypes = _.sortBy(this.$store.state.org.shiftTypes, ['name']);
      return shiftTypes.map((st) => {
        return {
          label: `${st.name} (${this.formatTime(st.startTime)} - ${this.formatTime(st.endTime)})`,
          value: st.id
        };
      });
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    }
  },
  mounted () {
    this.calcPageSize();
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    add () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/createDailyScheduleType', this.getPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.dailyScheduleSaveSuccess'),
            type: 'success'
          });
          this.closeDailyScheduleDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.dailyScheduleSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    addCensus () {
      this.selectedDailySchedule.settings.census.predefined.push({
        name: '',
        description: '',
        time: '',
        dayOffset: ''
      });
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeDeleteConfirmationDialog () {
      this.deleteDialog.show = false;
      this.deleteDialog.dailySchedule = null;
    },
    closeDailyScheduleDialog () {
      this.showDailyScheduleDialog = false;
      this.selectedDailySchedule = null;
      this.selectedDailyScheduleCollectCensus = false;
    },
    deleteDailySchedule (dailySchedule) {
      this.dispatch('org/deleteDailyScheduleType', dailySchedule.id).then(() => {
        showStatus({
          text: this.$t('descriptions.dailyScheduleDeletionSuccess', { name: dailySchedule.name }),
          type: 'success'
        });
        this.$emit('close');
      }).catch((error) => {
        const status = _.get(error, 'response.status', '');
        const responseData = {
          error: _.get(error, 'response.data')
        };

        let text = '';
        if (status === ERROR_CODES.http412PreconditionFailed) {
          text = this.$t('descriptions.dailyScheduleCannotDelete', { name: dailySchedule.name });
        } else {
          text = this.$t('descriptions.dailyScheduleDeletionFail', { name: dailySchedule.name });
        }

        showStatus({
          text,
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.closeDeleteConfirmationDialog();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatTime (time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    },
    getCensusCollectionTimesDisplay (dailySchedule) {
      let display = '';
      if (this.receivesCensus(dailySchedule)) {
        const census = _.get(dailySchedule, 'settings.census.predefined', []);
        const times = [];
        for (let i = 0, count = census.length; i < count; i++) {
          times.push(this.formatTime(census[i].time));
        }
        display = times.join(', ');
      }
      return display;
    },
    getPayload () {
      const selectedDailySchedule = _.cloneDeep(this.selectedDailySchedule);
      const invertedCollectionDayMapping = _.invert(this.collectionDayMapping);
      for (let i = 0, count = selectedDailySchedule.settings.census.predefined.length; i < count; i++) {
        const dayOffset = selectedDailySchedule.settings.census.predefined[i].dayOffset;
        selectedDailySchedule.settings.census.predefined[i].dayOffset = parseInt(invertedCollectionDayMapping[dayOffset]);
      }
      return selectedDailySchedule;
    },
    getAssociatedShiftTypesDisplay (dailySchedule) {
      const associatedShiftTypeIds = dailySchedule.shiftTypes;
      const associatedShiftTypes = [];
      for (let i = 0, count = associatedShiftTypeIds.length; i < count; i++) {
        if (this.shiftTypesById[associatedShiftTypeIds[i]]) {
          associatedShiftTypes.push(this.shiftTypesById[associatedShiftTypeIds[i]].name);
        }
      }
      return associatedShiftTypes.join(', ');
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openDeleteConfirmationDialog (dailySchedule) {
      this.deleteDialog.dailySchedule = dailySchedule;
      this.deleteDialog.show = true;
    },
    openDailyScheduleDialog (dailySchedule) {
      let selectedDailySchedule = {
        description: '',
        name: '',
        obsolete: false,
        settings: {
          census: {
            predefined: []
          }
        },
        shiftTypes: []
      };
      if (dailySchedule) {
        selectedDailySchedule = _.cloneDeep(dailySchedule);
      }
      for (let i = 0, count = selectedDailySchedule.settings.census.predefined.length; i < count; i++) {
        const dayOffset = selectedDailySchedule.settings.census.predefined[i].dayOffset;
        selectedDailySchedule.settings.census.predefined[i].dayOffset = this.collectionDayMapping[dayOffset];
      }
      this.selectedDailySchedule = selectedDailySchedule;
      this.selectedDailyScheduleCollectCensus = selectedDailySchedule.settings.census.predefined.length > 0;
      this.showDailyScheduleDialog = true;
    },
    receivesCensus (dailySchedule) {
      return _.get(dailySchedule, 'settings.census.predefined', []).length > 0;
    },
    removeCensus (idx) {
      this.selectedDailySchedule.settings.census.predefined.splice(idx, 1);
    },
    setFilter (field, value) {
      this.filters[field] = value;
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateDailyScheduleType', this.getPayload()).then(() => {
          showStatus({
            text: this.$t('descriptions.dailyScheduleSaveSuccess'),
            type: 'success'
          });
          this.closeDailyScheduleDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.dailyScheduleSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateCensusCollectionName (idx) {
      const time = moment(this.selectedDailySchedule.settings.census.predefined[idx].time, 'HH:mm:ss');
      let format = '';
      if (time.minutes() === 30) {
        format = 'h:mma';
      } else {
        format = 'ha';
      }
      this.selectedDailySchedule.settings.census.predefined[idx].name = time.format(format);
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('v-main')[0];
      const dailySchedulesContent = document.getElementById('dailySchedules');
      if (el && dailySchedulesContent) {
        const tabNavigatorHeight = 48;
        const padding = 50;
        this.tabHeight = el.clientHeight - dailySchedulesContent.getBoundingClientRect().top - tabNavigatorHeight - padding;
      }
    }
  }
};
</script>

<style lang="scss">
#dailySchedules {
  .v-tabs {
    .v-tabs-bar__content {
      border-bottom: 1px solid map-get($grey, 'lighten-2');
    }
  }
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .daily-schedule-dial {
    right: 0px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
  thead th span {
    font-size: 12px !important;
  }
}
</style>
