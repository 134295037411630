<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $tc('labels.holiday', 2), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="holidays"
    >
      <v-row
        class="actions"
        no-gutters
      >
        <v-col>
          <v-text-field
            v-model.trim="filters.name"
            :append-icon="filters.name ? '' : 'fal fa-search'"
            class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
            :clearable="!!filters.name"
            dense
            hide-details
            :placeholder="$t('labels.searchByName')"
            solo
          />
        </v-col>
      </v-row>
      <v-data-table
        v-resize.quiet="onWindowResized"
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedHolidays"
        :items-per-page="pagination.itemsPerPage"
        must-sort
        single-select
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
      >
        <template #item="{ item, headers: tableHeaders }">
          <tr
            :class="item.obsolete ? 'obsolete' : ''"
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <template v-if="header.value === 'partakeInScheduling'">
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': '140px' }"
                >
                  <v-icon
                    v-if="item.partakeInScheduling"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
              <span
                v-else
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item[header.value]"
              >
                {{ item[header.value] }}
              </span>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 holiday-dial"
              :value="hoveredItem && hoveredItem.name === item.name"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="edit"
                fab
                height="30"
                width="30"
                :title="$t('labels.editHoliday')"
                @click="openHolidayDialog(item)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="py-4 footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showHolidayDialog"
      persistent
      width="500px"
      :value="showHolidayDialog"
    >
      <v-card
        id="holiday"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ $t('labels.editHoliday') + ` - ${selectedHoliday.name}` }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeHolidayDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="holiday">
              <v-container class="py-0 px-6">
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedHoliday.partakeInScheduling"
                      class="d-inline-block mt-0 mb-4"
                      color="success"
                      dense
                      inset
                      hide-details
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.observed')"
                        >
                          {{ $t('labels.observed') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeHolidayDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.save') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Holidays from 'date-holidays';
import { DATE_FORMAT_US } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';

export default {
  data () {
    const defaultUSHolidays = new Holidays('US').getHolidays(moment().year()).reduce(
      (obj, h) => {
        obj[h.name] = h;
        return obj;
      }, // eslint-disable-line no-return-assign, no-sequences
      {}
    );

    // The following names are diffferent between the date-holidays library and moment-holiday library so we have a mapping here
    defaultUSHolidays['Day after Thanksgiving'] = defaultUSHolidays['Day after Thanksgiving Day'];
    defaultUSHolidays['Saint Patrick\'s Day'] = defaultUSHolidays['St. Patrick\'s Day'];
    defaultUSHolidays['Veteran\'s Day'] = defaultUSHolidays['Veterans Day'];

    return {
      defaultUSHolidays,
      filters: {
        name: ''
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedHoliday: null,
      sort: {
        by: ['date'],
        desc: [false]
      },
      showHolidayDialog: false
    };
  },
  computed: {
    filteredHolidays () {
      const filters = [];
      if (this.filters.name) {
        filters.push((holiday) => holiday.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }
      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let holidays = this.holidays;
      if (filters.length > 0) {
        holidays = _.filter(this.holidays, (holiday) => filters.reduce((matches, filter) => {
          matches &= filter(holiday);
          return matches;
        }, true));
      }

      return _.orderBy(holidays, this.sort.by, orders);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$tc('labels.date', 1),
          value: 'date',
          width: 100
        },
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: '*'
        },
        {
          sortable: true,
          text: this.$t('labels.observed'),
          value: 'partakeInScheduling',
          width: 250
        }
      ];
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredHolidays.length / this.pagination.itemsPerPage);
    },
    paginatedHolidays () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredHolidays.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredHolidays;
    },
    holidays () {
      const partakeInScheduling = this.$store.state.org.settings.scheduling.holidays.defaultUs;
      const defaultUS = [
        'New Year\'s Day', 'Martin Luther King Jr. Day', 'Valentine\'s Day', 'Washington\'s Birthday', 'Saint Patrick\'s Day',
        'Easter Sunday', 'Memorial Day', 'Mother\'s Day', 'Father\'s Day',
        'Independence Day', 'Labor Day', 'Columbus Day', 'Halloween', 'Veteran\'s Day',
        'Thanksgiving Day', 'Day after Thanksgiving', 'Christmas Eve', 'Christmas Day', 'New Year\'s Eve'
      ];
      const holidays = [];
      for (let i = 0, count = defaultUS.length; i < count; i++) {
        holidays.push(
          {
            date: this.defaultUSHolidays[defaultUS[i]] ? moment(this.defaultUSHolidays[defaultUS[i]].date).format(DATE_FORMAT_US) : '',
            name: defaultUS[i],
            partakeInScheduling: partakeInScheduling.includes(defaultUS[i])
          }
        );
      }
      return holidays;
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 54;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeHolidayDialog () {
      this.showHolidayDialog = false;
      this.selectedHoliday = null;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openHolidayDialog (holiday) {
      this.selectedHoliday = _.cloneDeep(holiday);
      this.showHolidayDialog = true;
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        const holidays = _.cloneDeep(this.$store.state.org.settings.scheduling.holidays.defaultUs);
        if (this.selectedHoliday.partakeInScheduling) {
          if (!holidays.includes(this.selectedHoliday.name)) {
            holidays.push(this.selectedHoliday.name);
          }
        } else {
          const index = _.findIndex(holidays, (h) => h === this.selectedHoliday.name);
          if (index >= 0) {
            holidays.splice(index, 1);
          }
        }
        this.dispatch('org/updateHolidays', { holidays, region: 'defaultUS' }).then(() => {
          showStatus({
            text: this.$t('descriptions.holidaySaveSuccess', { holiday: this.selectedHoliday.name }),
            type: 'success'
          });
          this.closeHolidayDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.holidaySaveFail', { holiday: this.selectedHoliday.name }),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    }
  }
};
</script>

<style lang="scss">
#holidays {
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .holiday-dial {
    right: 0px !important;
  }
  thead th span {
    font-size: 12px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
}
</style>
