exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#scheduleValidations .job-type-selection {\n  width: 300px;\n}\n#scheduleValidations .job-type-selection .v-select__selections {\n  display: inline-block;\n  overflow: hidden !important;\n  text-overflow: ellipsis !important;\n  white-space: nowrap !important;\n}\n#scheduleValidations .job-type-selection .v-select__selections input {\n  width: 0px;\n}\n#scheduleValidations .shift-count-container {\n  margin-right: 16px;\n  width: 172px;\n}\n#scheduleValidations .shift-name {\n  display: inline-block;\n  text-align: end;\n  vertical-align: middle;\n  width: 60px;\n}\n#scheduleValidations .shift-types {\n  overflow-x: auto;\n}\n#scheduleValidations .shift-types .container {\n  min-width: 400px;\n}\n#scheduleValidations .shift-types .container > .row {\n  white-space: nowrap;\n}\n#scheduleValidations .validations {\n  min-width: 600px;\n  overflow-x: auto;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};