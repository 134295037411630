<template>
  <v-dialog
    v-model="show"
    persistent
    :width="dialogStyle.width"
  >
    <v-card
      class="pa-0"
      flat
      :height="dialogStyle.height"
    >
      <v-card-text class="pa-0">
        <UserProfile
          :show-hint="showHint"
          show-title
          :user="user"
          @close="$emit('close')"
          @saved="updateUser"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserProfile from '@/views/admin/users/UserProfile';

export default {
  components: {
    UserProfile
  },
  props: {
    showHint: {
      default: false,
      type: Boolean
    },
    user: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      show: true
    };
  },
  computed: {
    dialogStyle () {
      switch (this.tab) {
        default:
          return {
            height: '665px',
            width: '750px'
          };
      }
    }
  },
  methods: {
    updateUser (user) {
      this.$store.commit('org/update_employee', user);
      this.$emit('saved', user);
    }
  }
};
</script>

<style lang="scss">
</style>
