var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "px-10 admin-users",
      style: _vm.containerStyle,
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-card",
        { attrs: { elevation: "1" } },
        [
          _c(
            "v-tabs",
            {
              attrs: { "align-with-title": "", "slider-color": "accent" },
              model: {
                value: _vm.state,
                callback: function($$v) {
                  _vm.state = $$v
                },
                expression: "state"
              }
            },
            _vm._l(_vm.states, function(stateItem) {
              return _c(
                "v-tab",
                {
                  key: stateItem.id,
                  staticClass: "ml-0",
                  attrs: { href: "#" + stateItem.id }
                },
                [
                  stateItem.id === _vm.pendingState
                    ? [
                        _c(
                          "v-badge",
                          {
                            staticClass: "tab-error-badge",
                            attrs: {
                              color: "error",
                              content: _vm.pendingProfileCount,
                              inline: "",
                              overlap: "",
                              tile: "",
                              value: _vm.pendingProfileCount > 0
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(stateItem.label) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    : [
                        _vm._v(
                          "\n          " +
                            _vm._s(stateItem.label) +
                            "\n        "
                        )
                      ]
                ],
                2
              )
            }),
            1
          ),
          _c(
            "v-container",
            { staticClass: "pa-0 mx-0 actions", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  style: [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? { "text-align": "center" }
                      : {}
                  ],
                  attrs: {
                    align: "center",
                    dense: "",
                    "no-gutters": !_vm.$vuetify.breakpoint.smAndDown
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "4" } },
                    [
                      _c("StaffSearch", {
                        attrs: {
                          "append-icon": _vm.filters.fullName
                            ? ""
                            : "fal fa-search",
                          "target-class":
                            "name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block",
                          clearable: !!_vm.filters.fullName,
                          dense: "",
                          "hide-details": "",
                          "nudge-bottom": "0",
                          solo: ""
                        },
                        model: {
                          value: _vm.filters.fullName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.filters,
                              "fullName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "filters.fullName"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c("v-col", { attrs: { cols: "12", sm: "6", md: "8" } }, [
                    _c(
                      "div",
                      {
                        class: [
                          _vm.$vuetify.breakpoint.smAndDown ? "" : "float-right"
                        ]
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "grey lighten-2 mr-4 mt-1",
                                            attrs: { icon: "" },
                                            on: { click: _vm.download }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.downloading
                                          ? _c("v-progress-circular", {
                                              staticClass: "px-7",
                                              attrs: {
                                                color: "secondary",
                                                indeterminate: "",
                                                size: "16",
                                                width: "2"
                                              }
                                            })
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "grey--text text--darken-3",
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    fal fa-download\n                  "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.downloading
                                      ? _vm.$t("labels.downloading")
                                      : _vm.$t("labels.download")
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              "max-width": "300px",
                              bottom: "",
                              "nudge-left": "140"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "grey lighten-2 mr-4 mt-1",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                _vm.showSearchUserDialog = true
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "grey--text text--darken-3",
                                            attrs: { size: "16" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    fal fa-plus\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", { staticClass: "body-2" }, [
                              _c(
                                "span",
                                { staticClass: "font-weight-bold text-h6" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("labels.addUser")) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("descriptions.addUserEmail")) +
                                  "\n              "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            directives: [
              {
                name: "resize",
                rawName: "v-resize.quiet",
                value: _vm.onWindowResized,
                expression: "onWindowResized",
                modifiers: { quiet: true }
              }
            ],
            attrs: {
              dense: "",
              "fixed-header": "",
              headers: _vm.headerDefinitions,
              "header-props": { sortIcon: "fa fa-arrow-up" },
              "hide-default-footer": "",
              items: _vm.paginatedUsers,
              "items-per-page": _vm.pagination.itemsPerPage,
              "mobile-breakpoint": "",
              "must-sort": "",
              "single-select": "",
              "sort-by": ["fullName"],
              "sort-desc": [false]
            },
            scopedSlots: _vm._u([
              {
                key: "header.departmentId",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    header.filterable
                      ? _c("MultiSelectionList", {
                          attrs: {
                            "auto-apply": "",
                            selection: header.filterOptions
                          },
                          on: {
                            selectionConfirmed: function($event) {
                              return _vm.setFilter(header.value, $event)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "ml-n4 subtitle-2 text-capitalize",
                                          attrs: {
                                            color: "secondary",
                                            text: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(header.text) +
                                            "\n              "
                                        ),
                                        _vm.filters[header.value].length > 0
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fas fa-filter\n              "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fal fa-filter\n              "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c(
                          "span",
                          { staticClass: "grey--text text--darken-3" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(header.text) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                }
              },
              {
                key: "header.jobTypeId",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    header.filterable
                      ? _c("MultiSelectionList", {
                          attrs: {
                            "auto-apply": "",
                            selection: header.filterOptions
                          },
                          on: {
                            selectionConfirmed: function($event) {
                              return _vm.setFilter(header.value, $event)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "ml-n4 subtitle-2 text-capitalize",
                                          attrs: {
                                            color: "secondary",
                                            text: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(header.text) +
                                            "\n              "
                                        ),
                                        _vm.filters[header.value].length > 0
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fas fa-filter\n              "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fal fa-filter\n              "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c(
                          "span",
                          { staticClass: "grey--text text--darken-3" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(header.text) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                }
              },
              {
                key: "header.jobStatusId",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    header.filterable
                      ? _c("MultiSelectionList", {
                          attrs: {
                            "auto-apply": "",
                            selection: header.filterOptions
                          },
                          on: {
                            selectionConfirmed: function($event) {
                              return _vm.setFilter(header.value, $event)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "ml-n4 subtitle-2 text-capitalize",
                                          attrs: {
                                            color: "secondary",
                                            text: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(header.text) +
                                            "\n              "
                                        ),
                                        _vm.filters[header.value].length > 0
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fas fa-filter\n              "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fal fa-filter\n              "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c(
                          "span",
                          { staticClass: "grey--text text--darken-3" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(header.text) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                }
              },
              {
                key: "header.shiftTypeId",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    header.filterable
                      ? _c("MultiSelectionList", {
                          attrs: {
                            "auto-apply": "",
                            selection: header.filterOptions
                          },
                          on: {
                            selectionConfirmed: function($event) {
                              return _vm.setFilter(header.value, $event)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "ml-n4 subtitle-2 text-capitalize",
                                          attrs: {
                                            color: "secondary",
                                            text: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(header.text) +
                                            "\n              "
                                        ),
                                        _vm.filters[header.value].length > 0
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fas fa-filter\n              "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fal fa-filter\n              "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _c(
                          "span",
                          { staticClass: "grey--text text--darken-3" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(header.text) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                }
              },
              {
                key: "header.phone",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "header.employeeID",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var tableHeaders = ref.headers
                  return [
                    _c(
                      "tr",
                      {
                        on: {
                          mouseenter: function($event) {
                            _vm.hoveredItem = item
                          },
                          mouseleave: function($event) {
                            _vm.hoveredItem = null
                          }
                        }
                      },
                      [
                        _vm._l(tableHeaders, function(header) {
                          return _c(
                            "td",
                            { key: header.value, staticClass: "text-start" },
                            [
                              header.value === "fullName"
                                ? _c(
                                    "v-list-item",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        { staticClass: "mr-1" },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                color:
                                                  _vm.$store.state.org
                                                    .employees[item.userId]
                                                    .avatarBgColor,
                                                size: "30"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "white--text subtitle-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getAvatar(
                                                          _vm.$store.state.org
                                                            .employees[
                                                            item.userId
                                                          ]
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "body-2 mb-0 name-n-avatar",
                                              attrs: { title: item.fullName }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-inline-block text-truncate pt-1",
                                                  style: {
                                                    width:
                                                      header.width - 40 + "px"
                                                  }
                                                },
                                                [
                                                  _c("UserName", {
                                                    attrs: {
                                                      "internal-control": false,
                                                      user: item
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openUserDialog(
                                                          item,
                                                          false
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm.showPendingTooltip(item)
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            "max-width":
                                                              "300px",
                                                            right: ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "info",
                                                                              "x-small":
                                                                                ""
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          fal fa-clock\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.activationScheduled"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c("v-list-item-subtitle", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption d-inline-block text-truncate",
                                                style: {
                                                  width:
                                                    header.width - 40 + "px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.email) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : header.value === "departmentId"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 text-truncate d-inline-block",
                                      style: { width: header.width + "px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getDepartmentName(item)) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : header.value === "jobTypeId"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 text-truncate d-inline-block",
                                      style: { width: header.width + "px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getJobTypeName(item)) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : header.value === "jobStatusId"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 text-truncate d-inline-block",
                                      style: { width: header.width + "px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.getJobStatus(item)) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : header.value === "shiftTypeId"
                                ? _c(
                                    "v-list-item",
                                    { staticClass: "px-0" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "body-2 text-truncate d-inline-block",
                                              style: {
                                                width: header.width + "px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getShiftTypeName(item)
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass:
                                                "caption mt-0 text-truncate d-inline-block",
                                              style: {
                                                width: header.width + "px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getShiftTime(item)
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : header.value === "phone"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 text-truncate d-inline-block",
                                      style: { width: header.width + "px" }
                                    },
                                    [
                                      item.phonePersonal
                                        ? [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("VMask")(
                                                    item.phonePersonal,
                                                    _vm.phoneMask
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : header.value === "employeeID"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 text-truncate d-inline-block",
                                      style: { width: header.width + "px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.employeeId) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        _c(
                          "v-speed-dial",
                          {
                            staticClass: "mt-7 user-actions",
                            attrs: {
                              absolute: "",
                              value:
                                _vm.hoveredItem &&
                                _vm.hoveredItem.id === item.id,
                              right: "",
                              direction: "left",
                              transition: "slide-x-reverse-transition"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "action",
                                attrs: {
                                  fab: "",
                                  height: "30",
                                  width: "30",
                                  title: _vm.$t("labels.editUser")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openUserDialog(item, false)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    "\n                fal fa-pencil\n              "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("v-pagination", {
                      staticClass: "pt-4 pb-4 user-footer",
                      attrs: {
                        color: "secondary",
                        length: _vm.numberOfPages,
                        "next-icon": "far fa-chevron-right",
                        "prev-icon": "far fa-chevron-left",
                        "total-visible": _vm.pagination.maxPaginationControls
                      },
                      model: {
                        value: _vm.pagination.currentPage,
                        callback: function($$v) {
                          _vm.$set(_vm.pagination, "currentPage", $$v)
                        },
                        expression: "pagination.currentPage"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm.showSearchUserDialog
        ? _c("SearchUserDialog", {
            on: {
              close: function($event) {
                _vm.showSearchUserDialog = false
              },
              done: _vm.processSearchResults
            }
          })
        : _vm._e(),
      _vm.selectedUser
        ? [
            _vm.newSelectedUser
              ? _c("NewUserDialog", {
                  attrs: {
                    "show-hint": _vm.showUserDialogHint,
                    user: _vm.selectedUser
                  },
                  on: { close: _vm.closeUserDialog, saved: _vm.updateUser }
                })
              : _c("UserDialog", {
                  attrs: {
                    "show-hint": _vm.showUserDialogHint,
                    user: _vm.selectedUser
                  },
                  on: { close: _vm.closeUserDialog, saved: _vm.updateUser }
                })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }